import { TERM_CONDITION } from '@/configs/endpoint'
import { BaseResponse } from '@/types/common'
import { api } from '@/utils/api'

export type GetDetailTermConditionResponse = BaseResponse<{
  value: {
    id: string
    en: string
  }
  version: string
}>

export const getDetailTermCondition = async () => {
  const response = await api<GetDetailTermConditionResponse>({
    url: TERM_CONDITION,
    method: 'GET',
  })

  return response.data
}

export const getDetailTermConditionKey = 'GET_DETAIL_TERM_CONDITION'
