import { CSSObject } from '@emotion/react'
import styled from '@emotion/styled'
import { configStyledOptions, scrollBar, SidebarContext } from '@nbsdev/naini-react'
import { forwardRef, useState } from 'react'

import { SidebarMenuContent } from './SidebarMenuContent'
import { findAllIdsByActive } from './utils'

export type SidebarMenuData = {
  id: string
  type: 'link' | 'divider' | 'menu'
  label: string
  path?: string
  icon?: string
  badge?: string
  active?: string[]
  access?: string[]
  items?: SidebarMenuData[]
}

export type SidebarMenuProps = {
  items: SidebarMenuData[]
  active: string
  collapsed: boolean
}

const SidebarMenuWrapper = styled(
  'nav',
  configStyledOptions([])
)(
  ({ theme }): CSSObject => ({
    ...scrollBar,
    display: 'block',
    height: `calc(100vh - ${theme.navbar.height})`,
    width: '100%',
    fontFamily: theme.font.sans,
    overflow: 'hidden auto',
    paddingTop: '12px',
    paddingBottom: '12px',
    '@media (min-width: 1024px)': {
      paddingTop: '0',
      paddingBottom: '12px',
      height: `calc(100vh - ${theme.sidebar.headerHeight} + ${theme.sidebar.gap})`, // sidebar header + grid gap = 112
    },
  })
)

export const SidebarMenu = forwardRef<HTMLElement, SidebarMenuProps>(({ items, active, collapsed, ...props }, ref) => {
  const [expandedIds, setExpandedIds] = useState<string[]>(findAllIdsByActive(items, active))

  return (
    <SidebarContext.Provider value={{ items, active, collapsed, expandedIds, setExpandedIds }}>
      <SidebarMenuWrapper
        ref={ref}
        {...props}
      >
        <SidebarMenuContent
          items={items}
          collapsed={collapsed}
        />
      </SidebarMenuWrapper>
    </SidebarContext.Provider>
  )
})

SidebarMenu.displayName = 'SidebarMenu'
