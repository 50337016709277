import { CSSObject } from '@emotion/react'
import styled from '@emotion/styled'
import { Table, TableBody, TableCell, TableHead, TableHeadCell, TableRow } from '@nbsdev/naini-react'
import { useCallback, useMemo } from 'react'
import { useTranslation } from 'react-i18next'

import { Dropdown, DropdownItem } from '../Dropdown'

const Styled = styled('div')({
  width: '100%',
  overflowY: 'hidden',
  overflowX: 'auto',
})

export type TableData = {
  xid: string
  [key: string]: any
}

export type TableColumn<K extends keyof TableData> = {
  key: K
  title: string
  width?: CSSObject['width']
}

export type TableComponentProps<K extends keyof TableData> = {
  columns: TableColumn<K>[]
  items: TableData[]
  actions?: (item: TableData) => DropdownItem[]
  perPage?: number
  currentPage?: number
  withoutNumber?: boolean
}

export const TableComponent = <K extends keyof TableData>({
  columns,
  items,
  actions,
  perPage = 10,
  currentPage = 1,
  withoutNumber = false,
}: TableComponentProps<K>) => {
  const { t } = useTranslation()

  const start = useMemo(() => (currentPage - 1) * perPage + 1, [currentPage, perPage])

  const dropdownItems = useCallback((item: TableData) => (actions ? actions(item) : []), [actions])

  return (
    <Styled>
      <Table>
        <TableHead css={{ borderBottom: '1px solid #EBEBEB' }}>
          <TableRow>
            {!withoutNumber && <TableHeadCell width={0}>{t('common:no')}</TableHeadCell>}

            {columns.map((col) => (
              <TableHeadCell
                key={col.key}
                width={col.width}
              >
                {col.title}
              </TableHeadCell>
            ))}

            {actions && actions.length > 0 && <TableHeadCell width={0}>{t('common:actions')}</TableHeadCell>}
          </TableRow>
        </TableHead>

        <TableBody
          css={{
            ' tr:nth-of-type(odd)': {
              backgroundColor: '#FFFFFF',
            },
            ' tr:nth-of-type(even)': {
              backgroundColor: '#F9F9F9',
            },
          }}
        >
          {items.map((item, idx) => (
            <TableRow key={item.xid}>
              {!withoutNumber && <TableCell width={0}>{idx + start}</TableCell>}

              {columns.map((col) => (
                <TableCell key={col.key}>{item[col.key] || '-'}</TableCell>
              ))}

              {actions && (
                <TableCell
                  className='nowrap'
                  width={0}
                >
                  <Dropdown items={dropdownItems(item)} />
                </TableCell>
              )}
            </TableRow>
          ))}
        </TableBody>
      </Table>
    </Styled>
  )
}
