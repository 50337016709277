import { CSSObject } from '@emotion/react'
import styled from '@emotion/styled'
import { configStyledOptions } from '@nbsdev/naini-react'

export type NotificationMessageTextProps = {}

export const NotificationMessageText = styled(
  'p',
  configStyledOptions<NotificationMessageTextProps>([])
)<NotificationMessageTextProps>(
  (): CSSObject => ({
    color: '#262626',
    fontSize: '12px',
    fontStyle: 'normal',
    fontWeight: 400,
    lineHeight: '18px',
    textAlign: 'left',
  })
)

NotificationMessageText.displayName = 'NotificationMessageText'
