import { CSSObject } from '@emotion/react'
import styled from '@emotion/styled'
import { Button, configStyledOptions, getSize, getVariant, Size } from '@nbsdev/naini-react'
import { FC, useMemo, useState } from 'react'
import { useTranslation } from 'react-i18next'

import { ImagePreview } from '../Image/ImagePreview'

import defaultImage12 from '/assets/images/default-image-1-2.webp'
import defaultImage34 from '/assets/images/default-image-3-4.webp'
import defaultImage169 from '/assets/images/default-image-16-9.webp'

type StyledProps = {
  buttonSize?: Size
  padding?: CSSObject['padding']
  height?: CSSObject['height']
  fontSize?: CSSObject['fontSize']
}

const Styled = styled(
  Button,
  configStyledOptions<StyledProps>(['size', 'variant', 'outline'])
)<StyledProps>(({
  theme,
  variant = 'primary',
  buttonSize = 'md',
  outline = false,
  padding,
  height,
  fontSize,
}): CSSObject => {
  return {
    ...getSize(buttonSize!),
    ...getVariant(theme.button!.colors, variant!, false, outline),
    padding: padding,
    height: height,
    fontSize: fontSize,
    borderRadius: '8px',
    borderStyle: 'solid',
    borderWidth: '1px',
    boxShadow: theme.shadow.sm,
    cursor: 'pointer',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    fontFamily: theme.font.sans,
    fontStyle: 'normal',
    fontWeight: 500,
    ':disabled': {
      ...getVariant(theme.button!.colors, 'disabled', false, outline),
      cursor: 'not-allowed',
    },
    ':hover:not([disabled])': {
      ...getVariant(theme.button!.colors, variant!, true, outline),
    },
    ':active:not([disabled])': {
      ...getVariant(theme.button!.colors, variant!, true, outline),
    },
    '>:not([hidden])~:not([hidden])': {
      marginLeft: '8px',
    },
  }
})

type ButtonPreviewProps = {
  src: string
  alt: string
  previewText?: string
  preview?: boolean
  closeOnBackdropClick?: boolean
  defaultImage?: '1:2' | '3:4' | '16:9'
}

export const ButtonPreviewImage: FC<StyledProps & ButtonPreviewProps> = ({
  src,
  previewText,
  preview = true,
  closeOnBackdropClick = true,
  defaultImage = '16:9',
  ...props
}) => {
  const { t } = useTranslation()
  const [openPreview, setOpenPreview] = useState(false)

  const handleOpenPreview = () => {
    if (preview) {
      setOpenPreview(true)
    }
  }

  const srcImage = useMemo(() => {
    if (src.length) {
      return src
    }
    if (defaultImage === '1:2') {
      return defaultImage12
    }
    if (defaultImage === '3:4') {
      return defaultImage34
    }
    return defaultImage169
  }, [src])

  return (
    <>
      <Styled
        onClick={handleOpenPreview}
        variant='outline'
        outline
        {...props}
      >
        <span
          className='ri-eye-fill'
          css={{ fontSize: '14px' }}
        />
        <span>{previewText ?? t('common:previewImage')}</span>
      </Styled>

      {openPreview && (
        <ImagePreview
          isOpen={openPreview}
          onClose={() => setOpenPreview(false)}
          closeOnBackdropClick={closeOnBackdropClick}
          slides={[{ src: srcImage }]}
        />
      )}
    </>
  )
}

ButtonPreviewImage.displayName = 'ButtonPreviewImage'
