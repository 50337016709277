import { CSSObject } from '@emotion/react'
import styled from '@emotion/styled'
import { AlertVariant, configStyledOptions } from '@nbsdev/naini-react'

type FormScheduleIconProps = {
  variant?: AlertVariant
}

export const FormScheduleIcon = styled(
  'div',
  configStyledOptions(['variant'])
)<FormScheduleIconProps>(
  ({ theme, variant = 'warning' }): CSSObject => ({
    padding: '8px',
    borderRadius: '8px',
    backgroundColor: theme.alert.colors[variant].bg,
    color: theme.alert.colors[variant].main,
    fontSize: '24px',
    lineHeight: '24px',
  })
)

FormScheduleIcon.displayName = 'FormScheduleIcon'
