import { CSSObject } from '@emotion/react'
import styled from '@emotion/styled'
import { configStyledOptions } from '@nbsdev/naini-react'

export type IconProps = {
  width?: number
  height?: number
  color?: string
}

export const Icon = styled(
  'i',
  configStyledOptions<IconProps>(['width', 'height'])
)<IconProps>(
  ({ width = 24, height = 24, color = 'inherit' }): CSSObject => ({
    fontSize: `${width}px`,
    lineHeight: `${height}px`,
    color: color,
  })
)

Icon.displayName = 'Icon'
