import { CAST_DETAIL } from '@/configs/endpoint'
import { BaseResponse, TimestampAndVersion } from '@/types/common'
import { api } from '@/utils/api'

export type GetDetailCastRequestParams = {
  xid: string
}

export type GetDetailCastRequest = {
  params: GetDetailCastRequestParams
}

export type GetDetailCastResponse = BaseResponse<
  {
    xid: string
    name: string
  } & TimestampAndVersion
>

export const getDetailCast = async (request: GetDetailCastRequest) => {
  const response = await api<GetDetailCastResponse, GetDetailCastRequestParams>({
    ...request,
    url: CAST_DETAIL,
    method: 'GET',
  })

  return response.data
}

export const getDetailCastKey = 'GET_DETAIL_CAST'
