import { ThemeProvider } from '@nbsdev/naini-react'
import * as Sentry from '@sentry/react'
import ReactDOM from 'react-dom/client'
import { Toaster } from 'react-hot-toast'

import { sentryDSN, sentryEnvironment } from '@/configs/env'
import theme from '@/configs/theme'

import App from './App'

Sentry.init({
  dsn: sentryDSN,
  environment: sentryEnvironment,
  integrations: [Sentry.browserTracingIntegration(), Sentry.browserProfilingIntegration()],
  tracesSampleRate: 0.5,
  profilesSampleRate: 0.5,
})

ReactDOM.createRoot(document.getElementById('app')!).render(
  <ThemeProvider theme={theme}>
    <App />

    <Toaster
      position='top-center'
      containerStyle={{
        top: 40,
      }}
      toastOptions={{
        duration: 3000,
      }}
    />
  </ThemeProvider>
)
