import { Abilities } from '@/configs/enum'

export type Permission = {
  path: string
  actions: {
    read?: string
    create?: string
    update?: string
    delete?: string
  }
}

export const permissions: Permission[] = [
  {
    path: '/main/dashboard',
    actions: {
      read: Abilities.ReadDashboard,
    },
  },

  {
    path: '/main/banner',
    actions: {
      read: Abilities.ReadBanner,
      create: Abilities.CreateBanner,
      update: Abilities.UpdateBanner,
      delete: Abilities.DeleteBanner,
    },
  },
  {
    path: '/main/banner/$xid/edit',
    actions: {
      update: Abilities.UpdateBanner,
      delete: Abilities.DeleteBanner,
    },
  },
  {
    path: '/main/banner/create',
    actions: {
      create: Abilities.CreateBanner,
    },
  },
  {
    path: '/main/notification-broadcast',
    actions: {
      read: Abilities.ReadNotificationBroadcast,
      create: Abilities.CreateNotificationBroadcast,
      update: Abilities.UpdateNotificationBroadcast,
      delete: Abilities.DeleteNotificationBroadcast,
    },
  },
  {
    path: '/main/notification-broadcast/create',
    actions: {
      create: Abilities.CreateNotificationBroadcast,
    },
  },
  {
    path: '/main/notification-broadcast/$xid/edit',
    actions: {
      update: Abilities.UpdateNotificationBroadcast,
      delete: Abilities.DeleteNotificationBroadcast,
    },
  },
  {
    path: '/main/notification-broadcast/$xid/detail',
    actions: {
      read: Abilities.ReadNotificationBroadcast,
    },
  },
  {
    path: '/main/dynamic-splash-screen',
    actions: {
      read: Abilities.ReadSplashScreen,
      create: Abilities.CreateSplashScreen,
      update: Abilities.UpdateSplashScreen,
      delete: Abilities.DeleteSplashScreen,
    },
  },
  {
    path: '/main/dynamic-splash-screen/create',
    actions: {
      create: Abilities.CreateSplashScreen,
    },
  },
  {
    path: '/main/dynamic-splash-screen/$xid/edit',
    actions: {
      update: Abilities.UpdateSplashScreen,
      delete: Abilities.DeleteSplashScreen,
    },
  },
  {
    path: '/main/slide-show',
    actions: {
      read: Abilities.ReadSlideShow,
      update: Abilities.UpdateSlideShow,
    },
  },
  {
    path: '/main/slide-show/$xid/edit',
    actions: {
      update: Abilities.UpdateSlideShow,
    },
  },
  {
    path: '/main/static-splash-screen',
    actions: {
      read: Abilities.ReadStaticSplashScreen,
      update: Abilities.UpdateStaticSplashScreen,
    },
  },
  {
    path: '/main/dynamic-content',
    actions: {
      read: Abilities.ReadDynamicContent,
      create: Abilities.CreateDynamicContent,
      update: Abilities.UpdateDynamicContent,
      delete: Abilities.DeleteDynamicContent,
    },
  },
  {
    path: '/main/dynamic-content/create',
    actions: {
      create: Abilities.CreateDynamicContent,
    },
  },
  {
    path: '/main/dynamic-content/$xid/edit',
    actions: {
      update: Abilities.UpdateDynamicContent,
      delete: Abilities.DeleteDynamicContent,
    },
  },
  {
    path: '/main/program-active',
    actions: {
      read: Abilities.ReadProgramLibrary,
      update: Abilities.UpdateProgramLibrary,
      delete: Abilities.DeleteProgramLibrary,
    },
  },
  {
    path: '/main/program-active/edit',
    actions: {
      read: Abilities.ReadProgramLibrary,
      update: Abilities.UpdateProgramLibrary,
      delete: Abilities.DeleteProgramLibrary,
    },
  },
  {
    path: '/main/program-active/$xid/edit/general-data',
    actions: {
      read: Abilities.ReadProgramLibrary,
      update: Abilities.UpdateProgramLibrary,
      delete: Abilities.DeleteProgramLibrary,
    },
  },
  {
    path: '/main/program-active/$xid/edit/cover',
    actions: {
      read: Abilities.ReadProgramLibrary,
      update: Abilities.UpdateProgramLibrary,
      delete: Abilities.DeleteProgramLibrary,
    },
  },
  {
    path: '/main/program-active/$xid/edit/publication',
    actions: {
      read: Abilities.ReadProgramLibrary,
      update: Abilities.UpdateProgramLibrary,
      delete: Abilities.DeleteProgramLibrary,
    },
  },
  {
    path: '/main/program-active/$xid/edit/info',
    actions: {
      read: Abilities.ReadProgramLibrary,
      update: Abilities.UpdateProgramLibrary,
      delete: Abilities.DeleteProgramLibrary,
    },
  },
  {
    path: '/main/program-active/$xid/edit/episodes',
    actions: {
      read: Abilities.ReadProgramLibrary,
      update: Abilities.UpdateProgramLibrary,
      delete: Abilities.DeleteProgramLibrary,
    },
  },
  {
    path: '/main/program-active/edit/$programXid/draft/video/edit/$episodeXid/episode',
    actions: {
      update: Abilities.UpdateProgramLibrary,
      delete: Abilities.DeleteProgramLibrary,
    },
  },
  {
    path: '/main/program-active/$xid/general-data',
    actions: {
      read: Abilities.ReadProgramLibrary,
      update: Abilities.UpdateProgramLibrary,
      delete: Abilities.DeleteProgramLibrary,
    },
  },
  {
    path: '/main/program-active/$xid/cover',
    actions: {
      read: Abilities.ReadProgramLibrary,
      update: Abilities.UpdateProgramLibrary,
      delete: Abilities.DeleteProgramLibrary,
    },
  },
  {
    path: '/main/program-active/$xid/info',
    actions: {
      read: Abilities.ReadProgramLibrary,
      update: Abilities.UpdateProgramLibrary,
      delete: Abilities.DeleteProgramLibrary,
    },
  },
  {
    path: '/main/program-active/$xid/episodes',
    actions: {
      read: Abilities.ReadProgramLibrary,
      update: Abilities.UpdateProgramLibrary,
      delete: Abilities.DeleteProgramLibrary,
    },
  },
  {
    path: '/main/program-submission',
    actions: {
      read: Abilities.ReadSubmissionProgram,
      create: Abilities.CreateSubmissionProgram,
      update: Abilities.UpdateSubmissionProgram,
      delete: Abilities.DeleteSubmissionProgram,
    },
  },
  {
    path: '/main/program-submission/create/type',
    actions: {
      read: Abilities.ReadSubmissionProgram,
      create: Abilities.CreateSubmissionProgram,
      update: Abilities.UpdateSubmissionProgram,
      delete: Abilities.DeleteSubmissionProgram,
    },
  },
  {
    path: '/main/program-submission/create/general',
    actions: {
      read: Abilities.ReadSubmissionProgram,
      create: Abilities.CreateSubmissionProgram,
      update: Abilities.UpdateSubmissionProgram,
      delete: Abilities.DeleteSubmissionProgram,
    },
  },
  {
    path: '/main/program-submission/create/$xid/draft/video/edit/$episodeXid/episode',
    actions: {
      create: Abilities.CreateSubmissionProgram,
      update: Abilities.UpdateSubmissionProgram,
      delete: Abilities.DeleteSubmissionProgram,
    },
  },
  {
    path: '/main/program-submission/create/$xid/draft/general',
    actions: {
      create: Abilities.CreateSubmissionProgram,
      update: Abilities.UpdateSubmissionProgram,
      delete: Abilities.DeleteSubmissionProgram,
    },
  },
  {
    path: '/main/program-submission/create/$xid/draft/video',
    actions: {
      create: Abilities.CreateSubmissionProgram,
      update: Abilities.UpdateSubmissionProgram,
      delete: Abilities.DeleteSubmissionProgram,
    },
  },
  {
    path: '/main/program-submission/create/$xid/draft/other-information',
    actions: {
      create: Abilities.CreateSubmissionProgram,
      update: Abilities.UpdateSubmissionProgram,
      delete: Abilities.DeleteSubmissionProgram,
    },
  },
  {
    path: '/main/program-submission/create/$xid/draft/cover',
    actions: {
      create: Abilities.CreateSubmissionProgram,
      update: Abilities.UpdateSubmissionProgram,
      delete: Abilities.DeleteSubmissionProgram,
    },
  },
  {
    path: '/main/program-submission/create/$xid/draft/publication',
    actions: {
      create: Abilities.CreateSubmissionProgram,
      update: Abilities.UpdateSubmissionProgram,
      delete: Abilities.DeleteSubmissionProgram,
    },
  },
  {
    path: '/main/program-submission/$xid/detail/general',
    actions: {
      read: Abilities.ReadSubmissionProgram,
      create: Abilities.CreateSubmissionProgram,
      update: Abilities.UpdateSubmissionProgram,
      delete: Abilities.DeleteSubmissionProgram,
    },
  },
  {
    path: '/main/program-submission/$xid/detail/video',
    actions: {
      read: Abilities.ReadSubmissionProgram,
      create: Abilities.CreateSubmissionProgram,
      update: Abilities.UpdateSubmissionProgram,
      delete: Abilities.DeleteSubmissionProgram,
    },
  },
  {
    path: '/main/program-submission/$xid/detail/other-information',
    actions: {
      read: Abilities.ReadSubmissionProgram,
      create: Abilities.CreateSubmissionProgram,
      update: Abilities.UpdateSubmissionProgram,
      delete: Abilities.DeleteSubmissionProgram,
    },
  },
  {
    path: '/main/program-submission/$xid/detail/cover',
    actions: {
      read: Abilities.ReadSubmissionProgram,
      create: Abilities.CreateSubmissionProgram,
      update: Abilities.UpdateSubmissionProgram,
      delete: Abilities.DeleteSubmissionProgram,
    },
  },
  {
    path: '/main/program-submission/$xid/detail/publication',
    actions: {
      read: Abilities.ReadSubmissionProgram,
      create: Abilities.CreateSubmissionProgram,
      update: Abilities.UpdateSubmissionProgram,
      delete: Abilities.DeleteSubmissionProgram,
    },
  },
  {
    path: '/main/program-need-review',
    actions: {
      read: Abilities.ReadProgramNeedReview,
    },
  },
  {
    path: '/main/program-need-review/$xid/general-data',
    actions: {
      read: Abilities.ReadProgramNeedReview,
      update: Abilities.UpdateProgramNeedReview,
    },
  },
  {
    path: '/main/program-need-review/$xid/episodes',
    actions: {
      read: Abilities.ReadProgramNeedReview,
      update: Abilities.UpdateProgramNeedReview,
    },
  },
  {
    path: '/main/program-need-review/$xid/info',
    actions: {
      read: Abilities.ReadProgramNeedReview,
      update: Abilities.UpdateProgramNeedReview,
    },
  },
  {
    path: '/main/program-need-review/$xid/cover',
    actions: {
      read: Abilities.ReadProgramNeedReview,
      update: Abilities.UpdateProgramNeedReview,
    },
  },
  {
    path: '/main/program-need-review/$xid/publication',
    actions: {
      read: Abilities.ReadProgramNeedReview,
      update: Abilities.UpdateProgramNeedReview,
    },
  },
  {
    path: '/main/program-statistic',
    actions: {
      read: Abilities.ReadProgramStatistic,
    },
  },
  {
    path: '/main/live-channel',
    actions: {
      read: Abilities.ReadLiveChannel,
      create: Abilities.CreateLiveChannel,
      update: Abilities.UpdateLiveChannel,
      delete: Abilities.DeleteLiveChannel,
    },
  },
  {
    path: '/main/live-channel/create',
    actions: {
      create: Abilities.CreateLiveChannel,
    },
  },
  {
    path: '/main/live-channel/$xid/edit/general',
    actions: {
      update: Abilities.UpdateLiveChannel,
      delete: Abilities.DeleteLiveChannel,
    },
  },
  {
    path: '/main/live-channel/$xid/edit/cover-and-video',
    actions: {
      update: Abilities.UpdateLiveChannel,
      delete: Abilities.DeleteLiveChannel,
    },
  },
  {
    path: '/main/live-channel/$xid/edit/schedule',
    actions: {
      update: Abilities.UpdateLiveChannel,
      delete: Abilities.DeleteLiveChannel,
    },
  },
  // {
  //   path: '/main/program-coverage',
  //   actions: {
  //     read: Abilities.ReadProgramCoverage,
  //     create: Abilities.CreateProgramCoverage,
  //     update: Abilities.UpdateProgramCoverage,
  //     delete: Abilities.DeleteProgramCoverage,
  //   },
  // },
  {
    path: '/main/program-coverage/create',
    actions: {
      create: Abilities.CreateProgramCoverage,
    },
  },
  {
    path: '/main/program-coverage/$xid/edit',
    actions: {
      update: Abilities.UpdateProgramCoverage,
      delete: Abilities.DeleteProgramCoverage,
    },
  },
  {
    path: '/main/category',
    actions: {
      read: Abilities.ReadMasterDataTopLevelCategory,
      create: Abilities.CreateMasterDataTopLevelCategory,
      update: Abilities.UpdateMasterDataTopLevelCategory,
      delete: Abilities.DeleteMasterDataTopLevelCategory,
    },
  },
  {
    path: '/main/category/$xid/edit',
    actions: {
      read: Abilities.ReadMasterDataCategory,
      create: Abilities.CreateMasterDataCategory,
      update: Abilities.UpdateMasterDataTopLevelCategory,
      delete: Abilities.DeleteMasterDataTopLevelCategory,
    },
  },
  {
    path: '/main/category/$xid/create-sub-category',
    actions: {
      create: Abilities.CreateMasterDataCategory,
    },
  },
  {
    path: '/main/category/$xid/edit/$subXid/edit-sub-category',
    actions: {
      update: Abilities.UpdateMasterDataCategory,
      delete: Abilities.DeleteMasterDataCategory,
    },
  },
  {
    path: '/main/sub-category',
    actions: {
      read: Abilities.ReadMasterDataCategory,
      create: Abilities.CreateMasterDataCategory,
      update: Abilities.UpdateMasterDataCategory,
      delete: Abilities.DeleteMasterDataCategory,
    },
  },
  {
    path: '/main/sub-category/create',
    actions: {
      create: Abilities.CreateMasterDataCategory,
    },
  },
  {
    path: '/main/sub-category/$xid/edit',
    actions: {
      update: Abilities.UpdateMasterDataCategory,
      delete: Abilities.DeleteMasterDataCategory,
    },
  },
  {
    path: '/main/cast',
    actions: {
      read: Abilities.ReadMasterDataCast,
      create: Abilities.CreateMasterDataCast,
      update: Abilities.UpdateMasterDataCast,
      delete: Abilities.DeleteMasterDataCast,
    },
  },
  {
    path: '/main/cast/create',
    actions: {
      create: Abilities.CreateMasterDataCast,
    },
  },
  {
    path: '/main/cast/$xid/edit',
    actions: {
      update: Abilities.UpdateMasterDataCast,
      delete: Abilities.DeleteMasterDataCast,
    },
  },
  {
    path: '/main/figure',
    actions: {
      read: Abilities.ReadMasterDataFigure,
      create: Abilities.CreateMasterDataFigure,
      update: Abilities.UpdateMasterDataFigure,
      delete: Abilities.DeleteMasterDataFigure,
    },
  },
  {
    path: '/main/figure/create',
    actions: {
      create: Abilities.CreateMasterDataFigure,
    },
  },
  {
    path: '/main/figure/$xid/edit',
    actions: {
      update: Abilities.UpdateMasterDataFigure,
      delete: Abilities.DeleteMasterDataFigure,
    },
  },
  {
    path: '/main/director',
    actions: {
      read: Abilities.ReadMasterDataDirector,
      create: Abilities.CreateMasterDataDirector,
      update: Abilities.UpdateMasterDataDirector,
      delete: Abilities.DeleteMasterDataDirector,
    },
  },
  {
    path: '/main/director/create',
    actions: {
      create: Abilities.CreateMasterDataDirector,
    },
  },
  {
    path: '/main/director/$xid/edit',
    actions: {
      update: Abilities.UpdateMasterDataDirector,
      delete: Abilities.DeleteMasterDataDirector,
    },
  },
  {
    path: '/main/age-rating',
    actions: {
      read: Abilities.ReadMasterDataAgeRating,
      create: Abilities.CreateMasterDataAgeRating,
      update: Abilities.UpdateMasterDataAgeRating,
      delete: Abilities.DeleteMasterDataAgeRating,
    },
  },
  {
    path: '/main/age-rating/create',
    actions: {
      create: Abilities.CreateMasterDataAgeRating,
    },
  },
  {
    path: '/main/age-rating/$xid/edit',
    actions: {
      update: Abilities.UpdateMasterDataAgeRating,
      delete: Abilities.DeleteMasterDataAgeRating,
    },
  },
  {
    path: '/main/tagging',
    actions: {
      read: Abilities.ReadMasterDataTagging,
      create: Abilities.CreateMasterDataTagging,
      update: Abilities.UpdateMasterDataTagging,
      delete: Abilities.DeleteMasterDataTagging,
    },
  },
  {
    path: '/main/tagging/create',
    actions: {
      create: Abilities.CreateMasterDataTagging,
    },
  },
  {
    path: '/main/tagging/$xid/edit',
    actions: {
      update: Abilities.UpdateMasterDataTagging,
      delete: Abilities.DeleteMasterDataTagging,
    },
  },
  {
    path: '/main/subject',
    actions: {
      read: Abilities.ReadMasterDataSubject,
      create: Abilities.CreateMasterDataSubject,
      update: Abilities.UpdateMasterDataSubject,
      delete: Abilities.DeleteMasterDataSubject,
    },
  },
  {
    path: '/main/subject/create',
    actions: {
      create: Abilities.CreateMasterDataSubject,
    },
  },
  {
    path: '/main/subject/$xid/edit',
    actions: {
      update: Abilities.UpdateMasterDataSubject,
      delete: Abilities.DeleteMasterDataSubject,
    },
  },
  {
    path: '/main/speaker',
    actions: {
      read: Abilities.ReadMasterDataGuestSpeaker,
      create: Abilities.CreateMasterDataGuestSpeaker,
      update: Abilities.UpdateMasterDataGuestSpeaker,
      delete: Abilities.DeleteMasterDataGuestSpeaker,
    },
  },
  {
    path: '/main/speaker/create',
    actions: {
      create: Abilities.CreateMasterDataGuestSpeaker,
    },
  },
  {
    path: '/main/speaker/$xid/edit',
    actions: {
      update: Abilities.UpdateMasterDataGuestSpeaker,
      delete: Abilities.DeleteMasterDataGuestSpeaker,
    },
  },
  {
    path: '/main/about-us',
    actions: {
      read: Abilities.ReadOtherInformationAboutUs,
      update: Abilities.UpdateOtherInformationAboutUs,
    },
  },
  {
    path: '/main/contact-and-social-media',
    actions: {
      read: Abilities.ReadOtherInformationContact,
      create: Abilities.CreateOtherInformationContact,
      update: Abilities.UpdateOtherInformationContact,
      delete: Abilities.DeleteOtherInformationContact,
    },
  },
  {
    path: '/main/contact-and-social-media/create',
    actions: {
      create: Abilities.UpdateOtherInformationContact,
    },
  },
  {
    path: '/main/contact-and-social-media/$xid/edit',
    actions: {
      update: Abilities.CreateOtherInformationContact,
      delete: Abilities.DeleteOtherInformationContact,
    },
  },
  {
    path: '/main/term-condition',
    actions: {
      read: Abilities.ReadOtherInformationTermCondition,
      update: Abilities.UpdateOtherInformationTermCondition,
    },
  },
  {
    path: '/main/privacy-policy',
    actions: {
      read: Abilities.ReadOtherInformationPrivacyPolicy,
      update: Abilities.UpdateOtherInformationPrivacyPolicy,
    },
  },
  {
    path: '/main/feedback-url',
    actions: {
      read: Abilities.ReadOtherInformationFeedbackUrl,
      update: Abilities.UpdateOtherInformationFeedbackUrl,
    },
  },
  {
    path: '/main/faq',
    actions: {
      read: Abilities.ReadOtherInformationFaq,
      create: Abilities.CreateOtherInformationFaq,
      update: Abilities.UpdateOtherInformationFaq,
      delete: Abilities.DeleteOtherInformationFaq,
    },
  },
  {
    path: '/main/faq/create',
    actions: {
      create: Abilities.CreateOtherInformationFaq,
    },
  },
  {
    path: '/main/faq/$xid/edit',
    actions: {
      update: Abilities.UpdateOtherInformationFaq,
      delete: Abilities.DeleteOtherInformationFaq,
    },
  },
  {
    path: '/main/users-app',
    actions: {
      read: Abilities.ReadUserApplication,
    },
  },
  {
    path: '/main/users-cms',
    actions: {
      read: Abilities.ReadAdminCMS,
      create: Abilities.CreateAdminCMS,
      update: Abilities.UpdateAdminCMS,
      delete: Abilities.DeleteAdminCMS,
    },
  },
  {
    path: '/main/users-cms/create',
    actions: {
      create: Abilities.CreateAdminCMS,
    },
  },
  {
    path: '/main/users-cms/$xid/edit',
    actions: {
      update: Abilities.UpdateAdminCMS,
      delete: Abilities.DeleteAdminCMS,
    },
  },
  {
    path: '/main/role-management',
    actions: {
      read: Abilities.ReadUserManagement,
      create: Abilities.CreateUserManagement,
      update: Abilities.UpdateUserManagement,
      delete: Abilities.DeleteUserManagement,
    },
  },
  {
    path: '/main/role-management/create',
    actions: {
      create: Abilities.CreateUserManagement,
    },
  },
  {
    path: '/main/role-management/$xid/edit',
    actions: {
      update: Abilities.UpdateUserManagement,
      delete: Abilities.DeleteUserManagement,
    },
  },
  {
    path: '/main/highlights',
    actions: {
      read: Abilities.ReadProgramHighlight,
      update: Abilities.UpdateProgramHighlight,
    },
  },
]
