import { CSSObject } from '@emotion/react'
import styled from '@emotion/styled'
import { configStyledOptions, getVariant, Variant } from '@nbsdev/naini-react'

export type DropdownMenuItemLinkProps = {
  variant?: Variant
}

export const DropdownMenuItemLink = styled(
  'a',
  configStyledOptions<DropdownMenuItemLinkProps>(['variant'])
)<DropdownMenuItemLinkProps>(
  ({ theme, variant = 'primary' }): CSSObject => ({
    ...getVariant(theme.colors, variant!, false, false, true),
    display: 'flex',
    alignItems: 'center',
    width: '100%',
    borderRadius: '4px',
    padding: '12px',
    fontSize: '14px',
    fontWeight: 500,
    backgroundColor: '#FFF',
    fontFamily: theme.font.sans,
    '&:hover': {
      cursor: 'pointer',
      backgroundColor: theme.colors.outline.light,
    },
    '> :not([hidden]) ~ :not([hidden])': {
      marginLeft: '8px',
    },
    '> i': {
      fontSize: '20px',
      lineHeight: '20px',
    },
  })
)

DropdownMenuItemLink.displayName = 'DropdownMenuItemLink'
