import { PROGRAM_SUBMISSION_DRAFT_GENERAL_INFORMATION } from '@/configs/endpoint'
import { BaseResponse, ModifiedBy, TimestampAndVersion } from '@/types/common'
import { api } from '@/utils/api'

export type GetDetailProgramSubmissionGeneralInformationRequestParams = {
  xid: string
}

export type GetDetailProgramSubmissionGeneralInformationRequest = {
  params: GetDetailProgramSubmissionGeneralInformationRequestParams
}

export type GetDetailProgramSubmissionGeneralInformationResponse = BaseResponse<
  {
    xid: string
    name: string
    synopsis: {
      id: string
      en: string
    }
    ageRating: {
      xid: string
      name: string
    }
    category: {
      xid: string
      name: string
    }
    topLevelCategory: {
      xid: string
      name: string
    }
    tags: {
      items: Array<{
        xid: string
        name: string
      }>
    }
    releaseYear: string
    modifiedBy: ModifiedBy
    statusId: number
    programTypeId: number
    cover: null
    notes: string
    rejectedBy: ModifiedBy
    rejectedAt: string
  } & TimestampAndVersion
>

export const getDetailProgramSubmissionGeneralInformation = async (
  request: GetDetailProgramSubmissionGeneralInformationRequest
) => {
  const response = await api<
    GetDetailProgramSubmissionGeneralInformationResponse,
    GetDetailProgramSubmissionGeneralInformationRequestParams
  >({
    ...request,
    url: PROGRAM_SUBMISSION_DRAFT_GENERAL_INFORMATION,
    method: 'GET',
  })

  return response.data
}

export const getDetailProgramSubmissionGeneralInformationKey =
  'GET_DETAIL_PROGRAM_PROGRAM_SUBMISSION_GENERAL_INFORMATION_KEY'
