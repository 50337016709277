import { queryOptions, useMutation, useSuspenseQuery } from '@tanstack/react-query'

import { anonymous as fn, anonymousKey } from '@/services'

export const useAnonymous = () => {
  const {
    mutate: anonymous,
    data: anonymousData,
    error: anonymousError,
    isPending: anonymousIsPending,
    isSuccess: anonymousIsSuccess,
    isError: anonymousIsError,
  } = useMutation({
    mutationFn: fn,
    mutationKey: [anonymousKey],
  })

  return {
    anonymous,
    anonymousData,
    anonymousError,
    anonymousIsPending,
    anonymousIsSuccess,
    anonymousIsError,
  }
}

export const useSuspenseAnonymous = () => {
  const { data, isSuccess, isError } = useSuspenseQuery(anonymousOptions())

  return { data, isSuccess, isError }
}

export const anonymousOptions = () =>
  queryOptions({
    queryFn: fn,
    queryKey: [anonymousKey],
  })
