import { CSSObject } from '@emotion/react'
import { flip, offset, useClick, useDismiss, useFloating, useInteractions } from '@floating-ui/react'
import { Dropdown as NainiDropdown, DropdownButton, DropdownMenu, DropdownMenuItem, Variant } from '@nbsdev/naini-react'
import { useNavigate } from '@tanstack/react-router'
import { FC, Fragment, MouseEvent, useMemo, useState } from 'react'
import { useTranslation } from 'react-i18next'

import { DropdownMenuItemLink } from './DropdownMenuItemLink'

export type DropdownItem = {
  id: string
  label: string
  icon?: string
  variant?: Variant
  href?: string
  onClick?(): void
  css?: CSSObject
  permission?: boolean
}

export type DropdownProps = {
  label?: string
  items: DropdownItem[]
}

export const Dropdown: FC<DropdownProps> = ({ label, items }) => {
  const { t } = useTranslation()
  const navigate = useNavigate()
  const [isOpen, setIsOpen] = useState(false)

  const { refs, floatingStyles, context } = useFloating({
    placement: 'bottom-end',
    strategy: 'fixed',
    open: isOpen,
    onOpenChange: setIsOpen,
    middleware: [offset(8), flip()],
  })

  const { getReferenceProps, getFloatingProps } = useInteractions([
    useClick(context),
    useDismiss(context, { ancestorScroll: true }),
  ])

  const allowShowByPermission: boolean = useMemo(() => {
    return items.map((item) => item.permission === true).includes(true)
  }, [items])

  return (
    <NainiDropdown>
      <DropdownButton
        type='button'
        ref={refs.setReference}
        {...getReferenceProps()}
      >
        <span>{label || t('common:actions')}</span>

        {isOpen ? <i className='ri-arrow-up-s-line' /> : <i className='ri-arrow-down-s-line' />}
      </DropdownButton>

      {isOpen && allowShowByPermission && (
        <DropdownMenu
          ref={refs.setFloating}
          style={floatingStyles}
          {...getFloatingProps()}
        >
          {items.map((item) => (
            <Fragment key={item.id}>
              {item.href && item.permission && (
                <DropdownMenuItemLink
                  variant={item.variant ? item.variant : 'primary'}
                  href={item.href}
                  onClick={(e: MouseEvent<HTMLAnchorElement>) => {
                    e.preventDefault()

                    setIsOpen(false)

                    if (item.onClick) {
                      item.onClick()
                    } else {
                      navigate({ to: item.href }).catch(console.error)
                    }
                  }}
                  css={item.css}
                >
                  {item.icon && <i className={item.icon} />}

                  <span>{item.label}</span>
                </DropdownMenuItemLink>
              )}

              {!item.href && item.permission && (
                <DropdownMenuItem
                  variant={item.variant ? item.variant : 'primary'}
                  onClick={(e: MouseEvent<HTMLButtonElement>) => {
                    e.stopPropagation()

                    if (item.onClick) {
                      item.onClick()
                    }

                    setIsOpen(false)
                  }}
                  css={item.css}
                >
                  {item.icon && <i className={item.icon} />}

                  <span>{item.label}</span>
                </DropdownMenuItem>
              )}
            </Fragment>
          ))}
        </DropdownMenu>
      )}
    </NainiDropdown>
  )
}
