import { CSSObject } from '@emotion/react'
import styled from '@emotion/styled'
import { configStyledOptions, LayoutMainContent as NainiLayoutMainContent } from '@nbsdev/naini-react'

export type LayoutMainContentProps = {}

export const LayoutMainContent = styled(
  NainiLayoutMainContent,
  configStyledOptions<LayoutMainContentProps>([])
)<LayoutMainContentProps>(
  (): CSSObject => ({
    padding: '35px',
    display: 'flex',
    flexDirection: 'column',

    '>:not([hidden])~:not([hidden])': {
      marginTop: '30px',
    },
  })
)

LayoutMainContent.displayName = 'LayoutMainContent'
