import { queryOptions, useQuery, useSuspenseQuery } from '@tanstack/react-query'

import {
  getDetailProgramCoverage as queryFn,
  getDetailProgramCoverageKey,
  GetDetailProgramCoverageRequest,
} from '@/services'

export const useGetDetailProgramCoverage = (request: GetDetailProgramCoverageRequest, enabled = true) => {
  const {
    refetch: getDetailProgramCoverage,
    data: getDetailProgramCoverageData,
    error: getDetailProgramCoverageError,
    isFetching: getDetailProgramCoverageIsFetching,
    isPending: getDetailProgramCoverageIsPending,
    isSuccess: getDetailProgramCoverageIsSuccess,
    isError: getDetailProgramCoverageIsError,
  } = useQuery({
    enabled,
    queryFn: () => queryFn(request),
    queryKey: [getDetailProgramCoverageKey, request],
    refetchOnMount: false,
    refetchOnReconnect: false,
    refetchOnWindowFocus: false,
    refetchInterval: false,
    refetchIntervalInBackground: false,
    retryOnMount: false,
    gcTime: 0,
  })

  return {
    getDetailProgramCoverage,
    getDetailProgramCoverageData,
    getDetailProgramCoverageError,
    getDetailProgramCoverageIsFetching,
    getDetailProgramCoverageIsPending,
    getDetailProgramCoverageIsSuccess,
    getDetailProgramCoverageIsError,
  }
}

export const useSuspenseGetDetailProgramCoverage = (request: GetDetailProgramCoverageRequest) => {
  const { data: getDetailProgramCoverageData } = useSuspenseQuery(getDetailProgramCoverageOptions(request))

  return { getDetailProgramCoverageData }
}

export const getDetailProgramCoverageOptions = (request: GetDetailProgramCoverageRequest) =>
  queryOptions({
    queryFn: () => queryFn(request),
    queryKey: [getDetailProgramCoverageKey, request],
  })
