import styled, { CSSObject } from '@emotion/styled'
import {
  arrow,
  autoUpdate,
  flip,
  FloatingArrow,
  offset,
  Placement,
  shift,
  useClick,
  useDismiss,
  useFloating,
  useInteractions,
  useMergeRefs,
  useRole,
} from '@floating-ui/react'
import { FC, useEffect, useRef, useState } from 'react'

const Styled = styled('div')(
  (): CSSObject => ({
    backgroundColor: '#000000',
    color: '#FFFFFF',
    padding: '4px 12px',
    fontSize: '12px',
    borderRadius: '8px',
    margin: '0 !important',
  })
)

type TooltipProps = {
  label: string | JSX.Element
  className?: string
  placement?: Placement
  children: JSX.Element
}

export const Tooltip: FC<TooltipProps> = ({ children, label, placement = 'top' }) => {
  const [open, setOpen] = useState(false)
  const arrowRef = useRef(null)

  const { refs, floatingStyles, context } = useFloating({
    placement,
    open,
    onOpenChange: setOpen,
    middleware: [
      offset(5),
      flip(),
      shift({ padding: 8 }),
      arrow({
        element: arrowRef,
      }),
    ],
    whileElementsMounted: autoUpdate,
  })

  const click = useClick(context, { toggle: false })
  const role = useRole(context, { role: 'tooltip' })
  const dismiss = useDismiss(context)

  const { getReferenceProps, getFloatingProps } = useInteractions([click, role, dismiss])

  const ref = useMergeRefs([refs.setReference, (children as any).ref])

  useEffect(() => {
    if (!label) {
      setOpen(false)
    }
  }, [label])

  return (
    <>
      <div
        ref={ref}
        {...getReferenceProps()}
        css={{ display: 'inline-block' }}
      >
        {children}
      </div>

      {open && label && (
        <Styled
          ref={refs.setFloating}
          style={floatingStyles}
          {...getFloatingProps()}
        >
          <FloatingArrow
            ref={arrowRef}
            context={context}
          />
          {label}
        </Styled>
      )}
    </>
  )
}
