import { Outlet, useRouter } from '@tanstack/react-router'
import { FC, lazy, useEffect } from 'react'
import { useTranslation } from 'react-i18next'
import { useDocumentTitle } from 'usehooks-ts'

import { TopLoading } from '@/components'
import { appName, isProd } from '@/configs/env'
import { useLayoutStore } from '@/stores'

const TanStackRouterDevtools = isProd
  ? () => null
  : lazy(() =>
      import('@tanstack/router-devtools').then((res) => ({
        default: res.TanStackRouterDevtools,
      }))
    )

const ReactQueryDevtools = isProd
  ? () => null
  : lazy(() =>
      import('@tanstack/react-query-devtools').then((res) => ({
        default: res.ReactQueryDevtools,
      }))
    )

const BaseLayout: FC = () => {
  const router = useRouter()

  const { i18n } = useTranslation()

  const { loading, setLoading, staticData, setStaticData, setRouteId, setPathname } = useLayoutStore()

  useDocumentTitle(staticData.title ? `${i18n.t(staticData.title)} - ${appName}` : appName)

  useEffect(() => {
    const unsubscribeOnBeforeNavigate = router.subscribe('onBeforeNavigate', () => {
      setLoading(true)
    })

    const unsubscribeOnResolved = router.subscribe('onResolved', () => {
      const { routeId, pathname, staticData } = router.state.matches[router.state.matches.length - 1]

      setStaticData(staticData)
      setRouteId(routeId)
      setPathname(pathname)

      setLoading(false)
    })

    return () => {
      unsubscribeOnBeforeNavigate()

      unsubscribeOnResolved()
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  return (
    <>
      <TopLoading isAnimating={loading} />

      <Outlet />

      <TanStackRouterDevtools initialIsOpen={false} />

      <ReactQueryDevtools initialIsOpen={false} />
    </>
  )
}

export default BaseLayout
