import { GenericAbortSignal } from 'axios'

import { PROGRAM_SUBMISSION_DRAFT_OTHER_INFORMATION } from '@/configs/endpoint'
import { BaseResponse, FileData, ModifiedBy, TimestampAndVersion } from '@/types/common'
import { api } from '@/utils/api'

export type GetDetailProgramSubmissionOtherInformationRequestParams = {
  xid: string
}

export type GetDetailProgramSubmissionOtherInformationRequest = {
  params: GetDetailProgramSubmissionOtherInformationRequestParams
  signal?: GenericAbortSignal
}

export type GetDetailProgramSubmissionOtherInformationResponse = BaseResponse<
  {
    xid: string
    name: string
    otherInformation: null | {
      items: [
        {
          visible: boolean
          sequence: string
          informationTypeId: number
          key: string
          value: {
            items: Array<{
              xid: string
              name: string
            }>
          } | null
        },
      ]
    }
    cover: {
      notificationCover: FileData
      applicationCover: FileData
      topChartCover: FileData
    }
    modifiedBy: ModifiedBy
    statusId: number
    programTypeId: number
    publishedDate: string
    expiredAt: string
  } & TimestampAndVersion
>

export const getDetailProgramSubmissionOtherInformation = async (
  request: GetDetailProgramSubmissionOtherInformationRequest
) => {
  const response = await api<
    GetDetailProgramSubmissionOtherInformationResponse,
    GetDetailProgramSubmissionOtherInformationRequestParams
  >({
    ...request,
    url: PROGRAM_SUBMISSION_DRAFT_OTHER_INFORMATION,
    method: 'GET',
  })

  return response.data
}

export const getDetailProgramSubmissionOtherInformationKey =
  'GET_DETAIL_PROGRAM_PROGRAM_SUBMISSION_OTHER_INFORMATION_KEY'
