import { queryOptions, useQuery, useSuspenseQuery } from '@tanstack/react-query'

import { getDetailStaticSplashScreen as queryFn, getDetailStaticSplashScreenKey } from '@/services'

export const useGetDetailStaticSplashScreen = () => {
  const {
    refetch: getDetailStaticSplashScreen,
    data: getDetailStaticSplashScreenData,
    error: getDetailStaticSplashScreenError,
    isFetching: getDetailStaticSplashScreenIsFetching,
    isPending: getDetailStaticSplashScreenIsPending,
    isSuccess: getDetailStaticSplashScreenIsSuccess,
    isError: getDetailStaticSplashScreenIsError,
  } = useQuery({
    queryFn: () => queryFn(),
    queryKey: [getDetailStaticSplashScreenKey],
    refetchOnMount: false,
    refetchOnReconnect: false,
    refetchOnWindowFocus: false,
    refetchInterval: false,
    refetchIntervalInBackground: false,
    retryOnMount: false,
    gcTime: 0,
  })

  return {
    getDetailStaticSplashScreen,
    getDetailStaticSplashScreenData,
    getDetailStaticSplashScreenError,
    getDetailStaticSplashScreenIsFetching,
    getDetailStaticSplashScreenIsPending,
    getDetailStaticSplashScreenIsSuccess,
    getDetailStaticSplashScreenIsError,
  }
}

export const useSuspenseGetDetailStaticSplashScreen = () => {
  const { data: getDetailStaticSplashScreenData } = useSuspenseQuery(getDetailStaticSplashScreenOptions())

  return { getDetailStaticSplashScreenData }
}

export const getDetailStaticSplashScreenOptions = () =>
  queryOptions({
    queryFn: () => queryFn(),
    queryKey: [getDetailStaticSplashScreenKey],
  })
