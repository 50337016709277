import styled from '@emotion/styled'
import {
  configStyledOptions,
  SidebarContext,
  SidebarMenuDivider,
  SidebarMenuItem,
  SidebarMenuItemArrow,
  SidebarMenuItemIcon,
  SidebarMenuItemLabel,
  SidebarMenuItemLink,
} from '@nbsdev/naini-react'
import { useNavigate } from '@tanstack/react-router'
import { clsx } from 'clsx'
import { Property } from 'csstype'
import { FC, Fragment, MouseEvent, useCallback, useContext, useMemo } from 'react'
import { useTranslation } from 'react-i18next'

import { Abilities, Actions } from '@/configs/enum'
import { AbilityContext } from '@/contexts'

import { SidebarMenuData } from './SidebarMenu'

export type SidebarMenuContentProps = {
  items: SidebarMenuData[]
  collapsed?: boolean
  isChild?: boolean
  isExpanded?: boolean
}

const SidebarMenuContentWrapper = styled(
  'ul',
  configStyledOptions([])
)(() => ({
  display: 'flex',
  flexDirection: 'column' as Property.FlexDirection,
  alignItems: 'start',
  justifyContent: 'start',
  overflow: 'hidden auto',
  width: '100%',
  '&.child': {
    display: 'none',
    '&.collapsed': {
      display: 'none',
    },
    '&.expanded': {
      display: 'flex',
    },
    '> li': {
      paddingLeft: '39px',
      paddingRight: '0',
    },
    '> li > ul > li': {
      paddingLeft: '12px',
    },
    '@media (min-width: 1024px)': {
      '&.collapsed': {
        display: 'none',
      },
    },
  },
  '> li': {
    marginTop: '6px',
    marginBottom: '6px',
  },
  '> :first-of-type': {
    marginTop: 0,
  },
  '> :last-of-type': {
    marginBottom: 0,
  },
}))

export const SidebarMenuContent: FC<SidebarMenuContentProps> = ({ items, collapsed, isChild, isExpanded }) => {
  const { t } = useTranslation()
  const navigate = useNavigate()
  const { active, expandedIds, setExpandedIds } = useContext(SidebarContext)
  const ability = useContext(AbilityContext)

  const handleNavigate = useCallback((path?: string) => {
    return (e: MouseEvent) => {
      e.preventDefault()

      navigate({ to: path }).catch(console.error)
    }
  }, [])

  const handleExpand = (id: string) => {
    return (e: MouseEvent) => {
      e.preventDefault()
      e.stopPropagation()

      setExpandedIds((prev) => {
        if (prev.includes(id)) {
          return prev.filter((p) => p !== id)
        }

        return [...prev, id]
      })
    }
  }

  const menus = useMemo(() => {
    return items.filter((menu) => menu.access?.find((access) => ability.can(Actions.Access, access as Abilities)))
  }, [items, ability])

  return (
    <SidebarMenuContentWrapper
      className={clsx(collapsed && 'collapsed', isChild ? 'child' : 'parent', isExpanded && 'expanded')}
    >
      {menus.map((item) => (
        <Fragment key={item.id}>
          {/* Divider */}
          {item.type === 'divider' && <SidebarMenuDivider>{t(item.label)}</SidebarMenuDivider>}

          {item.type === 'link' && (
            <SidebarMenuItem>
              <SidebarMenuItemLink
                as='a'
                className={clsx(item.active?.includes(active) && 'active')}
                onClick={handleNavigate(item.path)}
                href={item.path}
              >
                {item.icon && <SidebarMenuItemIcon className={item.icon} />}

                <SidebarMenuItemLabel>{t(item.label)}</SidebarMenuItemLabel>
              </SidebarMenuItemLink>
            </SidebarMenuItem>
          )}

          {item.type === 'menu' && (
            <>
              <SidebarMenuItem>
                <SidebarMenuItemLink
                  as='div'
                  className={clsx(item.active?.includes(active) && 'active')}
                  onClick={handleExpand(item.id)}
                >
                  {item.icon && <SidebarMenuItemIcon className={item.icon} />}

                  <SidebarMenuItemLabel>{t(item.label)}</SidebarMenuItemLabel>

                  <SidebarMenuItemArrow>
                    <svg
                      width={20}
                      height={20}
                    >
                      {expandedIds.includes(item.id) ? (
                        <path
                          d='M9.99999 9.02329L5.87499 13.1483L4.69666 11.97L9.99999 6.66663L15.3033 11.97L14.125 13.1483L9.99999 9.02329Z'
                          fill='currentColor'
                        />
                      ) : (
                        <path
                          d='M10 10.977l4.125-4.125 1.178 1.178L10 13.333 4.697 8.03l1.178-1.178L10 10.977z'
                          fill='currentColor'
                        />
                      )}
                    </svg>
                  </SidebarMenuItemArrow>
                </SidebarMenuItemLink>

                <SidebarMenuContent
                  isChild
                  collapsed={collapsed}
                  isExpanded={expandedIds.includes(item.id)}
                  items={item.items || []}
                />
              </SidebarMenuItem>
            </>
          )}
        </Fragment>
      ))}
    </SidebarMenuContentWrapper>
  )
}

SidebarMenuContent.displayName = 'SidebarMenuContent'
