import { queryOptions, useQuery, useSuspenseQuery } from '@tanstack/react-query'

import { getDetailSpeaker as queryFn, getDetailSpeakerKey, GetDetailSpeakerRequest } from '@/services'

export const useGetDetailSpeaker = (request: GetDetailSpeakerRequest, enabled = true) => {
  const {
    refetch: getDetailSpeaker,
    data: getDetailSpeakerData,
    error: getDetailSpeakerError,
    isFetching: getDetailSpeakerIsFetching,
    isPending: getDetailSpeakerIsPending,
    isSuccess: getDetailSpeakerIsSuccess,
    isError: getDetailSpeakerIsError,
  } = useQuery({
    enabled,
    queryFn: () => queryFn(request),
    queryKey: [getDetailSpeakerKey, request],
    refetchOnMount: false,
    refetchOnReconnect: false,
    refetchOnWindowFocus: false,
    refetchInterval: false,
    refetchIntervalInBackground: false,
    retryOnMount: false,
    gcTime: 0,
  })

  return {
    getDetailSpeaker,
    getDetailSpeakerData,
    getDetailSpeakerError,
    getDetailSpeakerIsFetching,
    getDetailSpeakerIsPending,
    getDetailSpeakerIsSuccess,
    getDetailSpeakerIsError,
  }
}

export const useSuspenseGetDetailSpeaker = (request: GetDetailSpeakerRequest) => {
  const { data: getDetailSpeakerData } = useSuspenseQuery(getDetailSpeakerOptions(request))

  return { getDetailSpeakerData }
}

export const getDetailSpeakerOptions = (request: GetDetailSpeakerRequest) =>
  queryOptions({
    queryFn: () => queryFn(request),
    queryKey: [getDetailSpeakerKey, request],
  })
