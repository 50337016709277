import { DIRECTOR_DETAIL } from '@/configs/endpoint'
import { BaseResponse, TimestampAndVersion } from '@/types/common'
import { api } from '@/utils/api'

export type GetDetailDirectorRequestParams = {
  xid: string
}

export type GetDetailDirectorRequest = {
  params: GetDetailDirectorRequestParams
}

export type GetDetailDirectorResponse = BaseResponse<
  {
    xid: string
    name: string
  } & TimestampAndVersion
>

export const getDetailDirector = async (request: GetDetailDirectorRequest) => {
  const response = await api<GetDetailDirectorResponse, GetDetailDirectorRequestParams>({
    ...request,
    url: DIRECTOR_DETAIL,
    method: 'GET',
  })

  return response.data
}

export const getDetailDirectorKey = 'GET_DETAIL_DIRECTOR'
