import { STATIC_SPLASH_SCREEN } from '@/configs/endpoint'
import { BaseResponse, FileData, ModifiedBy, TimestampAndVersion } from '@/types/common'
import { api } from '@/utils/api'

export type GetDetailStaticSplashScreenResponse = BaseResponse<
  {
    cover: FileData
    modifiedBy: ModifiedBy
  } & TimestampAndVersion
>

export const getDetailStaticSplashScreen = async () => {
  const response = await api<GetDetailStaticSplashScreenResponse, never>({
    url: STATIC_SPLASH_SCREEN,
    method: 'GET',
  })

  return response.data
}

export const getDetailStaticSplashScreenKey = 'GET_DETAIL_STATIC_SPLASH_SCREEN'
