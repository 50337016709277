import { PROGRAM_EPISODE_DETAIL } from '@/configs/endpoint'
import { BaseResponse, FileData, ModifiedBy, TimestampAndVersion } from '@/types/common'
import { api } from '@/utils/api'

export type GetDetailProgramEpisodeRequestParams = {
  programXid: string
  episodeXid: string
}

export type GetDetailProgramEpisodeRequest = {
  params: GetDetailProgramEpisodeRequestParams
}

export type GetDetailProgramEpisodeResponse = BaseResponse<{
  programEpisode: {
    xid: string
    name: string
    sequence: string
    modifiedBy: ModifiedBy
    statusId: number
    publishedDate: string
    duration: string
  } & TimestampAndVersion
  informationProgramEpisode: {
    trailers: {
      items: null | Array<
        FileData & {
          selected: boolean
        }
      >
    }
    covers: {
      items: null | Array<
        FileData & {
          selected: boolean
        }
      >
    }
    subtitles: {
      items: null | Array<
        FileData & {
          subtitleType: number
        }
      >
    }
    wiwaras: {
      items: null | Array<
        FileData & {
          selected: boolean
        }
      >
    }
    expiredAt: string
    notes: string
    timeCreditDuration: string
    programPublishedDate: string
    programExpiredAt: string
    kumulateRefId: string
    linkHLS: string
    assetType: string
    purgeDate: string
    source: string
    isTimeCreditDurationDefault: boolean
    publishedDate: string
  }
}>

export const getDetailProgramEpisode = async (request: GetDetailProgramEpisodeRequest) => {
  const response = await api<GetDetailProgramEpisodeResponse, GetDetailProgramEpisodeRequestParams>({
    ...request,
    url: PROGRAM_EPISODE_DETAIL,
    method: 'GET',
  })

  return response.data
}

export const getDetailProgramEpisodeKey = 'GET_DETAIL_PROGRAM_EPISODE'
