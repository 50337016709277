import { SLIDE_SHOWS_DETAIL } from '@/configs/endpoint'
import { ControlStatus } from '@/configs/enum'
import { BaseResponse, FileData, ModifiedBy, TimestampAndVersion } from '@/types/common'
import { api } from '@/utils/api'

export type GetDetailSlideShowsRequestParams = {
  xid: string
}

export type GetDetailSlideShowsRequest = {
  params: GetDetailSlideShowsRequestParams
}

export type GetDetailSlideShowsResponse = BaseResponse<
  {
    xid: string
    title: string
    sequence: string
    cover: FileData
    status: ControlStatus
    modifiedBy: ModifiedBy
  } & TimestampAndVersion
>

export const getDetailSlideShows = async (request: GetDetailSlideShowsRequest) => {
  const response = await api<GetDetailSlideShowsResponse, GetDetailSlideShowsRequestParams>({
    ...request,
    url: SLIDE_SHOWS_DETAIL,
    method: 'GET',
  })

  return response.data
}

export const getDetailSlideShowsKey = 'GET_DETAIL_SLIDE_SHOWS'
