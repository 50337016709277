import { CSSObject } from '@emotion/react'
import styled from '@emotion/styled'

export const AuthContent = styled('form')(
  (): CSSObject => ({
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'start',
    justifyContent: 'center',
    width: '100%',
    padding: '32px',

    '>:not([hidden])~:not([hidden])': {
      marginTop: '24px',
    },

    '@media (min-width: 1280px)': {
      padding: '32px 125px',
    },
  })
)
AuthContent.displayName = 'AuthContent'
