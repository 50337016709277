import { CSSObject } from '@emotion/react'
import styled from '@emotion/styled'
import { configStyledOptions, FlexColumn, Typography } from '@nbsdev/naini-react'
import { FC } from 'react'
import { useTranslation } from 'react-i18next'

import { IconSearchNotFound } from '../Icon'

const Styled = styled(
  'div',
  configStyledOptions([])
)(
  (): CSSObject => ({
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    alignItems: 'center',
    padding: '120px 24px',
    width: '100%',
    '>:not([hidden])~:not([hidden])': {
      marginTop: '16px',
    },
  })
)

export const SearchNotFound: FC = () => {
  const { t } = useTranslation()

  return (
    <Styled>
      <FlexColumn
        justifyContent='center'
        alignItems='center'
        space={8}
      >
        <IconSearchNotFound />

        <Typography
          size='xl'
          weight='bold'
        >
          {t('common:searchNotFound.title')}
        </Typography>
      </FlexColumn>

      <Typography size='sm'>{t('common:searchNotFound.description')}</Typography>
    </Styled>
  )
}
