import { StaticDataRouteOption } from '@tanstack/react-router'
import { create } from 'zustand'

interface LayoutState {
  loading: boolean
  setLoading: (loading: boolean) => void
  routeId: string
  setRouteId: (routeId: string) => void
  pathname: string
  setPathname: (pathname: string) => void
  staticData: StaticDataRouteOption
  setStaticData: (staticData: StaticDataRouteOption) => void
}

export const useLayoutStore = create<LayoutState>()((set) => ({
  loading: false,
  setLoading: (loading: boolean) => {
    set((state) => ({ ...state, loading }))
  },
  routeId: '__root__',
  setRouteId: (routeId: string) => {
    set((state) => ({ ...state, routeId }))
  },
  pathname: '/',
  setPathname: (pathname: string) => {
    set((state) => ({ ...state, pathname }))
  },
  staticData: {
    title: '',
  },
  setStaticData: (staticData: StaticDataRouteOption) => {
    set((state) => ({ ...state, staticData }))
  },
}))
