import { CSSObject } from '@emotion/react'
import styled from '@emotion/styled'
import { FlexColumn, Typography } from '@nbsdev/naini-react'
import { Link } from '@tanstack/react-router'

import { IconInvalidLink, LogoInvalidLink } from '@/components'

const Styled = styled('div')(
  (): CSSObject => ({
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    alignItems: 'center',
    background: '#F8F8F8',
    width: '100vw',
    height: '100vh',

    '> :not([hidden]) ~ :not([hidden])': {
      marginTop: '44px',
    },
  })
)

export const InvalidLink = () => {
  return (
    <Styled>
      <LogoInvalidLink />

      <FlexColumn
        space={24}
        justifyContent='center'
        alignItems='center'
        css={{ width: '512px', height: 'fit-content', padding: '44px 24px', background: '#FFF' }}
      >
        <IconInvalidLink />

        <Typography
          size='xl'
          weight='bold'
        >
          Tautan Sudah Kadaluwarsa
        </Typography>

        <Typography css={{ textAlign: 'center' }}>
          Tautan aktivasi akun sudah kadaluwarsa, silakan hubungi{' '}
          <Link
            href='/'
            css={{ color: '#5C77FF', textDecorationLine: 'underline' }}
          >
            customer support
          </Link>{' '}
          Indonesiana TV untuk mengirim ulang tautan aktivasi akun CMS.
        </Typography>
      </FlexColumn>
    </Styled>
  )
}
