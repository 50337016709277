import { CSSObject } from '@emotion/react'
import styled from '@emotion/styled'

export const AuthHeader = styled('div')(
  (): CSSObject => ({
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'flex-start',
    justifyContent: 'center',
    width: '100%',

    '>:not([hidden])~:not([hidden])': {
      marginTop: '12px',
    },
  })
)

AuthHeader.displayName = 'AuthHeader'
