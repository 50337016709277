import { PROFILE } from '@/configs/endpoint'
import { BaseResponse, CommonData, EnumData, FileData, ModifiedBy } from '@/types/common'
import { api } from '@/utils/api'

export type Admin = {
  avatar: FileData | null
  xid: string
  fullName: string
  email: string
  phone: string
  status: EnumData
  createdAt: string
  updatedAt: string
  modifiedBy: ModifiedBy
  version: string
  isEmailVerify: boolean
  subRole: CommonData
  newEmail: any
}

export type ProfileResponse = BaseResponse<{
  admin: Admin
  privileges: string[]
}>

export const profile = async () => {
  const response = await api<ProfileResponse>({
    url: PROFILE,
    method: 'GET',
  })

  return response.data
}

export const profileKey = 'PROFILE'
