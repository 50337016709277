import { queryOptions, useQuery, useSuspenseQuery } from '@tanstack/react-query'

import {
  getDetailProgramEpisode as queryFn,
  getDetailProgramEpisodeKey,
  GetDetailProgramEpisodeRequest,
} from '@/services'

export const useGetDetailProgramEpisode = (request: GetDetailProgramEpisodeRequest, enabled = true) => {
  const {
    refetch: getDetailProgramEpisode,
    data: getDetailProgramEpisodeData,
    error: getDetailProgramEpisodeError,
    isFetching: getDetailProgramEpisodeIsFetching,
    isPending: getDetailProgramEpisodeIsPending,
    isSuccess: getDetailProgramEpisodeIsSuccess,
    isError: getDetailProgramEpisodeIsError,
  } = useQuery({
    enabled,
    queryFn: () => queryFn(request),
    queryKey: [getDetailProgramEpisodeKey, request],
    refetchOnMount: false,
    refetchOnReconnect: false,
    refetchOnWindowFocus: false,
    refetchInterval: false,
    refetchIntervalInBackground: false,
    retryOnMount: false,
    gcTime: 0,
  })

  return {
    getDetailProgramEpisode,
    getDetailProgramEpisodeData,
    getDetailProgramEpisodeError,
    getDetailProgramEpisodeIsFetching,
    getDetailProgramEpisodeIsPending,
    getDetailProgramEpisodeIsSuccess,
    getDetailProgramEpisodeIsError,
  }
}

export const useSuspenseGetDetailProgramEpisode = (request: GetDetailProgramEpisodeRequest) => {
  const { data: getDetailProgramEpisodeData } = useSuspenseQuery(getDetailProgramEpisodeOptions(request))

  return { getDetailProgramEpisodeData }
}

export const getDetailProgramEpisodeOptions = (request: GetDetailProgramEpisodeRequest) =>
  queryOptions({
    queryFn: () => queryFn(request),
    queryKey: [getDetailProgramEpisodeKey, request],
  })
