import { queryOptions, useQuery, useSuspenseQuery } from '@tanstack/react-query'

import { getFeedbackUrl as queryFn, getFeedbackUrlKey } from '@/services'

export const useGetFeedbackUrl = (enabled = true) => {
  const {
    refetch: getFeedbackUrl,
    data: getFeedbackUrlData,
    error: getFeedbackUrlError,
    isFetching: getFeedbackUrlIsFetching,
    isPending: getFeedbackUrlIsPending,
    isSuccess: getFeedbackUrlIsSuccess,
    isError: getFeedbackUrlIsError,
  } = useQuery({
    enabled,
    queryFn: () => queryFn(),
    queryKey: [getFeedbackUrlKey],
    refetchOnMount: false,
    refetchOnReconnect: false,
    refetchOnWindowFocus: false,
    refetchInterval: false,
    refetchIntervalInBackground: false,
    retryOnMount: false,
    gcTime: 0,
  })

  return {
    getFeedbackUrl,
    getFeedbackUrlData,
    getFeedbackUrlError,
    getFeedbackUrlIsFetching,
    getFeedbackUrlIsPending,
    getFeedbackUrlIsSuccess,
    getFeedbackUrlIsError,
  }
}

export const useSuspenseGetFeedbackUrl = () => {
  const { data: getFeedbackUrlData } = useSuspenseQuery(getFeedbackUrlOptions())

  return { getFeedbackUrlData }
}

export const getFeedbackUrlOptions = () =>
  queryOptions({
    queryFn: () => queryFn(),
    queryKey: [getFeedbackUrlKey],
  })
