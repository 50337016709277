import axios from 'axios'

import { ANONYMOUS } from '@/configs/endpoint'
import { apiBaseUrl, apiClientId, apiClientSecret } from '@/configs/env'
import { BaseResponse } from '@/types/common'
import { removeAppToken, setAppAccessToken } from '@/utils/app'

export type AnonymousResponse = BaseResponse<{
  session: {
    token: string
    expiredAt: number
  }
  privileges: string[]
}>

export const anonymous = async () => {
  const client = axios.create({
    baseURL: apiBaseUrl,
  })

  try {
    const response = await client.request<AnonymousResponse>({
      url: ANONYMOUS,
      method: 'POST',
      auth: {
        username: apiClientId,
        password: apiClientSecret,
      },
    })

    setAppAccessToken(response.data.data.session.token, response.data.data.session.expiredAt)

    return true
  } catch (e) {
    removeAppToken()

    throw e
  }
}

export const anonymousKey = 'ANONYMOUS'
