import { FC } from 'react'

export const IconInvalidLink: FC = () => {
  return (
    <svg
      width='80'
      height='80'
      viewBox='0 0 80 80'
      fill='none'
      xmlns='http://www.w3.org/2000/svg'
    >
      <g
        id='broken-link 1'
        clipPath='url(#clip0_7973_88039)'
      >
        <g id='g63'>
          <g id='g65'>
            <g id='Clip path group'>
              <mask
                id='mask0_7973_88039'
                // style='mask-type:luminance'
                maskUnits='userSpaceOnUse'
                x='-1'
                y='-1'
                width='81'
                height='81'
              >
                <g id='clipPath71'>
                  <path
                    id='path69'
                    d='M-0.000976562 -0.00389862H79.999V79.9961H-0.000976562V-0.00389862Z'
                    fill='white'
                  />
                </g>
              </mask>
              <g mask='url(#mask0_7973_88039)'>
                <g id='g67'>
                  <g id='g73'>
                    <path
                      id='path75'
                      d='M58.1311 49.1895L71.955 35.3254C79.4772 27.7812 79.4772 15.5496 71.955 8.00539H71.9548C64.4327 0.461176 52.2367 0.461176 44.7144 8.00539L30.8906 21.8695L37.9406 28.9402L51.1292 15.7131C54.758 12.0738 60.6409 12.0738 64.2697 15.7131C67.8983 19.3523 67.8983 25.2526 64.2697 28.8916L51.0809 42.1188L58.1311 49.1895Z'
                      stroke='#5C77FF'
                      strokeWidth='4.6875'
                      strokeMiterlimit='10'
                      strokeLinecap='round'
                      strokeLinejoin='round'
                    />
                  </g>
                  <g id='g77'>
                    <path
                      id='path79'
                      d='M21.449 31.3383L8.10318 44.723C0.58084 52.2672 0.58084 64.4988 8.10318 72.043C15.6254 79.5872 27.8215 79.5872 35.3437 72.043L48.6894 58.6583L41.6394 51.5875L28.9288 64.3354C25.3002 67.9744 19.4171 67.9744 15.7885 64.3354C12.1599 60.6961 12.1599 54.7958 15.7885 51.1566L28.4991 38.4089L21.449 31.3383Z'
                      stroke='#5C77FF'
                      strokeWidth='4.6875'
                      strokeMiterlimit='10'
                      strokeLinecap='round'
                      strokeLinejoin='round'
                    />
                  </g>
                  <g id='g81'>
                    <path
                      id='path83'
                      d='M2.93457 24.2298L13.7883 26.9512'
                      stroke='#5C77FF'
                      strokeWidth='4.6875'
                      strokeMiterlimit='10'
                      strokeLinecap='round'
                      strokeLinejoin='round'
                    />
                  </g>
                  <g id='g85'>
                    <path
                      id='path87'
                      d='M20.3912 20.3281L11.9795 11.8919'
                      stroke='#5C77FF'
                      strokeWidth='4.6875'
                      strokeMiterlimit='10'
                      strokeLinecap='round'
                      strokeLinejoin='round'
                    />
                  </g>
                  <g id='g89'>
                    <path
                      id='path91'
                      d='M24.2803 2.82156L26.9937 13.707'
                      stroke='#5C77FF'
                      strokeWidth='4.6875'
                      strokeMiterlimit='10'
                      strokeLinecap='round'
                      strokeLinejoin='round'
                    />
                  </g>
                  <g id='g93'>
                    <path
                      id='path95'
                      d='M77.1238 55.8184L66.27 53.097'
                      stroke='#5C77FF'
                      strokeWidth='4.6875'
                      strokeMiterlimit='10'
                      strokeLinecap='round'
                      strokeLinejoin='round'
                    />
                  </g>
                  <g id='g97'>
                    <path
                      id='path99'
                      d='M59.667 59.72L68.0787 68.1562'
                      stroke='#5C77FF'
                      strokeWidth='4.6875'
                      strokeMiterlimit='10'
                      strokeLinecap='round'
                      strokeLinejoin='round'
                    />
                  </g>
                  <g id='g101'>
                    <path
                      id='path103'
                      d='M55.7779 77.2266L53.0645 66.3411'
                      stroke='#5C77FF'
                      strokeWidth='4.6875'
                      strokeMiterlimit='10'
                      strokeLinecap='round'
                      strokeLinejoin='round'
                    />
                  </g>
                </g>
              </g>
            </g>
          </g>
        </g>
      </g>
      <defs>
        <clipPath id='clip0_7973_88039'>
          <rect
            width='80'
            height='80'
            fill='white'
          />
        </clipPath>
      </defs>
    </svg>
  )
}
