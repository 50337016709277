import { create } from 'zustand'

interface ProgramSubmissionState {
  loading: boolean
  setLoading: (loading: boolean) => void
  selected: string
  setSelected: (pathname: string) => void
  openSidePanelDetail: string
  setOpenSidePanelDetail: (openSidePanelDetail: string) => void
  openSidePanelListVideo: boolean
  setOpenSidePanelListVideo: (openSidePanelListVideo: boolean) => void
  importKumulateTitle: string
  setImportKumulateTitle: (KumulateTitle: string) => void
  detailVideo: {
    xid: string
    title: string
    deleteXid: string
  }
  setDetailVideo: (xid: string, title: string, deleteXid: string) => void
  modalDeleteVideoEpisode: {
    xid: string
    title: string
  }
  setModalDeleteVideoEpisode: (xid: string, title: string) => void
  modalDeleteVideo: {
    xid: string
    title: string
  }
  setModalDeleteVideo: (xid: string, title: string) => void
  modalDeleteProgram: {
    xid: string
    title: string
  }
  setModalDeleteProgram: (xid: string, title: string) => void
}

export const useProgramSubmissionStore = create<ProgramSubmissionState>()((set) => ({
  loading: false,
  setLoading: (loading: boolean) => {
    set((state) => ({ ...state, loading }))
  },
  selected: '',
  setSelected: (selected: string) => {
    set((state) => ({ ...state, selected }))
  },
  openSidePanelDetail: '',
  setOpenSidePanelDetail: (openSidePanelDetail: string) => {
    set((state) => ({ ...state, openSidePanelDetail }))
  },
  openSidePanelListVideo: false,
  setOpenSidePanelListVideo: (openSidePanelListVideo: boolean) => {
    set((state) => ({ ...state, openSidePanelListVideo }))
  },
  importKumulateTitle: '',
  setImportKumulateTitle: (importKumulateTitle: string) => {
    set((state) => ({ ...state, importKumulateTitle }))
  },
  detailVideo: {
    xid: '',
    title: '',
    deleteXid: '',
  },
  setDetailVideo: (xid: string, title: string, deleteXid: string) => {
    set((state) => ({ ...state, detailVideo: { xid, title, deleteXid } }))
  },
  modalDeleteVideoEpisode: {
    xid: '',
    title: '',
  },
  setModalDeleteVideoEpisode: (xid: string, title: string) => {
    set((state) => ({ ...state, modalDeleteVideoEpisode: { xid, title } }))
  },
  modalDeleteVideo: {
    xid: '',
    title: '',
  },
  setModalDeleteVideo: (xid: string, title: string) => {
    set((state) => ({ ...state, modalDeleteVideo: { xid, title } }))
  },
  modalDeleteProgram: {
    xid: '',
    title: '',
  },
  setModalDeleteProgram: (xid: string, title: string) => {
    set((state) => ({ ...state, modalDeleteProgram: { xid, title } }))
  },
}))
