import { PROGRAM_COVERAGE_DETAIL } from '@/configs/endpoint'
import { BaseResponse, FileData, ModifiedBy, TimestampAndVersion } from '@/types/common'
import { api } from '@/utils/api'

export type GetDetailProgramCoverageRequestParams = {
  xid: string
}

export type GetDetailProgramCoverageRequest = {
  params: GetDetailProgramCoverageRequestParams
}

export type GetDetailProgramCoverageResponse = BaseResponse<{
  programCoverage: {
    xid: string
    name: string
    modifiedBy: ModifiedBy
    statusId: number
    content: {
      fileName: string
      url: string
      signature: string
      duration: string
      lastWatchingDuration: string
      subtitle: string | null
      wiwara: string | null
      timeCreditDuration: string
      lastWatchingAt: string
      eventBook: string | null
    }
    cover: FileData
    isUploadManual: boolean
    contentKumulateXid: string
  } & TimestampAndVersion
  contentKumulate: {
    xid: string
    name: string
    kumulateRefId: string
    covers: null | {
      items: FileData[]
    }
    modifiedBy: ModifiedBy
    statusId: number
    releaseYear: string
    genre: string
    duration: string
  } & TimestampAndVersion
}>

export const getDetailProgramCoverage = async (request: GetDetailProgramCoverageRequest) => {
  const response = await api<GetDetailProgramCoverageResponse, GetDetailProgramCoverageRequestParams>({
    ...request,
    url: PROGRAM_COVERAGE_DETAIL,
    method: 'GET',
  })

  return response.data
}

export const getDetailProgramCoverageKey = 'GET_DETAIL_PROGRAM_COVERAGE'
