import { SidebarMenuData } from './SidebarMenu'

export function findAllIdsByActive(menuItems: SidebarMenuData[], activeValue: string, result: string[] = []): string[] {
  // This outer loop iterates over each top-level menu item
  for (const item of menuItems) {
    // Check if this item's 'active' array contains the activeValue
    if (item.active && item.active.includes(activeValue)) {
      result.push(item.id)
    }

    // If this item has children, call the function recursively on the children
    if (item.items) {
      findAllIdsByActive(item.items, activeValue, result)
    }
  }

  // If no item was found that matches, return undefined
  return result
}
