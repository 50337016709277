import { PROGRAM_SUBMISSION_DRAFT_EPISODE_DETAIL } from '@/configs/endpoint'
import { BaseResponse, FileData, ModifiedBy, TimestampAndVersion } from '@/types/common'
import { api } from '@/utils/api'

export type GetDetailProgramSubmissionVideoEpisodeRequestParams = {
  xid: string
  episodeXid: string
}

export type GetDetailProgramSubmissionVideoEpisodeRequest = {
  params: GetDetailProgramSubmissionVideoEpisodeRequestParams
}

export type GetDetailProgramSubmissionVideoEpisodeResponse = BaseResponse<{
  programEpisodeDraft: {
    xid: string
    name: string
    sequence: string
    modifiedBy: ModifiedBy
    statusId: number
    covers: {
      items: null | Array<
        FileData & {
          selected: boolean
        }
      >
    }
    publishedDate: string
    duration: string
  } & TimestampAndVersion
  informationProgramEpisodeDraft: {
    trailers: {
      items: null | Array<
        FileData & {
          selected: boolean
        }
      >
    }
    subtitles: {
      items: null | Array<
        FileData & {
          subtitleType: number
        }
      >
    }
    wiwaras: {
      items: null | Array<
        FileData & {
          selected: boolean
        }
      >
    }
    expiredAt: string
    notes: string
    timeCreditDuration: string
    programPublishedDate: string
    programExpiredAt: string
    kumulateRefId: string
    linkHLS: string
    assetType: string
    purgeDate: string
    source: string
    isTimeCreditDurationDefault: boolean
  }
}>

export const getDetailProgramSubmissionVideoEpisode = async (
  request: GetDetailProgramSubmissionVideoEpisodeRequest
) => {
  const response = await api<
    GetDetailProgramSubmissionVideoEpisodeResponse,
    GetDetailProgramSubmissionVideoEpisodeRequestParams
  >({
    ...request,
    url: PROGRAM_SUBMISSION_DRAFT_EPISODE_DETAIL,
    method: 'GET',
  })

  return response.data
}

export const getDetailProgramSubmissionVideoEpisodeKey = 'GET_DETAIL_PROGRAM_PROGRAM_SUBMISSION_VIDEO_EPISODE_KEY'
