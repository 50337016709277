import { CSSObject } from '@emotion/react'
import styled from '@emotion/styled'
import { useNProgress } from '@tanem/react-nprogress'
import { FC } from 'react'

import { TopLoadingBar } from './TopLoadingBar'

type Props = {
  isAnimating: boolean
}

const Styled = styled('div')<{ animationDuration: number; isFinished: boolean }>(
  ({ animationDuration, isFinished }): CSSObject => ({
    opacity: isFinished ? 0 : 1,
    pointerEvents: 'none',
    transition: `opacity ${animationDuration}ms linear`,
  })
)

export const TopLoading: FC<Props> = ({ isAnimating }) => {
  const { animationDuration, isFinished, progress } = useNProgress({
    isAnimating,
    incrementDuration: 0.1,
    animationDuration: 1000,
  })

  return (
    <Styled
      animationDuration={animationDuration}
      isFinished={isFinished}
    >
      {!isFinished && (
        <TopLoadingBar
          progress={progress}
          animationDuration={animationDuration}
        />
      )}
    </Styled>
  )
}
