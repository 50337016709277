import {
  createRootRouteWithContext,
  createRoute,
  createRouter,
  lazyRouteComponent,
  redirect,
  RouteContext,
} from '@tanstack/react-router'
import { DateTime } from 'luxon'
import { z } from 'zod'

import { InternalServerError, InvalidLink, LoadingSpinner, PageNotFound } from '@/components'
import {
  anonymousOptions,
  getDetailAgeRatingOptions,
  getDetailBannerOptions,
  getDetailCastOptions,
  getDetailCategoryOptions,
  getDetailDirectorOptions,
  getDetailDynamicContentOptions,
  getDetailDynamicSplashScreenOptions,
  getDetailFigureOptions,
  getDetailHighlightsOptions,
  getDetailLiveStreamsOptions,
  getDetailNotificationBroadcastsOptions,
  getDetailPrivacyPolicyOptions,
  getDetailProgramCoverageOptions,
  getDetailProgramEpisodeOptions,
  getDetailProgramOptions,
  getDetailProgramSubmissionVideoEpisodeOptions,
  getDetailSlideShowsOptions,
  getDetailSpeakerOptions,
  getDetailSubjectOptions,
  getDetailSubRolesOptions,
  getDetailTagOptions,
  getDetailTermConditionOptions,
  getDetailUsersCmsOptions,
  getFeedbackUrlOptions,
  profileOptions,
  refreshTokenOptions,
} from '@/hooks'
import {
  getDetailProgramSubmissionGeneralInformationOptions,
  getDetailProgramSubmissionOtherInformationOptions,
} from '@/hooks'
import { getListLiveStreamProgramsOptions } from '@/hooks/live-streams/programs'
import { getDetailStaticSplashScreenOptions } from '@/hooks/static-splash-screen'
import { getDetailSubCategoryOptions } from '@/hooks/sub-category'
import BaseLayout from '@/layouts/BaseLayout'
import {
  getDetailAgeRatingKey,
  getDetailBannerKey,
  getDetailCastKey,
  getDetailCategoryKey,
  getDetailDirectorKey,
  getDetailDynamicContentKey,
  getDetailDynamicSplashScreenKey,
  getDetailFigureKey,
  getDetailHighlightsKey,
  getDetailLiveStreamsKey,
  getDetailNotificationBroadcastsKey,
  getDetailPrivacyPolicyKey,
  getDetailProgramCoverageKey,
  getDetailProgramEpisodeKey,
  getDetailProgramKey,
  getDetailProgramSubmissionGeneralInformationKey,
  getDetailProgramSubmissionOtherInformationKey,
  getDetailProgramSubmissionVideoEpisodeKey,
  getDetailSlideShowsKey,
  getDetailSpeakerKey,
  getDetailStaticSplashScreenKey,
  getDetailSubCategoryKey,
  getDetailSubjectKey,
  getDetailSubRolesKey,
  getDetailTagKey,
  getDetailTermConditionKey,
  getDetailUsersCmsKey,
  getFeedbackUrlKey,
  getListLiveStreamProgramsKey,
  profileKey,
} from '@/services'
import { isHasAppAccessToken, isHasUserAccessToken, isHasUserRefreshToken } from '@/utils/app'
import { decodeJWT } from '@/utils/formatter'

// Begin: Root Route
const rootRoute = createRootRouteWithContext<RouteContext>()({
  staticData: {
    title: '',
  },
  component: BaseLayout,
  notFoundComponent: PageNotFound,
  errorComponent: InternalServerError,
})

const rootBlankRoute = createRoute({
  getParentRoute: () => rootRoute,
  id: 'blank',
  staticData: {
    title: '',
  },
  component: lazyRouteComponent(() => import('@/layouts/BlankLayout')),
})

const rootGuestRoute = createRoute({
  getParentRoute: () => rootRoute,
  id: 'guest',
  staticData: {
    title: '',
  },
  component: lazyRouteComponent(() => import('@/layouts/GuestLayout')),
  beforeLoad: async (opts) => {
    if (!isHasAppAccessToken()) {
      await opts.context.queryClient.ensureQueryData(anonymousOptions())
    }

    if (isHasUserAccessToken() || isHasUserRefreshToken()) {
      throw redirect({
        to: '/dashboard',
      })
    }
  },
})

const rootMainRoute = createRoute({
  getParentRoute: () => rootRoute,
  id: 'main',
  staticData: {
    title: '',
  },
  component: lazyRouteComponent(() => import('@/layouts/MainLayout')),
  beforeLoad: async (opts) => {
    if (!isHasAppAccessToken()) {
      await opts.context.queryClient.ensureQueryData(anonymousOptions())
    }

    if (!isHasUserAccessToken() && isHasUserRefreshToken()) {
      await opts.context.queryClient.ensureQueryData(refreshTokenOptions())
    }

    if (!isHasUserAccessToken() && !isHasUserRefreshToken()) {
      throw redirect({
        to: '/auth/login',
      })
    }
  },
  loader: (opts) => opts.context.queryClient.ensureQueryData(profileOptions()),
  pendingComponent: LoadingSpinner,
})
// End: Root Route

// Begin: Blank Layout
const mainRoute = createRoute({
  getParentRoute: () => rootBlankRoute,
  path: '/',
  staticData: {
    title: '',
  },
  beforeLoad: async () => {
    if (isHasUserAccessToken() || isHasUserRefreshToken()) {
      throw redirect({
        to: '/dashboard',
      })
    }

    throw redirect({
      to: '/auth/login',
    })
  },
})

const healthRoute = createRoute({
  getParentRoute: () => rootBlankRoute,
  path: '/_health-check',
  staticData: {
    title: 'Health Check',
  },
  component: lazyRouteComponent(() => import('@/views/health')),
})
// End: Blank Layout

// Begin: Guest Layout
const loginRoute = createRoute({
  getParentRoute: () => rootGuestRoute,
  path: '/auth/login',
  staticData: {
    title: 'Login',
  },
  component: lazyRouteComponent(() => import('@/views/auth/login')),
})

const forgotPasswordRoute = createRoute({
  getParentRoute: () => rootGuestRoute,
  path: '/auth/forgot-password',
  staticData: {
    title: 'Forgot Password',
  },
  component: lazyRouteComponent(() => import('@/views/auth/forgot-password')),
})

const resetPasswordRoute = createRoute({
  getParentRoute: () => rootGuestRoute,
  path: '/auth/reset-password',
  staticData: {
    title: 'Reset Password',
  },
  component: lazyRouteComponent(() => import('@/views/auth/reset-password')),
  validateSearch: (search) => z.object({ t: z.string() }).parse(search),
  beforeLoad: async (opts) => {
    if (!opts.search.t) {
      throw new Error()
    }

    if (opts.search.t && DateTime.utc().toSeconds() > decodeJWT(opts.search.t)?.exp) {
      throw new Error()
    }
  },
  errorComponent: InvalidLink,
})

const setupPasswordRoute = createRoute({
  getParentRoute: () => rootGuestRoute,
  path: '/auth/setup-password',
  staticData: {
    title: 'Setup Password',
  },
  component: lazyRouteComponent(() => import('@/views/auth/setup-password')),
  validateSearch: (search) => z.object({ t: z.string() }).parse(search),
  beforeLoad: async (opts) => {
    if (!opts.search.t) {
      throw new Error()
    }

    if (opts.search.t && DateTime.utc().toSeconds() > decodeJWT(opts.search.t)?.exp) {
      throw new Error()
    }
  },
  errorComponent: InvalidLink,
})

const setupPasswordSuccessRoute = createRoute({
  getParentRoute: () => rootGuestRoute,
  path: '/auth/setup-password/success',
  staticData: {
    title: 'Setup Password',
  },
  component: lazyRouteComponent(() => import('@/views/auth/setup-password/success')),
})
// End: Guest Layout

// Begin: Authenticated Layout
const dashboardRoute = createRoute({
  getParentRoute: () => rootMainRoute,
  path: '/dashboard',
  staticData: {
    title: 'Dashboard',
  },
  component: lazyRouteComponent(() => import('@/views/dashboard')),
})

// Banner
const bannerRoute = createRoute({
  getParentRoute: () => rootMainRoute,
  path: '/banner',
  staticData: {
    title: 'Manajemen Banner',
  },
  component: lazyRouteComponent(() => import('@/views/banner/list')),
})

const bannerCreateRoute = createRoute({
  getParentRoute: () => rootMainRoute,
  path: '/banner/create',
  staticData: {
    title: 'Tambah Banner',
  },
  component: lazyRouteComponent(() => import('@/views/banner/create')),
})

const bannerEditRoute = createRoute({
  getParentRoute: () => rootMainRoute,
  path: '/banner/$xid/edit',
  staticData: {
    title: 'Edit Banner',
  },
  component: lazyRouteComponent(() => import('@/views/banner/edit')),
  loader: async (opts) => {
    opts.context.queryClient.removeQueries({ queryKey: [getDetailBannerKey] })

    return await opts.context.queryClient.ensureQueryData(getDetailBannerOptions({ params: opts.params }))
  },
  pendingComponent: LoadingSpinner,
  gcTime: 0,
})

// notification broadcast
const notificationBroadcastRoute = createRoute({
  getParentRoute: () => rootMainRoute,
  path: '/notification-broadcast',
  staticData: {
    title: 'Siaran Notifikasi',
  },
  component: lazyRouteComponent(() => import('@/views/notification-broadcast/list')),
})

const notificationBroadcastCreateRoute = createRoute({
  getParentRoute: () => rootMainRoute,
  path: '/notification-broadcast/create',
  staticData: {
    title: 'Tambah Siaran Notifikasi',
  },
  component: lazyRouteComponent(() => import('@/views/notification-broadcast/create')),
})

const notificationBroadcastEditRoute = createRoute({
  getParentRoute: () => rootMainRoute,
  path: '/notification-broadcast/$xid/edit',
  staticData: {
    title: 'Edit Siaran Notifikasi',
  },
  component: lazyRouteComponent(() => import('@/views/notification-broadcast/edit')),
  loader: async (opts) => {
    opts.context.queryClient.removeQueries({ queryKey: [getDetailNotificationBroadcastsKey] })

    return await opts.context.queryClient.ensureQueryData(
      getDetailNotificationBroadcastsOptions({ params: opts.params })
    )
  },
  pendingComponent: LoadingSpinner,
  gcTime: 0,
})

const notificationBroadcastDetailRoute = createRoute({
  getParentRoute: () => rootMainRoute,
  path: '/notification-broadcast/$xid/detail',
  staticData: {
    title: 'Detail Siaran Notifikasi',
  },
  component: lazyRouteComponent(() => import('@/views/notification-broadcast/detail')),
  loader: async (opts) => {
    opts.context.queryClient.removeQueries({ queryKey: [getDetailNotificationBroadcastsKey] })

    return await opts.context.queryClient.ensureQueryData(
      getDetailNotificationBroadcastsOptions({ params: opts.params })
    )
  },
  pendingComponent: LoadingSpinner,
  gcTime: 0,
})

// Dynamic Content
const dynamicContentRoute = createRoute({
  getParentRoute: () => rootMainRoute,
  path: '/dynamic-content',
  staticData: {
    title: 'Konten Dinamis',
  },
  component: lazyRouteComponent(() => import('@/views/dynamic-content/list')),
})

const dynamicContentCreateRoute = createRoute({
  getParentRoute: () => rootMainRoute,
  path: '/dynamic-content/create',
  staticData: {
    title: 'Tambah Section',
  },
  component: lazyRouteComponent(() => import('@/views/dynamic-content/create')),
})

const dynamicContentEditRoute = createRoute({
  getParentRoute: () => rootMainRoute,
  path: '/dynamic-content/$xid/edit',
  staticData: {
    title: 'Edit Section',
  },
  component: lazyRouteComponent(() => import('@/views/dynamic-content/edit')),
  loader: async (opts) => {
    opts.context.queryClient.removeQueries({ queryKey: [getDetailDynamicContentKey] })

    return await opts.context.queryClient.ensureQueryData(getDetailDynamicContentOptions({ params: opts.params }))
  },
  pendingComponent: LoadingSpinner,
  gcTime: 0,
})

// Program

const programNeedReviewRoute = createRoute({
  getParentRoute: () => rootMainRoute,
  path: '/program-need-review',
  staticData: {
    title: 'Program Butuh Direview',
  },
  component: lazyRouteComponent(() => import('@/views/program/need-review/list')),
})

const programNeedReviewDetailRoute = createRoute({
  getParentRoute: () => rootMainRoute,
  path: '/program-need-review/$xid',
  staticData: {
    title: '',
  },
  component: lazyRouteComponent(() => import('@/views/program/layout/ProgramNeedReviewDetailLayout')),
  loader: async (opts) => {
    opts.context.queryClient.removeQueries({ queryKey: [getDetailProgramSubmissionGeneralInformationKey] })
    opts.context.queryClient.removeQueries({ queryKey: [getDetailProgramSubmissionOtherInformationKey] })

    const generalData = await opts.context.queryClient.ensureQueryData(
      getDetailProgramSubmissionGeneralInformationOptions({ params: opts.params })
    )
    const otherInformation = await opts.context.queryClient.ensureQueryData(
      getDetailProgramSubmissionOtherInformationOptions({ params: opts.params })
    )

    return { generalData, otherInformation }
  },
  pendingComponent: LoadingSpinner,
  gcTime: 0,
})

const programNeedReviewDetailGeneralDataRoute = createRoute({
  getParentRoute: () => programNeedReviewDetailRoute,
  path: '/general-data',
  staticData: {
    title: 'Detail Program - Data Umum',
  },
  component: lazyRouteComponent(() => import('@/views/program/need-review/detail/general-data')),
})

const programNeedReviewDetailEpisodesRoute = createRoute({
  getParentRoute: () => programNeedReviewDetailRoute,
  path: '/episodes',
  staticData: {
    title: 'Detail Program - Daftar Episode',
  },
  component: lazyRouteComponent(() => import('@/views/program/need-review/detail/episodes')),
})

const programNeedReviewDetailInfoRoute = createRoute({
  getParentRoute: () => programNeedReviewDetailRoute,
  path: '/info',
  staticData: {
    title: 'Detail Program - Informasi Lainnya',
  },
  component: lazyRouteComponent(() => import('@/views/program/need-review/detail/info')),
})

const programNeedReviewDetailCoverRoute = createRoute({
  getParentRoute: () => programNeedReviewDetailRoute,
  path: '/cover',
  staticData: {
    title: 'Detail Program - Cover',
  },
  component: lazyRouteComponent(() => import('@/views/program/need-review/detail/cover')),
})

const programNeedReviewDetailPublicationRoute = createRoute({
  getParentRoute: () => programNeedReviewDetailRoute,
  path: '/publication',
  staticData: {
    title: 'Detail Program - Publication',
  },
  component: lazyRouteComponent(() => import('@/views/program/need-review/detail/publication')),
})

const programSubmissionRoute = createRoute({
  getParentRoute: () => rootMainRoute,
  path: '/program-submission',
  staticData: {
    title: 'Program Draft',
  },
  component: lazyRouteComponent(() => import('@/views/program/submission/list')),
})

const programSubmissionCreateTypeRoute = createRoute({
  getParentRoute: () => rootMainRoute,
  path: '/program-submission/create/type',
  staticData: {
    title: 'Buat Program',
  },
  component: lazyRouteComponent(() => import('@/views/program/submission/create/type')),
})

const programSubmissionCreateGeneralRoute = createRoute({
  getParentRoute: () => rootMainRoute,
  path: '/program-submission/create/general',
  staticData: {
    title: 'Buat Program',
  },
  component: lazyRouteComponent(() => import('@/views/program/submission/create/general')),
})

const programSubmissionEditKumulateRoute = createRoute({
  getParentRoute: () => rootMainRoute,
  path: '/program-submission/create/$xid/draft/video/edit/$episodeXid/episode',
  staticData: {
    title: 'Buat Program',
  },
  component: lazyRouteComponent(() => import('@/views/program/submission/create/video/kumulate/edit')),
  loader: async (opts) => {
    opts.context.queryClient.removeQueries({ queryKey: [getDetailProgramSubmissionVideoEpisodeKey] })

    return await opts.context.queryClient.ensureQueryData(
      getDetailProgramSubmissionVideoEpisodeOptions({ params: opts.params })
    )
  },
  pendingComponent: LoadingSpinner,
  gcTime: 0,
})

const programSubmissionCreatedRoute = createRoute({
  getParentRoute: () => rootMainRoute,
  path: '/program-submission/create/$xid/draft',
  staticData: {
    title: '',
  },
  component: lazyRouteComponent(() => import('@/views/program/layout/ProgramFormLayout')),
  loader: async (opts) => {
    opts.context.queryClient.removeQueries({ queryKey: [getDetailProgramSubmissionGeneralInformationKey] })

    return await opts.context.queryClient.ensureQueryData(
      getDetailProgramSubmissionGeneralInformationOptions({ params: opts.params })
    )
  },
  pendingComponent: LoadingSpinner,
  gcTime: 0,
})

const programSubmissionCreateDraftGeneralRoute = createRoute({
  getParentRoute: () => programSubmissionCreatedRoute,
  path: '/general',
  staticData: {
    title: 'Buat Program',
  },
  component: lazyRouteComponent(() => import('@/views/program/submission/create/general/draft')),
})

const programSubmissionCreateDraftVideoRoute = createRoute({
  getParentRoute: () => programSubmissionCreatedRoute,
  path: '/video',
  staticData: {
    title: 'Buat Program',
  },
  component: lazyRouteComponent(() => import('@/views/program/submission/create/video')),
})

const programSubmissionCreateDraftOtherRoute = createRoute({
  getParentRoute: () => programSubmissionCreatedRoute,
  path: '/other-information',
  staticData: {
    title: 'Buat Program',
  },
  component: lazyRouteComponent(() => import('@/views/program/submission/create/other-information')),
  loader: async (opts) => {
    opts.context.queryClient.removeQueries({ queryKey: [getDetailProgramSubmissionOtherInformationKey] })

    return await opts.context.queryClient.ensureQueryData(
      getDetailProgramSubmissionOtherInformationOptions({ params: opts.params })
    )
  },
  pendingComponent: LoadingSpinner,
  gcTime: 0,
})

const programSubmissionCreateDraftCoverRoute = createRoute({
  getParentRoute: () => programSubmissionCreatedRoute,
  path: '/cover',
  staticData: {
    title: 'Buat Program',
  },
  component: lazyRouteComponent(() => import('@/views/program/submission/create/cover')),
  loader: async (opts) => {
    opts.context.queryClient.removeQueries({ queryKey: [getDetailProgramSubmissionOtherInformationKey] })

    return await opts.context.queryClient.ensureQueryData(
      getDetailProgramSubmissionOtherInformationOptions({ params: opts.params })
    )
  },
  pendingComponent: LoadingSpinner,
  gcTime: 0,
})

const programSubmissionCreateDraftPublicationRoute = createRoute({
  getParentRoute: () => programSubmissionCreatedRoute,
  path: '/publication',
  staticData: {
    title: 'Buat Program',
  },
  component: lazyRouteComponent(() => import('@/views/program/submission/create/publication')),
  loader: async (opts) => {
    opts.context.queryClient.removeQueries({ queryKey: [getDetailProgramSubmissionOtherInformationKey] })

    return await opts.context.queryClient.ensureQueryData(
      getDetailProgramSubmissionOtherInformationOptions({ params: opts.params })
    )
  },
  pendingComponent: LoadingSpinner,
  gcTime: 0,
})

const programSubmissionDetailRoute = createRoute({
  getParentRoute: () => rootMainRoute,
  path: '/program-submission/$xid/detail',
  staticData: {
    title: '',
  },
  component: lazyRouteComponent(() => import('@/views/program/layout/ProgramSubmissionDetailLayout')),
  loader: async (opts) => {
    opts.context.queryClient.removeQueries({ queryKey: [getDetailProgramSubmissionGeneralInformationKey] })

    return await opts.context.queryClient.ensureQueryData(
      getDetailProgramSubmissionGeneralInformationOptions({ params: opts.params })
    )
  },
  pendingComponent: LoadingSpinner,
  gcTime: 0,
})

const programSubmissionDetailDraftGeneralRoute = createRoute({
  getParentRoute: () => programSubmissionDetailRoute,
  path: '/general',
  staticData: {
    title: 'Buat Program',
  },
  component: lazyRouteComponent(() => import('@/views/program/submission/detail/general/draft')),
})

const programSubmissionDetailDraftVideoRoute = createRoute({
  getParentRoute: () => programSubmissionDetailRoute,
  path: '/video',
  staticData: {
    title: 'Buat Program',
  },
  component: lazyRouteComponent(() => import('@/views/program/submission/detail/video')),
})

const programSubmissionDetailDraftOtherRoute = createRoute({
  getParentRoute: () => programSubmissionDetailRoute,
  path: '/other-information',
  staticData: {
    title: 'Buat Program',
  },
  component: lazyRouteComponent(() => import('@/views/program/submission/detail/other-information')),
  loader: async (opts) => {
    opts.context.queryClient.removeQueries({ queryKey: [getDetailProgramSubmissionOtherInformationKey] })

    return await opts.context.queryClient.ensureQueryData(
      getDetailProgramSubmissionOtherInformationOptions({ params: opts.params })
    )
  },
  pendingComponent: LoadingSpinner,
  gcTime: 0,
})

const programSubmissionDetailDraftCoverRoute = createRoute({
  getParentRoute: () => programSubmissionDetailRoute,
  path: '/cover',
  staticData: {
    title: 'Buat Program',
  },
  component: lazyRouteComponent(() => import('@/views/program/submission/detail/cover')),
  loader: async (opts) => {
    opts.context.queryClient.removeQueries({ queryKey: [getDetailProgramSubmissionOtherInformationKey] })

    return await opts.context.queryClient.ensureQueryData(
      getDetailProgramSubmissionOtherInformationOptions({ params: opts.params })
    )
  },
  pendingComponent: LoadingSpinner,
  gcTime: 0,
})

const programSubmissionDetailDraftPublicationRoute = createRoute({
  getParentRoute: () => programSubmissionDetailRoute,
  path: '/publication',
  staticData: {
    title: 'Buat Program',
  },
  component: lazyRouteComponent(() => import('@/views/program/submission/detail/publication')),
  loader: async (opts) => {
    opts.context.queryClient.removeQueries({ queryKey: [getDetailProgramSubmissionOtherInformationKey] })

    return await opts.context.queryClient.ensureQueryData(
      getDetailProgramSubmissionOtherInformationOptions({ params: opts.params })
    )
  },
  pendingComponent: LoadingSpinner,
  gcTime: 0,
})

const programActiveRoute = createRoute({
  getParentRoute: () => rootMainRoute,
  path: '/program-active',
  staticData: {
    title: 'Program Aktif',
  },
  component: lazyRouteComponent(() => import('@/views/program/active/list')),
})

const programActiveEditRoute = createRoute({
  getParentRoute: () => rootMainRoute,
  path: '/program-active/$xid/edit',
  staticData: {
    title: '',
  },
  loader: async (opts) => {
    opts.context.queryClient.removeQueries({ queryKey: [getDetailProgramKey] })

    return await opts.context.queryClient.ensureQueryData(getDetailProgramOptions({ params: opts.params }))
  },
  gcTime: 0,
  component: lazyRouteComponent(() => import('@/views/program/layout/ProgramEditLayout')),
})

const programActiveEditGeneralDataRoute = createRoute({
  getParentRoute: () => programActiveEditRoute,
  path: '/general-data',
  staticData: {
    title: 'Edit Program - Data Umum',
  },
  component: lazyRouteComponent(() => import('@/views/program/active/edit/general-data')),
  pendingComponent: LoadingSpinner,
  gcTime: 0,
})

const programActiveEditCoverRoute = createRoute({
  getParentRoute: () => programActiveEditRoute,
  path: '/cover',
  staticData: {
    title: 'Edit Program - Cover',
  },
  component: lazyRouteComponent(() => import('@/views/program/active/edit/cover')),
  pendingComponent: LoadingSpinner,
  gcTime: 0,
})

const programActiveEditPublicationRoute = createRoute({
  getParentRoute: () => programActiveEditRoute,
  path: '/publication',
  staticData: {
    title: 'Edit Program - Publication',
  },
  component: lazyRouteComponent(() => import('@/views/program/active/edit/publication')),
  pendingComponent: LoadingSpinner,
  gcTime: 0,
})

const programActiveEditOtherInformationRoute = createRoute({
  getParentRoute: () => programActiveEditRoute,
  path: '/info',
  staticData: {
    title: 'Edit Program - Other Information',
  },
  component: lazyRouteComponent(() => import('@/views/program/active/edit/other-information')),
  pendingComponent: LoadingSpinner,
  gcTime: 0,
})

const programActiveEditEpisodeRoute = createRoute({
  getParentRoute: () => programActiveEditRoute,
  path: '/episodes',
  staticData: {
    title: 'Edit Program - Episode',
  },
  component: lazyRouteComponent(() => import('@/views/program/active/edit/episodes')),
  pendingComponent: LoadingSpinner,
  gcTime: 0,
})

const programActiveEditKumulateRoute = createRoute({
  getParentRoute: () => rootMainRoute,
  path: '/program-active/edit/$programXid/draft/video/edit/$episodeXid/episode',
  staticData: {
    title: 'Edit Program',
  },
  component: lazyRouteComponent(() => import('@/views/program/active/edit/episodes/kumulate/edit')),
  loader: async (opts) => {
    opts.context.queryClient.removeQueries({ queryKey: [getDetailProgramEpisodeKey] })

    return await opts.context.queryClient.ensureQueryData(getDetailProgramEpisodeOptions({ params: opts.params }))
  },
  pendingComponent: LoadingSpinner,
  gcTime: 0,
})

const programActiveDetailRoute = createRoute({
  getParentRoute: () => rootMainRoute,
  path: '/program-active/$xid',
  staticData: {
    title: '',
  },
  loader: async (opts) => {
    opts.context.queryClient.removeQueries({ queryKey: [getDetailProgramEpisodeKey] })

    return await opts.context.queryClient.ensureQueryData(getDetailProgramOptions({ params: opts.params }))
  },
  component: lazyRouteComponent(() => import('@/views/program/layout/ProgramDetailLayout')),
  gcTime: 0,
})

const programActiveDetailGeneralDataRoute = createRoute({
  getParentRoute: () => programActiveDetailRoute,
  path: '/general-data',
  staticData: {
    title: 'Detail Program - Data Umum',
  },
  component: lazyRouteComponent(() => import('@/views/program/active/detail/general-data')),
  pendingComponent: LoadingSpinner,
  gcTime: 0,
})

const programActiveDetailEpisodesRoute = createRoute({
  getParentRoute: () => programActiveDetailRoute,
  path: '/episodes',
  staticData: {
    title: 'Detail Program - Daftar Episode',
  },
  component: lazyRouteComponent(() => import('@/views/program/active/detail/episodes')),
  pendingComponent: LoadingSpinner,
  gcTime: 0,
})

const programActiveDetailInfoRoute = createRoute({
  getParentRoute: () => programActiveDetailRoute,
  path: '/info',
  staticData: {
    title: 'Detail Program - Informasi Lainnya',
  },
  component: lazyRouteComponent(() => import('@/views/program/active/detail/info')),
  pendingComponent: LoadingSpinner,
  gcTime: 0,
})

const programActiveDetailCoverRoute = createRoute({
  getParentRoute: () => programActiveDetailRoute,
  path: '/cover',
  staticData: {
    title: 'Detail Program - Cover',
  },
  component: lazyRouteComponent(() => import('@/views/program/active/detail/cover')),
  pendingComponent: LoadingSpinner,
  gcTime: 0,
})

const programStatisticRoute = createRoute({
  getParentRoute: () => rootMainRoute,
  path: '/program-statistic',
  staticData: {
    title: 'Statistik Program',
  },
  component: lazyRouteComponent(() => import('@/views/program-statistic/list')),
})

// Live Channel
const liveChannelRoute = createRoute({
  getParentRoute: () => rootMainRoute,
  path: '/live-channel',
  staticData: {
    title: 'Kanal Live',
  },
  component: lazyRouteComponent(() => import('@/views/live-channel/list')),
})

const liveChannelCreateRoute = createRoute({
  getParentRoute: () => rootMainRoute,
  path: '/live-channel/create',
  staticData: {
    title: 'Kanal Live',
  },
  component: lazyRouteComponent(() => import('@/views/live-channel/create')),
  gcTime: 0,
})

const liveChannelEditRoute = createRoute({
  getParentRoute: () => rootMainRoute,
  path: '/live-channel/$xid/edit',
  staticData: {
    title: 'Kanal Live',
  },
  component: lazyRouteComponent(() => import('@/views/live-channel/layout/LiveChannelEditLayout')),
  loader: async (opts) => {
    opts.context.queryClient.removeQueries({ queryKey: [getDetailLiveStreamsKey] })
    opts.context.queryClient.removeQueries({ queryKey: [getListLiveStreamProgramsKey] })
    const schedule = await opts.context.queryClient.ensureQueryData(
      getListLiveStreamProgramsOptions({ params: opts.params })
    )
    const data = await opts.context.queryClient.ensureQueryData(getDetailLiveStreamsOptions({ params: opts.params }))

    return { schedule: schedule.data, data: data.data }
  },
  pendingComponent: LoadingSpinner,
  gcTime: 0,
})

const liveChannelEditGeneralRoute = createRoute({
  getParentRoute: () => liveChannelEditRoute,
  path: '/general',
  staticData: {
    title: 'Kanal Live',
  },
  component: lazyRouteComponent(() => import('@/views/live-channel/edit/general-data')),
})

const liveChannelEditCoverAndVideoRoute = createRoute({
  getParentRoute: () => liveChannelEditRoute,
  path: '/cover-and-video',
  staticData: {
    title: 'Kanal Live',
  },
  component: lazyRouteComponent(() => import('@/views/live-channel/edit/cover-and-video')),
})

const liveChannelEditScheduleRoute = createRoute({
  getParentRoute: () => liveChannelEditRoute,
  path: '/schedule',
  staticData: {
    title: 'Kanal Live',
  },
  component: lazyRouteComponent(() => import('@/views/live-channel/edit/schedule')),
})

// other information
const termConditionRoute = createRoute({
  getParentRoute: () => rootMainRoute,
  path: '/term-condition',
  staticData: {
    title: 'Syarat & Ketentuan',
  },
  loader: async (opts) => {
    opts.context.queryClient.removeQueries({ queryKey: [getDetailTermConditionKey] })

    return await opts.context.queryClient.ensureQueryData(getDetailTermConditionOptions())
  },
  pendingComponent: LoadingSpinner,
  gcTime: 0,
  component: lazyRouteComponent(() => import('@/views/term-condition')),
})

const privacyPolicyRoute = createRoute({
  getParentRoute: () => rootMainRoute,
  path: '/privacy-policy',
  staticData: {
    title: 'Kebijakan Privasi',
  },
  loader: async (opts) => {
    opts.context.queryClient.removeQueries({ queryKey: [getDetailPrivacyPolicyKey] })

    return await opts.context.queryClient.ensureQueryData(getDetailPrivacyPolicyOptions())
  },
  gcTime: 0,
  component: lazyRouteComponent(() => import('@/views/privacy-policy')),
})

const feedbackUrlRoute = createRoute({
  getParentRoute: () => rootMainRoute,
  path: '/feedback-url',
  staticData: {
    title: 'Tautan Saran & Masukan',
  },
  component: lazyRouteComponent(() => import('@/views/feedback-url')),
  gcTime: 0,
  loader: async (opts) => {
    opts.context.queryClient.removeQueries({ queryKey: [getFeedbackUrlKey] })

    return await opts.context.queryClient.ensureQueryData(getFeedbackUrlOptions())
  },
})

const categoryRoute = createRoute({
  getParentRoute: () => rootMainRoute,
  path: '/category',
  staticData: {
    title: 'Kategori',
  },
  component: lazyRouteComponent(() => import('@/views/category/list')),
})

const categoryCreateRoute = createRoute({
  getParentRoute: () => rootMainRoute,
  path: '/category/create',
  staticData: {
    title: 'Tambah Kategori',
  },
  component: lazyRouteComponent(() => import('@/views/category/create')),
})

const categoryEditRoute = createRoute({
  getParentRoute: () => rootMainRoute,
  path: '/category/$xid/edit',
  staticData: {
    title: 'Edit Kategori',
  },
  component: lazyRouteComponent(() => import('@/views/category/edit')),
  loader: async (opts) => {
    opts.context.queryClient.removeQueries({ queryKey: [getDetailCategoryKey] })

    return await opts.context.queryClient.ensureQueryData(getDetailCategoryOptions({ params: opts.params }))
  },
  pendingComponent: LoadingSpinner,
  gcTime: 0,
})

const subCategoryCreateRoute = createRoute({
  getParentRoute: () => rootMainRoute,
  path: '/category/$xid/create-sub-category',
  staticData: {
    title: 'Tambah Kategori',
  },
  component: lazyRouteComponent(() => import('@/views/category/sub/create')),
})

const subCategoryEditRoute = createRoute({
  getParentRoute: () => rootMainRoute,
  path: '/category/$xid/edit/$subXid/edit-sub-category',
  staticData: {
    title: 'Edit Kategori',
  },
  component: lazyRouteComponent(() => import('@/views/category/sub/edit')),
  loader: async (opts) => {
    opts.context.queryClient.removeQueries({ queryKey: [getDetailSubCategoryKey] })

    return await opts.context.queryClient.ensureQueryData(
      getDetailSubCategoryOptions({ params: { xid: opts.params.subXid } })
    )
  },
  pendingComponent: LoadingSpinner,
  gcTime: 0,
})

// Cast
const castRoute = createRoute({
  getParentRoute: () => rootMainRoute,
  path: '/cast',
  staticData: {
    title: 'Pemeran',
  },
  component: lazyRouteComponent(() => import('@/views/cast/list')),
})

const castCreateRoute = createRoute({
  getParentRoute: () => rootMainRoute,
  path: '/cast/create',
  staticData: {
    title: 'Tambah Pemeran',
  },
  component: lazyRouteComponent(() => import('@/views/cast/create')),
})

const castEditRoute = createRoute({
  getParentRoute: () => rootMainRoute,
  path: '/cast/$xid/edit',
  staticData: {
    title: 'Edit Pemeran',
  },
  loader: async (opts) => {
    opts.context.queryClient.removeQueries({ queryKey: [getDetailCastKey] })

    return await opts.context.queryClient.ensureQueryData(getDetailCastOptions({ params: opts.params }))
  },
  component: lazyRouteComponent(() => import('@/views/cast/edit')),
  pendingComponent: LoadingSpinner,
  gcTime: 0,
})

// Figure
const figureRoute = createRoute({
  getParentRoute: () => rootMainRoute,
  path: '/figure',
  staticData: {
    title: 'Tokoh',
  },
  component: lazyRouteComponent(() => import('@/views/figure/list')),
})

const figureCreateRoute = createRoute({
  getParentRoute: () => rootMainRoute,
  path: '/figure/create',
  staticData: {
    title: 'Tambah Tokoh',
  },
  component: lazyRouteComponent(() => import('@/views/figure/create')),
})

const figureEditRoute = createRoute({
  getParentRoute: () => rootMainRoute,
  path: '/figure/$xid/edit',
  staticData: {
    title: 'Edit Tokoh',
  },
  component: lazyRouteComponent(() => import('@/views/figure/edit')),
  loader: async (opts) => {
    opts.context.queryClient.removeQueries({ queryKey: [getDetailFigureKey] })

    return await opts.context.queryClient.ensureQueryData(getDetailFigureOptions({ params: opts.params }))
  },
  pendingComponent: LoadingSpinner,
  gcTime: 0,
})

// Age Rating
const ageRatingRoute = createRoute({
  getParentRoute: () => rootMainRoute,
  path: '/age-rating',
  staticData: {
    title: 'Rating Usia',
  },
  component: lazyRouteComponent(() => import('@/views/age-rating/list')),
})

const ageRatingCreateRoute = createRoute({
  getParentRoute: () => rootMainRoute,
  path: '/age-rating/create',
  staticData: {
    title: 'Tambah Rating Usia',
  },
  component: lazyRouteComponent(() => import('@/views/age-rating/create')),
})

const ageRatingEditRoute = createRoute({
  getParentRoute: () => rootMainRoute,
  path: '/age-rating/$xid/edit',
  staticData: {
    title: 'Edit Rating Usia',
  },
  component: lazyRouteComponent(() => import('@/views/age-rating/edit')),
  loader: async (opts) => {
    opts.context.queryClient.removeQueries({ queryKey: [getDetailAgeRatingKey] })

    return await opts.context.queryClient.ensureQueryData(getDetailAgeRatingOptions({ params: opts.params }))
  },
  pendingComponent: LoadingSpinner,
  gcTime: 0,
})

// Director
const directorRoute = createRoute({
  getParentRoute: () => rootMainRoute,
  path: '/director',
  staticData: {
    title: 'Sutradara',
  },
  component: lazyRouteComponent(() => import('@/views/director/list')),
})

const directorCreateRoute = createRoute({
  getParentRoute: () => rootMainRoute,
  path: '/director/create',
  staticData: {
    title: 'Tambah Sutradara',
  },
  component: lazyRouteComponent(() => import('@/views/director/create')),
})

const directorEditRoute = createRoute({
  getParentRoute: () => rootMainRoute,
  path: '/director/$xid/edit',
  staticData: {
    title: 'Edit Sutradara',
  },
  component: lazyRouteComponent(() => import('@/views/director/edit')),
  loader: async (opts) => {
    opts.context.queryClient.removeQueries({ queryKey: [getDetailDirectorKey] })

    return await opts.context.queryClient.ensureQueryData(getDetailDirectorOptions({ params: opts.params }))
  },
  pendingComponent: LoadingSpinner,
  gcTime: 0,
})

// tag
const tagRoute = createRoute({
  getParentRoute: () => rootMainRoute,
  path: '/tagging',
  staticData: {
    title: 'Tagging',
  },
  component: lazyRouteComponent(() => import('@/views/tag/list')),
})

const tagEditRoute = createRoute({
  getParentRoute: () => rootMainRoute,
  path: '/tagging/$xid/edit',
  staticData: {
    title: 'Edit Tagging',
  },
  component: lazyRouteComponent(() => import('@/views/tag/edit')),
  loader: async (opts) => {
    opts.context.queryClient.removeQueries({ queryKey: [getDetailTagKey] })

    return await opts.context.queryClient.ensureQueryData(getDetailTagOptions({ params: opts.params }))
  },
  pendingComponent: LoadingSpinner,
  gcTime: 0,
})

// Subject
const subjectRoute = createRoute({
  getParentRoute: () => rootMainRoute,
  path: '/subject',
  staticData: {
    title: 'Subjek',
  },
  component: lazyRouteComponent(() => import('@/views/subject/list')),
})

const subjectCreateRoute = createRoute({
  getParentRoute: () => rootMainRoute,
  path: '/subject/create',
  staticData: {
    title: 'Tambah Subjek',
  },
  component: lazyRouteComponent(() => import('@/views/subject/create')),
})

const subjectEditRoute = createRoute({
  getParentRoute: () => rootMainRoute,
  path: '/subject/$xid/edit',
  staticData: {
    title: 'Edit Subjek',
  },
  component: lazyRouteComponent(() => import('@/views/subject/edit')),
  loader: async (opts) => {
    opts.context.queryClient.removeQueries({ queryKey: [getDetailSubjectKey] })

    return await opts.context.queryClient.ensureQueryData(getDetailSubjectOptions({ params: opts.params }))
  },
  pendingComponent: LoadingSpinner,
  gcTime: 0,
})

// Speaker
const speakerRoute = createRoute({
  getParentRoute: () => rootMainRoute,
  path: '/speaker',
  staticData: {
    title: 'Subjek',
  },
  component: lazyRouteComponent(() => import('@/views/speaker/list')),
})

const speakerCreateRoute = createRoute({
  getParentRoute: () => rootMainRoute,
  path: '/speaker/create',
  staticData: {
    title: 'Tambah Subjek',
  },
  component: lazyRouteComponent(() => import('@/views/speaker/create')),
})

const speakerEditRoute = createRoute({
  getParentRoute: () => rootMainRoute,
  path: '/speaker/$xid/edit',
  staticData: {
    title: 'Edit Subjek',
  },
  component: lazyRouteComponent(() => import('@/views/speaker/edit')),
  loader: async (opts) => {
    opts.context.queryClient.removeQueries({ queryKey: [getDetailSpeakerKey] })

    return await opts.context.queryClient.ensureQueryData(getDetailSpeakerOptions({ params: opts.params }))
  },
  pendingComponent: LoadingSpinner,
  gcTime: 0,
})

// Slide Show
const slideShowRoute = createRoute({
  getParentRoute: () => rootMainRoute,
  path: '/slide-show',
  staticData: {
    title: 'Slide Show',
  },
  component: lazyRouteComponent(() => import('@/views/slide-show/list')),
})

const slideShowEditRoute = createRoute({
  getParentRoute: () => rootMainRoute,
  path: '/slide-show/$xid/edit',
  staticData: {
    title: 'Edit Slide Show',
  },
  component: lazyRouteComponent(() => import('@/views/slide-show/edit')),
  loader: async (opts) => {
    opts.context.queryClient.removeQueries({ queryKey: [getDetailSlideShowsKey] })

    return await opts.context.queryClient.ensureQueryData(getDetailSlideShowsOptions({ params: opts.params }))
  },
  pendingComponent: LoadingSpinner,
  gcTime: 0,
})

// Users CMS
const usersCmsRoute = createRoute({
  getParentRoute: () => rootMainRoute,
  path: '/users-cms',
  staticData: {
    title: 'Pengguna CMS',
  },
  component: lazyRouteComponent(() => import('@/views/users-cms/list')),
})

const usersCmsCreateRoute = createRoute({
  getParentRoute: () => rootMainRoute,
  path: '/users-cms/create',
  staticData: {
    title: 'Tambah Pengguna CMS',
  },
  component: lazyRouteComponent(() => import('@/views/users-cms/create')),
})

const usersCmsEditRoute = createRoute({
  getParentRoute: () => rootMainRoute,
  path: '/users-cms/$xid/edit',
  staticData: {
    title: 'Edit Pengguna CMS',
  },
  component: lazyRouteComponent(() => import('@/views/users-cms/edit')),
  loader: async (opts) => {
    opts.context.queryClient.removeQueries({ queryKey: [getDetailUsersCmsKey] })

    return await opts.context.queryClient.ensureQueryData(getDetailUsersCmsOptions({ params: opts.params }))
  },
  pendingComponent: LoadingSpinner,
  gcTime: 0,
})

// User Management
const roleManagementRoute = createRoute({
  getParentRoute: () => rootMainRoute,
  path: '/role-management',
  staticData: {
    title: 'Manajemen Role',
  },
  component: lazyRouteComponent(() => import('@/views/role-management/list')),
})

const roleManagementCreateRoute = createRoute({
  getParentRoute: () => rootMainRoute,
  path: '/role-management/create',
  staticData: {
    title: 'Tambah Role',
  },
  component: lazyRouteComponent(() => import('@/views/role-management/create')),
})

const roleManagementEditRoute = createRoute({
  getParentRoute: () => rootMainRoute,
  path: '/role-management/$xid/edit',
  staticData: {
    title: 'Edit Role',
  },
  component: lazyRouteComponent(() => import('@/views/role-management/edit')),
  loader: async (opts) => {
    opts.context.queryClient.removeQueries({ queryKey: [getDetailSubRolesKey] })

    return await opts.context.queryClient.ensureQueryData(getDetailSubRolesOptions({ params: opts.params }))
  },
  pendingComponent: LoadingSpinner,
  gcTime: 0,
})

// Application Users
const usersAppRoute = createRoute({
  getParentRoute: () => rootMainRoute,
  path: '/users-app',
  staticData: {
    title: 'Pengguna Aplikasi',
  },
  component: lazyRouteComponent(() => import('@/views/users-app/list')),
})

// Profile
const profileRoute = createRoute({
  getParentRoute: () => rootMainRoute,
  path: '/profile',
  staticData: {
    title: 'Profile',
  },
  component: lazyRouteComponent(() => import('@/views/profile')),
  loader: async (opts) => {
    opts.context.queryClient.removeQueries({ queryKey: [profileKey] })

    return await opts.context.queryClient.ensureQueryData(profileOptions())
  },
  pendingComponent: LoadingSpinner,
  gcTime: 0,
})

// Dynamic Splash Screen
const dynamicSplashScreenRoute = createRoute({
  getParentRoute: () => rootMainRoute,
  path: '/dynamic-splash-screen',
  staticData: {
    title: 'Dynamic Splash Screen',
  },
  component: lazyRouteComponent(() => import('@/views/dynamic-splash-screen/list')),
})

const dynamicSplashScreenCreateRoute = createRoute({
  getParentRoute: () => rootMainRoute,
  path: '/dynamic-splash-screen/create',
  staticData: {
    title: 'Tambah Dynamic Splash Screen',
  },
  component: lazyRouteComponent(() => import('@/views/dynamic-splash-screen/create')),
})

const dynamicSplashScreenEditRoute = createRoute({
  getParentRoute: () => rootMainRoute,
  path: '/dynamic-splash-screen/$xid/edit',
  staticData: {
    title: 'Edit Dynamic Splash Screen',
  },
  component: lazyRouteComponent(() => import('@/views/dynamic-splash-screen/edit')),
  loader: async (opts) => {
    opts.context.queryClient.removeQueries({ queryKey: [getDetailDynamicSplashScreenKey] })

    return await opts.context.queryClient.ensureQueryData(getDetailDynamicSplashScreenOptions({ params: opts.params }))
  },
  gcTime: 0,
})

// Splash Screen TV
const staticSplashScreenRoute = createRoute({
  getParentRoute: () => rootMainRoute,
  path: '/static-splash-screen',
  staticData: {
    title: 'Splash Screen TV',
  },
  component: lazyRouteComponent(() => import('@/views/static-splash-screen')),
  loader: async (opts) => {
    opts.context.queryClient.removeQueries({ queryKey: [getDetailStaticSplashScreenKey] })

    return await opts.context.queryClient.ensureQueryData(getDetailStaticSplashScreenOptions())
  },
  gcTime: 0,
})

// Program Coverage

const programCoverageRoute = createRoute({
  getParentRoute: () => rootMainRoute,
  path: '/program-coverage',
  staticData: {
    title: 'Manajemen Program Coverage',
  },
  component: lazyRouteComponent(() => import('@/views/program-coverage/list')),
})

const programCoverageCreateRoute = createRoute({
  getParentRoute: () => rootMainRoute,
  path: '/program-coverage/create',
  staticData: {
    title: 'Tambah Program Coverage',
  },
  component: lazyRouteComponent(() => import('@/views/program-coverage/create')),
})

const programCoverageEditRoute = createRoute({
  getParentRoute: () => rootMainRoute,
  path: '/program-coverage/$xid/edit',
  staticData: {
    title: 'Edit Program Coverage',
  },
  component: lazyRouteComponent(() => import('@/views/program-coverage/edit')),
  loader: async (opts) => {
    opts.context.queryClient.removeQueries({ queryKey: [getDetailProgramCoverageKey] })

    return await opts.context.queryClient.ensureQueryData(getDetailProgramCoverageOptions({ params: opts.params }))
  },
  pendingComponent: LoadingSpinner,
  gcTime: 0,
})

// Highlights
const highlightsRoute = createRoute({
  getParentRoute: () => rootMainRoute,
  path: '/highlights',
  staticData: {
    title: 'Highlights',
  },
  component: lazyRouteComponent(() => import('@/views/highlights')),
  loader: async (opts) => {
    opts.context.queryClient.removeQueries({ queryKey: [getDetailHighlightsKey] })

    return await opts.context.queryClient.ensureQueryData(getDetailHighlightsOptions())
  },
  gcTime: 0,
})

// End: Authenticated Layout

const routeTree = rootRoute.addChildren([
  // blank layout
  rootBlankRoute.addChildren([
    // main
    mainRoute,

    // health
    healthRoute,
  ]),

  // guest layout
  rootGuestRoute.addChildren([
    // auth
    loginRoute,
    forgotPasswordRoute,
    resetPasswordRoute,
    setupPasswordRoute,
    setupPasswordSuccessRoute,
  ]),

  // authenticated layout
  rootMainRoute.addChildren([
    // dashboard
    dashboardRoute,

    // banner
    bannerRoute,
    bannerCreateRoute,
    bannerEditRoute,

    // notification broadcast
    notificationBroadcastRoute,
    notificationBroadcastCreateRoute,
    notificationBroadcastEditRoute,
    notificationBroadcastDetailRoute,

    // programs need review
    programNeedReviewRoute,
    programNeedReviewDetailRoute.addChildren([
      programNeedReviewDetailGeneralDataRoute,
      programNeedReviewDetailEpisodesRoute,
      programNeedReviewDetailInfoRoute,
      programNeedReviewDetailCoverRoute,
      programNeedReviewDetailPublicationRoute,
    ]),

    // programs-submission
    programSubmissionRoute,
    programSubmissionCreateTypeRoute,
    programSubmissionCreateGeneralRoute,
    programSubmissionEditKumulateRoute,
    programSubmissionCreatedRoute.addChildren([
      programSubmissionCreateDraftGeneralRoute,
      programSubmissionCreateDraftVideoRoute,
      programSubmissionCreateDraftOtherRoute,
      programSubmissionCreateDraftCoverRoute,
      programSubmissionCreateDraftPublicationRoute,
    ]),

    programSubmissionDetailRoute.addChildren([
      programSubmissionDetailDraftGeneralRoute,
      programSubmissionDetailDraftVideoRoute,
      programSubmissionDetailDraftOtherRoute,
      programSubmissionDetailDraftCoverRoute,
      programSubmissionDetailDraftPublicationRoute,
    ]),

    // dynamic content
    dynamicContentRoute,
    dynamicContentCreateRoute,
    dynamicContentEditRoute,

    // program-actives
    programActiveRoute,
    programActiveDetailRoute.addChildren([
      programActiveDetailGeneralDataRoute,
      programActiveDetailEpisodesRoute,
      programActiveDetailInfoRoute,
      programActiveDetailCoverRoute,
    ]),

    programActiveEditRoute.addChildren([
      programActiveEditGeneralDataRoute,
      programActiveEditCoverRoute,
      programActiveEditOtherInformationRoute,
      programActiveEditEpisodeRoute,
      programActiveEditPublicationRoute,
    ]),

    programActiveEditKumulateRoute,

    // program-statistic
    programStatisticRoute,

    // live channel
    liveChannelRoute,
    liveChannelCreateRoute,
    liveChannelEditRoute.addChildren([
      liveChannelEditGeneralRoute,
      liveChannelEditCoverAndVideoRoute,
      liveChannelEditScheduleRoute,
    ]),

    // category
    categoryRoute,
    categoryCreateRoute,
    categoryEditRoute,

    // Sub Category
    subCategoryCreateRoute,
    subCategoryEditRoute,

    // cast
    castRoute,
    castCreateRoute,
    castEditRoute,

    // figure
    figureRoute,
    figureCreateRoute,
    figureEditRoute,

    // director
    directorRoute,
    directorCreateRoute,
    directorEditRoute,

    // age rating
    ageRatingRoute,
    ageRatingCreateRoute,
    ageRatingEditRoute,

    // tag
    tagRoute,
    tagEditRoute,

    // Subject
    subjectRoute,
    subjectCreateRoute,
    subjectEditRoute,

    // Speaker
    speakerRoute,
    speakerCreateRoute,
    speakerEditRoute,

    // Users CMS
    usersCmsRoute,
    usersCmsCreateRoute,
    usersCmsEditRoute,

    // User Management
    roleManagementRoute,
    roleManagementCreateRoute,
    roleManagementEditRoute,

    // other information
    termConditionRoute,
    privacyPolicyRoute,
    feedbackUrlRoute,

    // application users
    usersAppRoute,

    // slide show
    slideShowRoute,
    slideShowEditRoute,

    // profile
    profileRoute,

    // Dynamic Splash Screen
    dynamicSplashScreenRoute,
    dynamicSplashScreenCreateRoute,
    dynamicSplashScreenEditRoute,

    // Splash Screen TV
    staticSplashScreenRoute,

    // Program Coverage
    programCoverageRoute,
    programCoverageCreateRoute,
    programCoverageEditRoute,

    highlightsRoute,
  ]),
])

export default createRouter({
  routeTree,
})
