import { CSSObject } from '@emotion/react'
import styled from '@emotion/styled'
import { configStyledOptions } from '@nbsdev/naini-react'

export type NotificationContentProps = {}

export const NotificationContent = styled(
  'div',
  configStyledOptions<NotificationContentProps>([])
)<NotificationContentProps>(
  (): CSSObject => ({
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'flex-start',
    flex: '1 0 0',
    '>:not([hidden])~:not([hidden])': {
      marginTop: '6px',
    },
  })
)

NotificationContent.displayName = 'NotificationContent'
