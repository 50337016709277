import { queryOptions, useQuery, useSuspenseQuery } from '@tanstack/react-query'

import { getDetailLiveStreams as queryFn, getDetailLiveStreamsKey, GetDetailLiveStreamsRequest } from '@/services'

export const useGetDetailLiveStreams = (request: GetDetailLiveStreamsRequest, enabled = true) => {
  const {
    refetch: getDetailLiveStreams,
    data: getDetailLiveStreamsData,
    error: getDetailLiveStreamsError,
    isFetching: getDetailLiveStreamsIsFetching,
    isPending: getDetailLiveStreamsIsPending,
    isSuccess: getDetailLiveStreamsIsSuccess,
    isError: getDetailLiveStreamsIsError,
  } = useQuery({
    enabled,
    queryFn: () => queryFn(request),
    queryKey: [getDetailLiveStreamsKey, request],
    refetchOnMount: false,
    refetchOnReconnect: false,
    refetchOnWindowFocus: false,
    refetchInterval: false,
    refetchIntervalInBackground: false,
    retryOnMount: false,
    gcTime: 0,
  })

  return {
    getDetailLiveStreams,
    getDetailLiveStreamsData,
    getDetailLiveStreamsError,
    getDetailLiveStreamsIsFetching,
    getDetailLiveStreamsIsPending,
    getDetailLiveStreamsIsSuccess,
    getDetailLiveStreamsIsError,
  }
}

export const useSuspenseGetDetailLiveStreams = (request: GetDetailLiveStreamsRequest) => {
  const { data: getDetailLiveStreamsData } = useSuspenseQuery(getDetailLiveStreamsOptions(request))

  return { getDetailLiveStreamsData }
}

export const getDetailLiveStreamsOptions = (request: GetDetailLiveStreamsRequest) =>
  queryOptions({
    queryFn: () => queryFn(request),
    queryKey: [getDetailLiveStreamsKey, request],
  })
