import { FIGURE_DETAIL } from '@/configs/endpoint'
import { BaseResponse, TimestampAndVersion } from '@/types/common'
import { api } from '@/utils/api'

export type GetDetailFigureRequestParams = {
  xid: string
}

export type GetDetailFigureRequest = {
  params: GetDetailFigureRequestParams
}

export type GetDetailFigureResponse = BaseResponse<
  {
    xid: string
    name: string
  } & TimestampAndVersion
>

export const getDetailFigure = async (request: GetDetailFigureRequest) => {
  const response = await api<GetDetailFigureResponse, GetDetailFigureRequestParams>({
    ...request,
    url: FIGURE_DETAIL,
    method: 'GET',
  })

  return response.data
}

export const getDetailFigureKey = 'GET_DETAIL_FIGURE'
