import { BANNER_DETAIL } from '@/configs/endpoint'
import { BaseResponse, CommonData, FileData, ModifiedBy, TimestampAndVersion } from '@/types/common'
import { api } from '@/utils/api'

export type GetDetailBannerRequestParams = {
  xid: string
}

export type GetDetailBannerRequest = {
  params: GetDetailBannerRequestParams
}

export type GetDetailBannerResponse = BaseResponse<
  {
    xid: string
    siteSnapshot: {
      url: string
      description: string
      title: string
    } | null
    program: CommonData | null
    bannerTypeId: number
    cover: FileData
    sequence: string
    publishedDate: string
    modifiedBy: ModifiedBy
    statusId: number
  } & TimestampAndVersion
>

export const getDetailBanner = async (request: GetDetailBannerRequest) => {
  const response = await api<GetDetailBannerResponse, GetDetailBannerRequestParams>({
    ...request,
    url: BANNER_DETAIL,
    method: 'GET',
  })

  return response.data
}

export const getDetailBannerKey = 'GET_DETAIL_BANNER'
