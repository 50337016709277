import { queryOptions, useQuery, useSuspenseQuery } from '@tanstack/react-query'

import {
  getListLiveStreamPrograms as queryFn,
  getListLiveStreamProgramsKey,
  GetListLiveStreamProgramsRequest,
} from '@/services'

export const useGetListLiveStreamPrograms = (request: GetListLiveStreamProgramsRequest, enabled = true) => {
  const {
    refetch: getListLiveStreamPrograms,
    data: getListLiveStreamProgramsData,
    error: getListLiveStreamProgramsError,
    isFetching: getListLiveStreamProgramsIsFetching,
    isPending: getListLiveStreamProgramsIsPending,
    isSuccess: getListLiveStreamProgramsIsSuccess,
    isError: getListLiveStreamProgramsIsError,
  } = useQuery({
    enabled,
    queryFn: () => queryFn(request),
    queryKey: [getListLiveStreamProgramsKey, request],
    refetchOnMount: false,
    refetchOnReconnect: false,
    refetchOnWindowFocus: false,
    refetchInterval: false,
    refetchIntervalInBackground: false,
    retryOnMount: false,
    gcTime: 0,
  })

  return {
    getListLiveStreamPrograms,
    getListLiveStreamProgramsData,
    getListLiveStreamProgramsError,
    getListLiveStreamProgramsIsFetching,
    getListLiveStreamProgramsIsPending,
    getListLiveStreamProgramsIsSuccess,
    getListLiveStreamProgramsIsError,
  }
}

export const useSuspenseGetListLiveStreamPrograms = (request: GetListLiveStreamProgramsRequest) => {
  const { data: getListLiveStreamProgramsData } = useSuspenseQuery(getListLiveStreamProgramsOptions(request))

  return { getListLiveStreamProgramsData }
}

export const getListLiveStreamProgramsOptions = (request: GetListLiveStreamProgramsRequest) =>
  queryOptions({
    queryFn: () => queryFn(request),
    queryKey: [getListLiveStreamProgramsKey, request],
  })
