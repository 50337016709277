import { SPEAKER_DETAIL } from '@/configs/endpoint'
import { BaseResponse, TimestampAndVersion } from '@/types/common'
import { api } from '@/utils/api'

export type GetDetailSpeakerRequestParams = {
  xid: string
}

export type GetDetailSpeakerRequest = {
  params: GetDetailSpeakerRequestParams
}

export type GetDetailSpeakerResponse = BaseResponse<
  {
    xid: string
    name: string
  } & TimestampAndVersion
>

export const getDetailSpeaker = async (request: GetDetailSpeakerRequest) => {
  const response = await api<GetDetailSpeakerResponse, GetDetailSpeakerRequestParams>({
    ...request,
    url: SPEAKER_DETAIL,
    method: 'GET',
  })

  return response.data
}

export const getDetailSpeakerKey = 'GET_DETAIL_SPEAKER'
