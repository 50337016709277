import { queryOptions, useQuery, useSuspenseQuery } from '@tanstack/react-query'

import { getDetailUsersCms as queryFn, getDetailUsersCmsKey, GetDetailUsersCmsRequest } from '@/services'

export const useGetDetailUsersCms = (request: GetDetailUsersCmsRequest, enabled = true) => {
  const {
    refetch: getDetailUsersCms,
    data: getDetailUsersCmsData,
    error: getDetailUsersCmsError,
    isFetching: getDetailUsersCmsIsFetching,
    isPending: getDetailUsersCmsIsPending,
    isSuccess: getDetailUsersCmsIsSuccess,
    isError: getDetailUsersCmsIsError,
  } = useQuery({
    enabled,
    queryFn: () => queryFn(request),
    queryKey: [getDetailUsersCmsKey, request],
    refetchOnMount: false,
    refetchOnReconnect: false,
    refetchOnWindowFocus: false,
    refetchInterval: false,
    refetchIntervalInBackground: false,
    retryOnMount: false,
    gcTime: 0,
  })

  return {
    getDetailUsersCms,
    getDetailUsersCmsData,
    getDetailUsersCmsError,
    getDetailUsersCmsIsFetching,
    getDetailUsersCmsIsPending,
    getDetailUsersCmsIsSuccess,
    getDetailUsersCmsIsError,
  }
}

export const useSuspenseGetDetailUsersCms = (request: GetDetailUsersCmsRequest) => {
  const { data: getDetailUsersCmsData } = useSuspenseQuery(getDetailUsersCmsOptions(request))

  return { getDetailUsersCmsData }
}

export const getDetailUsersCmsOptions = (request: GetDetailUsersCmsRequest) =>
  queryOptions({
    queryFn: () => queryFn(request),
    queryKey: [getDetailUsersCmsKey, request],
  })
