import styled from '@emotion/styled'
import {
  CardDescription,
  CardHeader as NainiCardHeader,
  CardHeaderLeft,
  CardHeaderRight,
  CardTitle,
  Typography,
} from '@nbsdev/naini-react'
import { FC } from 'react'

import { useAbilityCan } from '@/hooks/permission/use-permission'

import { ButtonLink } from '../Button'
import { Icon } from '../Icon'

const Styled = styled(NainiCardHeader)({
  alignItems: 'start',

  '@media (min-width: 768px)': {
    alignItems: 'center ',
  },
})

export type CardHeaderProps = {
  title: string
  subtitle?: string
  addText?: string
  addUrl?: string
}

export const CardHeader: FC<CardHeaderProps> = ({ title, subtitle, addText, addUrl }) => {
  const { canPermissionCreate } = useAbilityCan()

  return (
    <Styled>
      <CardHeaderLeft>
        <CardTitle>{title}</CardTitle>

        {subtitle && <CardDescription>{subtitle}</CardDescription>}
      </CardHeaderLeft>

      {addText && addUrl && canPermissionCreate && (
        <CardHeaderRight>
          <ButtonLink
            buttonSize='lg'
            to={addUrl}
            css={{
              '> span': {
                display: 'none',
              },

              '@media (min-width: 768px)': {
                '> span': {
                  display: 'inline-block',
                },
              },
            }}
          >
            <Icon
              className='ri-add-circle-fill'
              width={20}
              height={20}
            />

            <Typography as='span'>{addText}</Typography>
          </ButtonLink>
        </CardHeaderRight>
      )}
    </Styled>
  )
}
