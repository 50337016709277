import { queryOptions, useQuery, useSuspenseQuery } from '@tanstack/react-query'

import { getDetailSubRoles as queryFn, getDetailSubRolesKey, GetDetailSubRolesRequest } from '@/services'

export const useGetDetailSubRoles = (request: GetDetailSubRolesRequest, enabled = true) => {
  const {
    refetch: getDetailSubRoles,
    data: getDetailSubRolesData,
    error: getDetailSubRolesError,
    isFetching: getDetailSubRolesIsFetching,
    isPending: getDetailSubRolesIsPending,
    isSuccess: getDetailSubRolesIsSuccess,
    isError: getDetailSubRolesIsError,
  } = useQuery({
    enabled,
    queryFn: () => queryFn(request),
    queryKey: [getDetailSubRolesKey, request],
    refetchOnMount: false,
    refetchOnReconnect: false,
    refetchOnWindowFocus: false,
    refetchInterval: false,
    refetchIntervalInBackground: false,
    retryOnMount: false,
    gcTime: 0,
  })

  return {
    getDetailSubRoles,
    getDetailSubRolesData,
    getDetailSubRolesError,
    getDetailSubRolesIsFetching,
    getDetailSubRolesIsPending,
    getDetailSubRolesIsSuccess,
    getDetailSubRolesIsError,
  }
}

export const useSuspenseGetDetailSubRoles = (request: GetDetailSubRolesRequest) => {
  const { data: getDetailSubRolesData } = useSuspenseQuery(getDetailSubRolesOptions(request))

  return { getDetailSubRolesData }
}

export const getDetailSubRolesOptions = (request: GetDetailSubRolesRequest) =>
  queryOptions({
    queryFn: () => queryFn(request),
    queryKey: [getDetailSubRolesKey, request],
  })
