import { FC } from 'react'

export const LogoInvalidLink: FC = () => {
  return (
    <svg
      width='214'
      height='34'
      viewBox='0 0 214 34'
      fill='none'
      xmlns='http://www.w3.org/2000/svg'
    >
      <g
        id='Frame'
        clipPath='url(#clip0_7973_88004)'
      >
        <path
          id='Vector'
          d='M186.091 31.3333V31.4206C186.091 32.5555 187.051 33.5157 188.186 33.5157C189.408 33.5157 190.281 32.5555 190.281 31.4206V31.3333C190.281 30.1985 189.321 29.2383 188.186 29.2383C187.051 29.2383 186.091 30.1112 186.091 31.3333Z'
          fill='#EC1C24'
        />
        <path
          id='XMLID_24_'
          d='M192.646 5.32227V33.4799H196.752V5.32227H192.646Z'
          fill='#0082AD'
        />
        <path
          id='XMLID_23_'
          d='M200.083 5.11328H189.273V9.15675H200.083V5.11328Z'
          fill='#0082AD'
        />
        <path
          id='Vector_2'
          d='M192.646 9.17773H196.752V15.0439C196.752 15.0439 194.678 10.6233 192.646 9.17773Z'
          fill='#0082AD'
        />
        <path
          id='Vector_3'
          d='M209.595 5.13477V25.7921L208.191 28.3899L206.787 25.7921V5.13477H202.576V25.7921L206.787 33.4809H209.595L213.827 25.7921V5.13477H209.595Z'
          fill='#0082AD'
        />
        <path
          id='XMLID_22_'
          d='M170.249 7.29268C169.642 6.03564 168.112 5.13477 165.2 5.13477C161.702 5.13477 160.445 6.3918 160.088 8.15165C159.921 8.94778 159.942 9.93246 159.942 10.1001V33.4809H164.048V9.13633C164.048 8.44496 164.593 7.90025 165.305 7.90025C166.017 7.90025 166.562 8.44496 166.562 9.13633V33.4809H170.668V9.99531C170.648 9.07348 170.648 8.15165 170.249 7.29268Z'
          fill='#0082AD'
        />
        <path
          id='XMLID_21_'
          d='M140.081 5.13477V33.4809H144.187V5.13477H140.081Z'
          fill='#0082AD'
        />
        <path
          id='XMLID_20_'
          d='M54.0366 5.13477V33.4809H58.1429V5.13477H54.0366Z'
          fill='#0082AD'
        />
        <path
          id='XMLID_19_'
          d='M70.9438 7.29268C70.3362 6.03564 68.8068 5.13477 65.8947 5.13477C62.3959 5.13477 61.1389 6.3918 60.7827 8.15165C60.6151 8.94778 60.6361 9.93246 60.6361 10.1001V33.4809H64.7424V9.13633C64.7424 8.44496 65.2871 7.90025 65.9994 7.90025C66.7118 7.90025 67.2565 8.44496 67.2565 9.13633V33.4809H71.3628V9.99531C71.3628 9.07348 71.3628 8.15165 70.9438 7.29268Z'
          fill='#0082AD'
        />
        <path
          id='XMLID_18_'
          d='M84.1845 6.76945C83.4721 5.65906 82.3827 5.36576 81.9218 5.3029C81.2095 5.15625 80.141 5.15625 80.0153 5.15625H73.793V33.4815H80.1829C81.5237 33.4815 83.0322 33.3977 83.954 32.2873C84.6873 31.4283 84.7292 30.276 84.7292 29.2076V9.36732C84.7292 7.96363 84.5616 7.35606 84.1845 6.76945ZM80.581 9.30447V29.3542C80.581 30.0456 80.0362 30.6112 79.3239 30.6112H77.7945V8.06839H79.3239C80.0362 8.06839 80.581 8.6131 80.581 9.30447Z'
          fill='#0082AD'
        />
        <path
          id='XMLID_17_'
          d='M137.588 22.1467H137.609C137.483 20.9525 137.337 20.3868 136.75 19.884C136.352 19.5278 135.891 19.2345 135.472 18.9412C134.257 18.2498 132.811 17.4328 131.47 16.6995C131.24 16.5738 131.072 16.4481 130.968 16.3433V9.11538C130.968 8.42401 131.512 7.8793 132.225 7.8793C132.937 7.8793 133.482 8.42401 133.482 9.11538V16.3852H137.588V9.95341C137.588 9.07348 137.588 8.1307 137.19 7.29268C136.582 6.03564 135.053 5.13477 132.141 5.13477C128.642 5.13477 127.385 6.3918 127.029 8.15165C126.861 8.94778 126.882 9.93246 126.882 10.1001V16.3224C127.008 17.5166 127.155 18.0822 127.741 18.5851C128.139 18.9412 128.6 19.2345 129.019 19.5278C130.15 20.1145 131.868 21.0572 133 21.7276C133.23 21.8534 133.419 22 133.524 22.1048H133.503V29.5003C133.503 30.1917 132.958 30.7364 132.246 30.7364C131.533 30.7364 130.989 30.1917 130.989 29.5003V22.2305H126.882V28.6623C126.882 29.5422 126.882 30.485 127.28 31.323C127.888 32.5801 129.417 33.4809 132.329 33.4809C135.828 33.4809 137.085 32.2239 137.441 30.4641C137.609 29.6679 137.588 28.6833 137.588 28.5156V22.1467Z'
          fill='#0082AD'
        />
        <path
          id='XMLID_16_'
          d='M103.669 19.8421C103.271 19.5069 102.81 19.1926 102.391 18.8993C101.176 18.2079 99.7299 17.3909 98.3891 16.6576C98.1586 16.5319 97.991 16.4062 97.8863 16.3224V9.93245C97.8863 9.05253 97.8863 8.10975 97.4882 7.27173C96.8806 6.01469 95.3513 5.13477 92.4391 5.13477C88.9404 5.13477 87.6833 6.3918 87.3272 8.1307C87.1596 8.92683 87.1805 9.9115 87.1805 10.0791V28.6833C87.1805 29.5632 87.1805 30.506 87.5786 31.344C88.1862 32.601 89.7155 33.4809 92.6277 33.4809C96.1264 33.4809 97.3835 32.2239 97.7396 30.485C97.9072 29.6889 97.8863 28.7042 97.8863 28.5366V27.8871C97.991 27.7824 98.1796 27.6357 98.41 27.51C99.5414 26.8396 101.259 25.9178 102.391 25.3102C102.81 25.0169 103.25 24.7236 103.669 24.3674C104.255 23.8437 104.402 23.257 104.528 22.1048C104.423 20.9525 104.234 20.3868 103.669 19.8421ZM99.9185 22.44C98.5776 23.1732 97.1321 24.0113 95.9169 24.6817C95.4979 24.975 95.0579 25.2683 94.6389 25.6245C94.0523 26.1482 93.9057 26.7348 93.78 27.8871V29.4584C93.78 30.1498 93.2352 30.6945 92.5229 30.6945C91.8106 30.6945 91.2659 30.1498 91.2659 29.4584V9.11538C91.2659 8.42401 91.8106 7.8793 92.5229 7.8793C93.2352 7.8793 93.78 8.42401 93.78 9.11538V16.3014C93.9057 17.4747 94.0523 18.0403 94.6389 18.5641C95.037 18.8993 95.4979 19.2136 95.9169 19.5069C97.0482 20.0935 98.7662 21.0363 99.8975 21.7067C100.128 21.8324 100.317 21.9791 100.421 22.0838C100.317 22.2305 100.128 22.3352 99.9185 22.44Z'
          fill='#0082AD'
        />
        <path
          id='XMLID_15_'
          d='M123.991 7.27173C123.383 6.01469 121.854 5.13477 118.941 5.13477C115.443 5.13477 114.186 6.3918 113.829 8.1307C113.662 8.92683 113.683 9.9115 113.683 10.0791V10.5191C113.578 10.6238 113.41 10.7495 113.18 10.8543C111.839 11.5876 110.394 12.4256 109.178 13.096C108.759 13.3893 108.319 13.6826 107.9 14.0388C107.314 14.5625 107.167 15.1492 107.042 16.3014C107.167 17.4747 107.314 18.0403 107.9 18.5641C108.299 18.8993 108.759 19.2136 109.178 19.5069C110.31 20.0935 112.028 21.0363 113.159 21.7067C113.39 21.8324 113.557 21.9791 113.662 22.0838L113.683 28.6413C113.683 29.5213 113.683 30.4641 114.081 31.3021C114.688 32.5591 116.218 33.439 119.13 33.439C122.629 33.439 123.886 32.182 124.242 30.4431C124.41 29.647 124.389 28.6623 124.389 28.4947V22.2724H120.282V29.4375C120.282 30.1288 119.738 30.6736 119.025 30.6736C118.313 30.6736 117.768 30.1288 117.768 29.4375V22.0419C117.873 21.9372 118.041 21.7905 118.271 21.6648C119.402 20.9944 121.12 20.0725 122.252 19.465C122.671 19.1717 123.111 18.8784 123.53 18.5222C124.116 17.9984 124.263 17.4328 124.389 16.2805V9.89055C124.389 9.05253 124.389 8.1307 123.991 7.27173ZM120.282 16.3224C120.178 16.4271 120.01 16.5528 119.779 16.6576C118.439 17.3909 116.993 18.2289 115.778 18.8993C115.757 18.9203 115.736 18.9203 115.694 18.9412C115.673 18.9203 115.652 18.9203 115.61 18.8993C114.395 18.2079 112.95 17.3909 111.609 16.6576C111.378 16.5319 111.211 16.4062 111.106 16.3224C111.211 16.2176 111.399 16.071 111.63 15.9453C112.761 15.2749 114.479 14.353 115.61 13.7455C116.029 13.4522 116.469 13.1588 116.888 12.8027C117.475 12.2789 117.622 11.6923 117.747 10.54V9.13633C117.747 8.44496 118.292 7.90025 119.004 7.90025C119.717 7.90025 120.261 8.44496 120.261 9.13633V16.3224H120.282Z'
          fill='#0082AD'
        />
        <path
          id='XMLID_57_1_1_'
          d='M116.951 14.0588C116.553 13.7236 116.092 13.4094 115.673 13.1161C114.458 12.4247 113.013 11.6076 111.672 10.8743C111.441 10.7486 111.274 10.6229 111.169 10.5391V9.97347C111.169 9.09355 111.169 8.15077 110.771 7.31274C110.163 6.05571 108.634 5.17578 105.722 5.17578C102.223 5.17578 100.966 6.43282 100.61 8.17172C100.442 8.96784 100.463 9.95252 100.463 10.1201V16.3215C100.358 16.4263 100.191 16.552 99.9603 16.6567C98.6195 17.39 97.1739 18.228 95.9587 18.8984C95.5397 19.1917 95.0998 19.485 94.6808 19.8412C94.0941 20.365 93.9475 20.9516 93.8218 22.1248C93.9475 23.2981 94.0941 23.8637 94.6808 24.3875C95.0788 24.7227 95.5397 25.037 95.9587 25.3303C97.0901 25.9169 98.808 26.8597 99.9393 27.5301C100.17 27.6558 100.358 27.8024 100.463 27.9072V33.2287H104.59L104.569 27.9072C104.444 26.734 104.297 26.1683 103.71 25.6445C103.312 25.3093 102.851 24.9951 102.432 24.7017C101.217 24.0104 99.7717 23.1933 98.4309 22.46C98.2004 22.3343 98.0328 22.2086 97.9281 22.1248C98.0328 22.0201 98.2214 21.8734 98.4519 21.7477C99.5832 21.0773 101.301 20.1555 102.432 19.5479C102.851 19.2546 103.291 18.9613 103.71 18.6051C104.297 18.0814 104.444 17.4947 104.569 16.3425V9.1564C104.569 8.46503 105.114 7.92031 105.826 7.92031C106.539 7.92031 107.083 8.46503 107.083 9.1564V10.5601C107.209 11.7333 107.356 12.299 107.942 12.8228C108.341 13.158 108.801 13.4722 109.22 13.7655C110.352 14.3521 112.07 15.2949 113.201 15.9653C113.432 16.091 113.62 16.2377 113.725 16.3425C113.62 16.4472 113.452 16.5729 113.222 16.6777C111.881 17.4109 110.436 18.249 109.22 18.9194C108.801 19.2127 108.361 19.506 107.942 19.8622C107.356 20.3859 107.209 20.9725 107.083 22.1458V33.2706H111.19V22.1458C111.295 22.041 111.483 21.8944 111.714 21.7687C112.845 21.0982 114.563 20.1764 115.694 19.5689C116.113 19.2755 116.553 18.9822 116.972 18.6261C117.559 18.1023 117.705 17.5157 117.831 16.3634C117.705 15.1273 117.517 14.5617 116.951 14.0588Z'
          fill='#0082AD'
        />
        <path
          id='XMLID_14_'
          d='M120.282 16.3223C120.178 16.427 120.01 16.5527 119.78 16.6575C119.025 17.0765 118.187 17.5374 117.412 17.9564C117.286 18.2078 117.14 18.3964 116.93 18.6059C116.658 18.8363 116.386 19.0458 116.092 19.2554C116.365 19.4439 116.637 19.6534 116.888 19.8629C117.475 20.3867 117.622 20.9733 117.747 22.1465C117.852 22.0418 118.02 21.8951 118.25 21.7694C119.382 21.099 121.099 20.1772 122.231 19.5696C122.65 19.2763 123.09 18.983 123.509 18.6268C124.095 18.1031 124.242 17.5165 124.368 16.3642H120.261V16.3223H120.282Z'
          fill='#0082AD'
        />
        <path
          id='XMLID_13_'
          d='M112.405 17.076C112.154 16.9293 111.881 16.7827 111.63 16.636C111.4 16.5103 111.232 16.3846 111.127 16.3008C111.232 16.1961 111.421 16.0494 111.651 15.9237C111.881 15.777 112.133 15.6304 112.405 15.4837C111.358 14.8971 110.101 14.2057 109.179 13.7029C109.032 13.5772 108.844 13.4725 108.697 13.3887C108.425 13.5772 108.152 13.7867 107.859 14.0381C107.272 14.5619 107.126 15.1485 107 16.3008C107.126 17.474 107.272 18.0397 107.859 18.5635C108.131 18.7939 108.404 19.0034 108.697 19.2129C108.844 19.1082 108.99 18.9825 109.179 18.8987C110.184 18.354 111.316 17.7045 112.405 17.076Z'
          fill='#0082AD'
        />
        <path
          id='XMLID_12_'
          d='M112.405 11.2718C112.153 11.1251 111.902 10.9994 111.651 10.8528C111.42 10.7271 111.253 10.6014 111.148 10.5176H107.042C107.167 11.6908 107.314 12.2565 107.9 12.7802C108.152 13.0107 108.424 13.1993 108.697 13.3878C108.843 13.2831 108.99 13.1993 109.158 13.0736C110.142 12.5498 111.315 11.8794 112.405 11.2718Z'
          fill='#0082AD'
        />
        <path
          id='XMLID_11_'
          d='M112.405 15.5052C112.678 15.6519 112.929 15.7985 113.159 15.9452C113.39 16.0709 113.579 16.2175 113.683 16.3223C113.579 16.427 113.411 16.5527 113.18 16.6575C112.929 16.8042 112.657 16.9508 112.405 17.0975C113.516 17.705 114.668 18.3545 115.632 18.9202C115.778 19.0459 115.967 19.1506 116.114 19.2344C116.386 19.0459 116.658 18.8364 116.952 18.585C117.538 18.0612 117.685 17.4746 117.811 16.3223C117.685 15.1491 117.538 14.5834 116.952 14.0596C116.679 13.8292 116.407 13.6197 116.114 13.4102C115.967 13.5149 115.82 13.6406 115.632 13.7244C114.731 14.2272 113.474 14.9186 112.405 15.5052Z'
          fill='#0082AD'
        />
        <path
          id='XMLID_50_2_1_'
          d='M116.114 19.2129C115.967 19.3176 115.82 19.4014 115.653 19.5271C114.731 20.03 113.474 20.7004 112.405 21.308C112.657 21.4546 112.908 21.6013 113.139 21.727C113.369 21.8527 113.537 21.9993 113.641 22.1041H117.769C117.643 20.9308 117.496 20.3652 116.91 19.8205C116.658 19.611 116.386 19.4224 116.114 19.2129Z'
          fill='#0082AD'
        />
        <path
          id='XMLID_10_'
          d='M99.1645 22.9002C98.9131 22.7535 98.6408 22.6069 98.3894 22.4602C98.1589 22.3345 97.9913 22.2088 97.8865 22.125C97.9913 22.0203 98.1799 21.8736 98.4103 21.7479C98.6408 21.6013 98.8922 21.4546 99.1645 21.308C98.117 20.7213 96.86 20.03 95.9381 19.5271C95.7915 19.4014 95.6029 19.2967 95.4563 19.2129C95.1839 19.4014 94.9116 19.611 94.6182 19.8624C94.0316 20.3861 93.885 20.9727 93.7593 22.125C93.885 23.2983 94.0316 23.8639 94.6182 24.3877C94.8906 24.6181 95.163 24.8277 95.4563 25.0372C95.6029 24.9324 95.7496 24.8067 95.9381 24.7229C96.9228 24.1572 98.0541 23.5078 99.1645 22.9002Z'
          fill='#0082AD'
        />
        <path
          id='XMLID_9_'
          d='M99.1641 17.0765C98.9127 16.9298 98.6613 16.8041 98.4099 16.6575C98.1795 16.5318 98.0119 16.4061 97.9071 16.3223H93.8008C93.9265 17.4955 94.0731 18.0612 94.6598 18.5849C94.9112 18.8154 95.1835 19.0039 95.4559 19.1925C95.6025 19.0877 95.7492 19.0039 95.9168 18.8782C96.8805 18.3545 98.0538 17.705 99.1641 17.0765Z'
          fill='#0082AD'
        />
        <path
          id='XMLID_8_'
          d='M99.1641 21.308C99.4364 21.4546 99.6878 21.6013 99.9183 21.7479C100.149 21.8736 100.337 22.0203 100.442 22.125C100.337 22.2298 100.17 22.3555 99.9392 22.4602C99.6878 22.6069 99.4155 22.7535 99.1641 22.9002C100.274 23.5078 101.427 24.1572 102.39 24.7229C102.537 24.8486 102.726 24.9534 102.872 25.0372C103.145 24.8486 103.417 24.6391 103.71 24.3877C104.297 23.8639 104.444 23.2773 104.569 22.125C104.444 20.9518 104.297 20.3861 103.71 19.8624C103.438 19.6319 103.166 19.4224 102.872 19.2129C102.726 19.3176 102.579 19.4433 102.39 19.5271C101.469 20.009 100.212 20.7004 99.1641 21.308Z'
          fill='#0082AD'
        />
        <path
          id='XMLID_7_'
          d='M102.852 25.0371C102.705 25.1419 102.559 25.2257 102.391 25.3514C101.469 25.8542 100.212 26.5246 99.1436 27.1322C99.395 27.2788 99.6464 27.4255 99.8768 27.5512C100.107 27.6769 100.275 27.8235 100.38 27.9283H104.507C104.381 26.7551 104.235 26.1894 103.648 25.6447C103.417 25.4352 103.124 25.2257 102.852 25.0371Z'
          fill='#0082AD'
        />
        <path
          id='XMLID_6_'
          d='M183.889 22.2299C183.889 22.1461 183.868 22.0833 183.868 21.9995V9.95287C183.868 9.052 183.868 8.10922 183.47 7.27119C182.862 6.01416 181.333 5.11328 178.421 5.11328C174.922 5.11328 173.665 6.37032 173.309 8.15112C173.141 8.94724 173.162 9.93192 173.162 10.1205V33.4804H177.268V20.6377C178.002 21.0357 178.693 21.4548 179.28 21.79C179.489 21.9157 179.678 22.0414 179.782 22.1461V33.4595H183.889V22.1671H183.91V22.2299H183.889ZM177.75 16.7199C177.52 16.5942 177.352 16.4685 177.247 16.3638V9.11485C177.247 8.42348 177.792 7.87876 178.504 7.87876C179.217 7.87876 179.761 8.42348 179.761 9.11485V17.8303C179.091 17.4532 178.4 17.0551 177.75 16.7199Z'
          fill='#0082AD'
        />
        <path
          id='XMLID_5_'
          d='M179.761 9.13477V17.8293C180.474 18.2273 181.144 18.6254 181.752 18.9606C182.171 19.2539 182.611 19.5472 183.03 19.9034C183.553 20.4062 183.742 20.9509 183.868 21.9775V9.99374L179.761 9.13477Z'
          fill='#0082AD'
        />
        <path
          id='XMLID_4_'
          d='M175.257 19.6112C174.838 19.3179 174.398 19.0245 173.979 18.6684C173.392 18.1446 173.246 17.558 173.12 16.3848V33.4386H177.226V20.6587C176.535 20.2816 175.844 19.9254 175.257 19.6112Z'
          fill='#0082AD'
        />
        <path
          id='XMLID_3_'
          d='M157.03 7.29268C156.422 6.03564 154.893 5.13477 151.981 5.13477C148.482 5.13477 147.225 6.3918 146.869 8.15165C146.701 8.94778 146.722 9.93246 146.722 10.1001V33.4809H150.807V20.6801C151.541 21.0782 152.232 21.4972 152.819 21.8115C153.028 21.9372 153.217 22.0629 153.321 22.1676V33.46H157.428V9.99531C157.428 9.07348 157.428 8.15165 157.03 7.29268ZM151.31 16.7205C151.08 16.5947 150.912 16.469 150.807 16.3643V9.13633C150.807 8.44496 151.352 7.90025 152.064 7.90025C152.777 7.90025 153.321 8.44496 153.321 9.13633V17.8308C152.651 17.4747 151.96 17.0766 151.31 16.7205Z'
          fill='#0082AD'
        />
        <path
          id='XMLID_2_'
          d='M153.321 9.13477V17.8293C154.034 18.2273 154.704 18.6254 155.312 18.9606C155.731 19.2539 156.171 19.5472 156.59 19.9034C157.113 20.4062 157.302 20.9509 157.428 21.9775V9.99374L153.321 9.13477Z'
          fill='#0082AD'
        />
        <path
          id='XMLID_1_'
          d='M148.838 19.6112C148.419 19.3179 147.979 19.0245 147.56 18.6684C146.974 18.1446 146.827 17.558 146.701 16.3848V33.4386H150.807V20.6587C150.095 20.2816 149.404 19.9254 148.838 19.6112Z'
          fill='#0082AD'
        />
        <path
          id='Vector_4'
          d='M41.8017 17.076C41.1732 16.5732 33.4843 11.6289 33.2329 11.4613V8.84243C33.2329 4.29615 29.5456 0.587891 24.9784 0.587891C20.4531 0.587891 16.7448 4.2752 16.7448 8.84243V11.5032C15.9906 11.985 8.84644 16.5942 8.23888 17.076C7.3799 17.8302 6.96089 18.6683 6.93994 20.3653V20.3862C6.93994 21.4756 7.1285 22.2089 7.48466 22.7955V22.8165H7.50561C7.69416 23.1307 7.94557 23.4031 8.23888 23.6755C8.88835 24.1783 16.8077 29.2902 16.8077 29.2902V32.8518C16.8077 32.9356 16.8077 33.1661 16.8077 33.4803H22.2967V29.0807C22.2967 26.9857 22.0034 26.0848 19.615 24.5973C17.6666 23.3822 13.5394 20.7424 12.9737 20.3653C12.9737 20.3653 17.6666 17.3065 19.615 16.1123C22.0034 14.6248 22.2967 13.7239 22.2967 11.6289V10.8537H22.2758V8.10916C22.2758 6.60072 23.4909 5.38558 24.9993 5.38558C26.5078 5.38558 27.7229 6.60072 27.7229 8.10916V10.8537H27.702V11.6289C27.702 13.7239 27.9953 14.6248 30.3836 16.1123C32.332 17.3274 37.025 20.3653 37.025 20.3653C36.4593 20.7424 32.332 23.3822 30.3836 24.5973C27.9953 26.0848 27.702 26.9857 27.702 29.0807V33.4803H33.191C33.191 33.1661 33.191 32.9356 33.191 32.8518V29.2693C33.191 29.2693 41.1103 24.1573 41.7598 23.6545C42.0531 23.4031 42.3045 23.1098 42.4931 22.7955H42.514V22.7746C42.8702 22.188 43.0378 21.4547 43.0587 20.3653V20.3443C43.0797 18.6683 42.6607 17.8093 41.8017 17.076Z'
          fill='#0082AD'
        />
        <path
          id='Vector_5'
          d='M43.0589 33.4595C44.8523 33.4595 46.3062 32.0056 46.3062 30.2122C46.3062 28.4187 44.8523 26.9648 43.0589 26.9648C41.2654 26.9648 39.8115 28.4187 39.8115 30.2122C39.8115 32.0056 41.2654 33.4595 43.0589 33.4595Z'
          fill='#EC1C24'
        />
        <path
          id='Vector_6'
          d='M42.011 17.2448C41.9901 17.2238 41.9482 17.1819 41.9272 17.161C41.9272 17.161 41.9272 17.161 41.9062 17.161C41.8643 17.14 41.8434 17.0981 41.8015 17.0772C41.8015 17.0772 41.7805 17.0562 41.7596 17.0562C41.4872 16.8676 40.5864 16.2391 39.4341 15.5059L34.4897 18.7532C34.5735 18.8161 34.6574 18.858 34.7412 18.9208C34.8669 19.0046 34.9926 19.0884 35.1183 19.1513C35.1183 19.1513 35.1183 19.1513 35.1392 19.1513C35.1811 19.1722 35.2021 19.1932 35.244 19.2141C35.244 19.2141 35.2649 19.2141 35.2649 19.2351C35.3068 19.256 35.3278 19.277 35.3697 19.2979L35.3906 19.3189C35.4116 19.3398 35.4535 19.3608 35.4744 19.3817L35.4954 19.4027C35.7468 19.5703 35.9982 19.7169 36.1867 19.8636C36.1867 19.8636 36.1867 19.8636 36.2077 19.8636C36.4801 20.0312 36.6896 20.1778 36.8362 20.2616C36.8362 20.2616 36.8572 20.2616 36.8572 20.2826C36.9619 20.3455 37.0248 20.3874 37.0457 20.4083C37.0457 20.3874 42.011 17.2448 42.011 17.2448Z'
          fill='#0082AD'
        />
        <path
          id='Vector_7'
          d='M8.25977 23.6545C8.49022 23.8221 9.57965 24.5554 10.8995 25.4143L15.6344 22.0622C14.3564 21.2452 13.267 20.5538 12.9946 20.3652L8.25977 23.6545Z'
          fill='#0082AD'
        />
      </g>
      <defs>
        <clipPath id='clip0_7973_88004'>
          <rect
            width='213.654'
            height='32.8925'
            fill='white'
            transform='translate(0.172852 0.587891)'
          />
        </clipPath>
      </defs>
    </svg>
  )
}
