import axios, { AxiosError, AxiosRequestConfig, AxiosResponse, HttpStatusCode } from 'axios'
import qs from 'qs'
import UrlPattern from 'url-pattern'

import { ANONYMOUS } from '@/configs/endpoint'
import { apiBaseUrl } from '@/configs/env'
import { anonymous, refreshToken } from '@/services'
import { BaseRequest, BaseResponse } from '@/types/common'

import {
  getAppAccessToken,
  getUserAccessToken,
  isHasAppAccessToken,
  isHasUserAccessToken,
  isHasUserRefreshToken,
} from './app'

export const api = async <Response = BaseResponse, Params = never, Query = never, Data = never>(
  payload: BaseRequest<Params, Query, Data>
): Promise<AxiosResponse<Response>> => {
  const client = axios.create({
    baseURL: payload?.baseURL || apiBaseUrl,
  })

  client.interceptors.request.use(
    async (config) => {
      // request app access token when
      // - app not have access token
      // - url is not equal with get anonymous token
      if (!isHasAppAccessToken() && config.url !== ANONYMOUS) {
        await anonymous()
      }

      // request user refresh token when
      // - user not have access token
      // - user have refresh token
      if (!isHasUserAccessToken() && isHasUserRefreshToken()) {
        await refreshToken()
      }

      // assign authorization headers
      if (!config.headers.Authorization && isHasUserAccessToken()) {
        config.headers.Authorization = `Bearer ${getUserAccessToken()}`
      } else if (!config.headers.Authorization && isHasAppAccessToken()) {
        config.headers.Authorization = `Bearer ${getAppAccessToken()}`
      }

      return config
    },
    null,
    {
      runWhen: (config) => config.baseURL === apiBaseUrl,
    }
  )

  client.interceptors.response.use(
    (response) => response,
    async (error: AxiosError) => {
      const config = error.config as AxiosRequestConfig

      // only run when response status is 401 (unauthorized)
      if (error.response?.status === HttpStatusCode.Unauthorized && isHasUserRefreshToken()) {
        await refreshToken()

        config.headers = {
          ...config.headers,
          Authorization: `Bearer ${getUserAccessToken()}`,
        }

        return client.request(config)
      }

      return Promise.reject(error)
    },
    {
      runWhen: (config) => config.baseURL === apiBaseUrl,
    }
  )

  const request: AxiosRequestConfig<Data> = {
    url: generateEndpoint(payload.url, payload.params || {}),
    method: payload.method,
    headers: payload.headers,
    data: payload.data,
    params: payload.query,
    paramsSerializer: (params) => qs.stringify(params),
    signal: payload.signal,
  }

  return await client.request(request)
}

const generateEndpoint = (url: string, params = {}) => {
  const pattern = new UrlPattern(url)

  return pattern.stringify(params)
}
