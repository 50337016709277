import { queryOptions, useQuery, useSuspenseQuery } from '@tanstack/react-query'

import { getDetailBanner as queryFn, getDetailBannerKey, GetDetailBannerRequest } from '@/services'

export const useGetDetailBanner = (request: GetDetailBannerRequest, enabled = true) => {
  const {
    refetch: getDetailBanner,
    data: getDetailBannerData,
    error: getDetailBannerError,
    isFetching: getDetailBannerIsFetching,
    isPending: getDetailBannerIsPending,
    isSuccess: getDetailBannerIsSuccess,
    isError: getDetailBannerIsError,
  } = useQuery({
    enabled,
    queryFn: () => queryFn(request),
    queryKey: [getDetailBannerKey, request],
    refetchOnMount: false,
    refetchOnReconnect: false,
    refetchOnWindowFocus: false,
    refetchInterval: false,
    refetchIntervalInBackground: false,
    retryOnMount: false,
    gcTime: 0,
  })

  return {
    getDetailBanner,
    getDetailBannerData,
    getDetailBannerError,
    getDetailBannerIsFetching,
    getDetailBannerIsPending,
    getDetailBannerIsSuccess,
    getDetailBannerIsError,
  }
}

export const useSuspenseGetDetailBanner = (request: GetDetailBannerRequest) => {
  const { data: getDetailBannerData } = useSuspenseQuery(getDetailBannerOptions(request))

  return { getDetailBannerData }
}

export const getDetailBannerOptions = (request: GetDetailBannerRequest) =>
  queryOptions({
    queryFn: () => queryFn(request),
    queryKey: [getDetailBannerKey, request],
  })
