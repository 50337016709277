import { PROGRAM_DETAIL } from '@/configs/endpoint'
import { BaseResponse, CommonData, FileData, ModifiedBy, TimestampAndVersion } from '@/types/common'
import { api } from '@/utils/api'

export type GetDetailProgramRequestParams = {
  xid: string
}

export type GetDetailProgramRequest = {
  params: GetDetailProgramRequestParams
}

export type SeasonData = {
  xid: string
  name: string
  sequence: string
  cover: FileData | null
  trailer: FileData | null
  synopsis: {
    id: string
    en: string
  }
}

export type DetailListCategoryResponse = {
  xid: string
  name: string
  topLevelCategory: {
    name: string
    xid: string
  }
}

export type ListInformationResponse = {
  visible: string
  sequence: string
  key: string
  informationTypeId: number
  value: {
    items: CommonData[] | null
  }
}

export type GetDetailProgramResponse = BaseResponse<{
  program: {
    xid: string
    name: string
    synopsis: {
      id: string
      en: string
    }
    trailer: any
    cover?: {
      notificationCover?: FileData
      applicationCover?: FileData
      topChartCover?: FileData
    }
    category: {
      items: DetailListCategoryResponse[]
    } | null
    releaseYear: string
    ageRating: CommonData
    programInformationSnapshot?: null | {
      items: Array<{
        visible: boolean
        sequence: string
        key: string
        informationTypeId: number
        value: {
          items: Array<{
            xid: string
            name: string
          }>
        }
      }>
    }
    productionInformationSnapshot: null
    publishedDate: string
    modifiedBy: ModifiedBy
    statusId: number
    programStatusId: number
    expiredAt: string
    programTypeId: number
    programEpisode: any
    totalSeason: string
    totalDislike: string
    totalLike: string
    totalShare: string
  } & TimestampAndVersion
  figure: {
    items: CommonData[]
  } | null
  totalDislike: string
  totalLike: string
  totalShare?: string
  tags: {
    items: CommonData[]
  } | null
  seasons: {
    items: SeasonData[]
  } | null
}>

export const getDetailProgram = async (request: GetDetailProgramRequest) => {
  const response = await api<GetDetailProgramResponse, GetDetailProgramRequestParams>({
    ...request,
    url: PROGRAM_DETAIL,
    method: 'GET',
  })

  return response.data
}

export const getDetailProgramKey = 'GET_DETAIL_PROGRAM'
