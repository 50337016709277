import { HIGHLIGHTS } from '@/configs/endpoint'
import { DetailListCategoryResponse } from '@/services'
import { BaseResponse, CommonData, FileData, ModifiedBy, TimestampAndVersion } from '@/types/common'
import { api } from '@/utils/api'

export type GetDetailHighlightsRequestParams = {
  xid: string
}

export type GetDetailHighlightsRequest = {
  params: GetDetailHighlightsRequestParams
}

export type GetDetailHighlightsResponse = BaseResponse<
  {
    program: {
      xid: string
      name: string
      synopsis: {
        id: string
        en: string
      }
      trailer: any
      cover?: {
        notificationCover?: FileData
        applicationCover?: FileData
        topChartCover?: FileData
      }
      category: {
        items: DetailListCategoryResponse[]
      } | null
      releaseYear: string
      ageRating: CommonData
      programInformationSnapshot?: null | {
        items: Array<{
          visible: boolean
          sequence: string
          key: string
          informationTypeId: number
          value: {
            items: Array<{
              xid: string
              name: string
            }>
          }
        }>
      }
      productionInformationSnapshot: null
      publishedDate: string
      modifiedBy: ModifiedBy
      statusId: number
      programStatusId: number
      expiredAt: string
      programTypeId: number
      programEpisode: any
      totalSeason: string
      totalDislike: string
      totalLike: string
      totalShare: string
    } & TimestampAndVersion
    cover: FileData
  } & TimestampAndVersion
>

export const getDetailHighlights = async () => {
  const response = await api<GetDetailHighlightsResponse, never>({
    url: HIGHLIGHTS,
    method: 'GET',
  })

  return response.data
}

export const getDetailHighlightsKey = 'GET_DETAIL_HIGHLIGHTS'
