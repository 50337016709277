import { CSSObject } from '@emotion/react'
import styled from '@emotion/styled'
import { FormRadioInput, Typography } from '@nbsdev/naini-react'
import clsx from 'clsx'
import { forwardRef, InputHTMLAttributes } from 'react'

type StyledProps = {
  width?: CSSObject['width']
  height?: CSSObject['height']
  background?: string
}

const Styled = styled('div')<StyledProps>(
  ({ width = '100%', height = '100%', background }): CSSObject => ({
    width,
    height,
    position: 'relative',
    display: 'flex',
    padding: '16px',
    borderRadius: '8px',
    border: '1px solid #D9D9D9',
    cursor: 'pointer',
    justifyContent: 'end',
    backgroundColor: '#fff',
    backgroundImage: `url('${background}')`,
    backgroundRepeat: 'no-repeat',
    backgroundSize: 'cover',
    color: '#808080',

    '>:not([hidden])~:not([hidden])': {
      marginLeft: '8px',
    },

    ':has(input:checked)': {
      backgroundColor: '#E6EBF3',
      color: '#003684',
    },

    'input:checked': {
      border: 0,
      backgroundImage:
        "url(\"data:image/svg+xml,%3Csvg width='20' height='20' viewBox='0 0 20 20' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Crect x='0.5' y='0.5' width='19' height='19' rx='9.5' fill='%23003684'/%3E%3Crect x='0.5' y='0.5' width='19' height='19' rx='9.5' stroke='%23003684'/%3E%3Cpath d='M14.6663 6.5L8.24967 12.9167L5.33301 10' stroke='white' stroke-width='2' stroke-linecap='round' stroke-linejoin='round'/%3E%3C/svg%3E%0A\")",
    },

    'input:checked::before': {
      boxShadow: 'none',
      width: '0',
      height: '0',
      borderRadius: '0',
      backgroundColor: 'transparent',
    },

    '.absolute-label': {
      position: 'absolute',
      top: 0,
      left: 0,
      width: '100%',
      height: '100%',
      cursor: 'pointer',
    },

    '.disabled': {
      cursor: 'not-allowed',
    },
  })
)

export type FormRadioImageProps = {
  id?: string
  label?: string
} & StyledProps &
  InputHTMLAttributes<HTMLInputElement>

export const FormRadioImage = forwardRef<HTMLInputElement, FormRadioImageProps>(
  ({ id, label, width, height, background, disabled, ...props }, ref) => {
    return (
      <Styled
        width={width}
        height={height}
        background={background}
      >
        <label
          htmlFor={id}
          className={clsx('absolute-label', disabled && 'disabled')}
        />

        {label && (
          <Typography
            as='label'
            weight='semibold'
            css={{ color: 'inherit' }}
          >
            {label}
          </Typography>
        )}

        <fieldset disabled={disabled}>
          <FormRadioInput
            ref={ref}
            id={id}
            type='radio'
            {...props}
          />
        </fieldset>
      </Styled>
    )
  }
)
