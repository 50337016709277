import { queryOptions, useQuery, useSuspenseQuery } from '@tanstack/react-query'

import {
  getDetailProgramSubmissionOtherInformation as queryFn,
  getDetailProgramSubmissionOtherInformationKey,
  GetDetailProgramSubmissionOtherInformationRequest,
} from '@/services'

export const useGetDetailSubmissionOtherInformation = (
  request: GetDetailProgramSubmissionOtherInformationRequest,
  enabled = true
) => {
  const {
    refetch: getDetailProgramSubmissionOtherInformation,
    data: getDetailProgramSubmissionOtherInformationData,
    error: getDetailProgramSubmissionOtherInformationError,
    isFetching: getDetailProgramSubmissionOtherInformationIsFetching,
    isPending: getDetailProgramSubmissionOtherInformationIsPending,
    isSuccess: getDetailProgramSubmissionOtherInformationIsSuccess,
    isError: getDetailProgramSubmissionOtherInformationIsError,
  } = useQuery({
    enabled,
    queryFn: () => queryFn(request),
    queryKey: [getDetailProgramSubmissionOtherInformationKey, request],
    refetchOnMount: false,
    refetchOnReconnect: false,
    refetchOnWindowFocus: false,
    refetchInterval: false,
    refetchIntervalInBackground: false,
    retryOnMount: false,
    gcTime: 0,
  })

  return {
    getDetailProgramSubmissionOtherInformation,
    getDetailProgramSubmissionOtherInformationData,
    getDetailProgramSubmissionOtherInformationError,
    getDetailProgramSubmissionOtherInformationIsFetching,
    getDetailProgramSubmissionOtherInformationIsPending,
    getDetailProgramSubmissionOtherInformationIsSuccess,
    getDetailProgramSubmissionOtherInformationIsError,
  }
}

export const useSuspenseGetDetailProgramSubmissionOtherInformation = (
  request: GetDetailProgramSubmissionOtherInformationRequest
) => {
  const { data: getDetailProgramSubmissionOtherInformationData } = useSuspenseQuery(
    getDetailProgramSubmissionOtherInformationOptions(request)
  )

  return { getDetailProgramSubmissionOtherInformationData }
}

export const getDetailProgramSubmissionOtherInformationOptions = (
  request: GetDetailProgramSubmissionOtherInformationRequest
) =>
  queryOptions({
    queryFn: () => queryFn(request),
    queryKey: [getDetailProgramSubmissionOtherInformationKey, request],
  })
