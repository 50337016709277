import { queryOptions, useQuery, useSuspenseQuery } from '@tanstack/react-query'

import { getDetailCast as queryFn, getDetailCastKey, GetDetailCastRequest } from '@/services'

export const useGetDetailCast = (request: GetDetailCastRequest, enabled = true) => {
  const {
    refetch: getDetailCast,
    data: getDetailCastData,
    error: getDetailCastError,
    isFetching: getDetailCastIsFetching,
    isPending: getDetailCastIsPending,
    isSuccess: getDetailCastIsSuccess,
    isError: getDetailCastIsError,
  } = useQuery({
    enabled,
    queryFn: () => queryFn(request),
    queryKey: [getDetailCastKey, request],
    refetchOnMount: false,
    refetchOnReconnect: false,
    refetchOnWindowFocus: false,
    refetchInterval: false,
    refetchIntervalInBackground: false,
    retryOnMount: false,
    gcTime: 0,
  })

  return {
    getDetailCast,
    getDetailCastData,
    getDetailCastError,
    getDetailCastIsFetching,
    getDetailCastIsPending,
    getDetailCastIsSuccess,
    getDetailCastIsError,
  }
}

export const useSuspenseGetDetailCast = (request: GetDetailCastRequest) => {
  const { data: getDetailCastData } = useSuspenseQuery(getDetailCastOptions(request))

  return { getDetailCastData }
}

export const getDetailCastOptions = (request: GetDetailCastRequest) =>
  queryOptions({
    queryFn: () => queryFn(request),
    queryKey: [getDetailCastKey, request],
  })
