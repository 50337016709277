import { queryOptions, useQuery, useSuspenseQuery } from '@tanstack/react-query'

import {
  getDetailNotificationBroadcasts as queryFn,
  getDetailNotificationBroadcastsKey,
  GetDetailNotificationBroadcastsRequest,
} from '@/services'

export const useGetDetailNotificationBroadcasts = (request: GetDetailNotificationBroadcastsRequest, enabled = true) => {
  const {
    refetch: getDetailNotificationBroadcasts,
    data: getDetailNotificationBroadcastsData,
    error: getDetailNotificationBroadcastsError,
    isFetching: getDetailNotificationBroadcastsIsFetching,
    isPending: getDetailNotificationBroadcastsIsPending,
    isSuccess: getDetailNotificationBroadcastsIsSuccess,
    isError: getDetailNotificationBroadcastsIsError,
  } = useQuery({
    enabled,
    queryFn: () => queryFn(request),
    queryKey: [getDetailNotificationBroadcastsKey, request],
    refetchOnMount: false,
    refetchOnReconnect: false,
    refetchOnWindowFocus: false,
    refetchInterval: false,
    refetchIntervalInBackground: false,
    retryOnMount: false,
    gcTime: 0,
  })

  return {
    getDetailNotificationBroadcasts,
    getDetailNotificationBroadcastsData,
    getDetailNotificationBroadcastsError,
    getDetailNotificationBroadcastsIsFetching,
    getDetailNotificationBroadcastsIsPending,
    getDetailNotificationBroadcastsIsSuccess,
    getDetailNotificationBroadcastsIsError,
  }
}

export const useSuspenseGetDetailNotificationBroadcasts = (request: GetDetailNotificationBroadcastsRequest) => {
  const { data: getDetailNotificationBroadcastsData } = useSuspenseQuery(
    getDetailNotificationBroadcastsOptions(request)
  )

  return { getDetailNotificationBroadcastsData }
}

export const getDetailNotificationBroadcastsOptions = (request: GetDetailNotificationBroadcastsRequest) =>
  queryOptions({
    queryFn: () => queryFn(request),
    queryKey: [getDetailNotificationBroadcastsKey, request],
  })
