import { CSSObject } from '@emotion/react'
import styled from '@emotion/styled'
import { configStyledOptions } from '@nbsdev/naini-react'
import { FC, useEffect, useState } from 'react'
import { useCopyToClipboard } from 'usehooks-ts'

import { IconButton } from '../Icon/IconButton'

type StyledProps = {
  width?: number
  height?: number
  color?: string
}

const Styled = styled(
  IconButton,
  configStyledOptions<StyledProps>(['width', 'height'])
)<StyledProps>(
  ({ width = 24, height = 24, color }): CSSObject => ({
    fontSize: `${width}px`,
    lineHeight: `${height}px`,
    cursor: 'pointer',
    color,
  })
)

const ButtonCopy: FC<
  StyledProps & {
    text: string
  }
> = ({ text = '', ...props }) => {
  const [, copy] = useCopyToClipboard()
  const [copied, setCopied] = useState(false)

  useEffect(() => {
    if (copied) {
      setTimeout(() => {
        setCopied(false)
      }, 2000)
    }
  }, [copied])

  return (
    <Styled
      width={16}
      height={16}
      className='ri-file-copy-line'
      onClick={() => {
        setCopied(true)
        copy(text)
      }}
      {...props}
    />
  )
}

export default ButtonCopy
