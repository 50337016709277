import { queryOptions, useMutation, useSuspenseQuery } from '@tanstack/react-query'

import { refreshToken as fn, refreshTokenKey } from '@/services'

export const useRefreshToken = () => {
  const {
    mutate: refreshToken,
    data: refreshTokenData,
    error: refreshTokenError,
    isPending: refreshTokenIsPending,
    isSuccess: refreshTokenIsSuccess,
    isError: refreshTokenIsError,
  } = useMutation({
    mutationFn: fn,
    mutationKey: [refreshTokenKey],
  })

  return {
    refreshToken,
    refreshTokenData,
    refreshTokenError,
    refreshTokenIsPending,
    refreshTokenIsSuccess,
    refreshTokenIsError,
  }
}

export const useSuspenseRefreshToken = () => {
  const { data } = useSuspenseQuery(refreshTokenOptions())

  return { data }
}

export const refreshTokenOptions = () =>
  queryOptions({
    queryFn: fn,
    queryKey: [refreshTokenKey],
  })
