import { useRouterState } from '@tanstack/react-router'
import { useContext, useMemo } from 'react'

import { permissions } from '@/configs/abilities'
import { Abilities, Actions } from '@/configs/enum'
import { AbilityContext } from '@/contexts'

export const usePermission = () => {
  const routerState = useRouterState()

  const getPermissionRead = useMemo(() => {
    return permissions.find((item) => item.path === routerState.matches[routerState.matches.length - 1].routeId)
      ?.actions?.read as Abilities
  }, [routerState.matches])

  const getPermissionCreate = useMemo(() => {
    return permissions.find((item) => item.path === routerState.matches[routerState.matches.length - 1].routeId)
      ?.actions?.create as Abilities
  }, [routerState.matches])

  const getPermissionUpdate = useMemo(() => {
    return permissions.find((item) => item.path === routerState.matches[routerState.matches.length - 1].routeId)
      ?.actions?.update as Abilities
  }, [routerState.matches])

  const getPermissionDelete = useMemo(() => {
    return permissions.find((item) => item.path === routerState.matches[routerState.matches.length - 1].routeId)
      ?.actions?.delete as Abilities
  }, [routerState.matches])

  return {
    getPermissionRead,
    getPermissionCreate,
    getPermissionUpdate,
    getPermissionDelete,
  }
}

export const useAbilityCan = () => {
  const ability = useContext(AbilityContext)
  const { getPermissionRead, getPermissionCreate, getPermissionUpdate, getPermissionDelete } = usePermission()

  return {
    canPermissionRead: ability.can(Actions.Access, getPermissionRead),
    canPermissionCreate: ability.can(Actions.Access, getPermissionCreate),
    canPermissionUpdate: ability.can(Actions.Access, getPermissionUpdate),
    canPermissionDelete: ability.can(Actions.Access, getPermissionDelete),
  }
}
