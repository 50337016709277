import { CSSObject } from '@emotion/react'
import { SidePanel as NainiSidePanel, SidePanelCard, SidePanelCloseButton } from '@nbsdev/naini-react'
import { forwardRef, PropsWithChildren, useEffect, useRef } from 'react'
import { createPortal } from 'react-dom'
import { useOnClickOutside } from 'usehooks-ts'

export type SidePanelProps = PropsWithChildren<{
  isOpen?: boolean
  withoutCloseButton?: boolean
  noClickOutside?: boolean
  onClose?(): void
  width?: CSSObject['maxWidth']
}>

export const SidePanel = forwardRef<HTMLDivElement, SidePanelProps>(
  ({ isOpen = false, withoutCloseButton = false, noClickOutside = false, width, onClose, children }, ref) => {
    const bodyRef = useRef(document.body)
    const sidePanelRef = useRef(null)

    const handleClose = () => {
      if (onClose && !noClickOutside) {
        onClose()
      }
    }

    useEffect(() => {
      if (isOpen) {
        bodyRef.current.style.overflow = 'hidden'
      }

      return () => {
        bodyRef.current.removeAttribute('style')
      }
    }, [])

    useOnClickOutside(sidePanelRef, handleClose)

    return createPortal(
      <NainiSidePanel
        ref={ref}
        css={(theme) => ({ zIndex: theme.zIndex.other })}
      >
        <SidePanelCard
          ref={sidePanelRef}
          width={width}
        >
          {!withoutCloseButton && (
            <SidePanelCloseButton onClick={handleClose}>
              <i className='ri-close-line' />
            </SidePanelCloseButton>
          )}

          {children}
        </SidePanelCard>
      </NainiSidePanel>,
      bodyRef.current
    )
  }
)
