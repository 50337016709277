import { FC } from 'react'
import { useTranslation } from 'react-i18next'

import { ButtonLink } from '@/components'

type Props = {
  data: unknown
}

export const PageNotFound: FC<Props> = () => {
  const { i18n } = useTranslation()

  return (
    <div
      css={{
        height: '100vh',
        textAlign: 'center',
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        justifyContent: 'center',
        userSelect: 'none',
        '> :not([hidden]) ~ :not([hidden])': {
          marginTop: '24px',
        },
      }}
    >
      <img
        src='/assets/images/404-text.webp'
        alt=''
        css={{
          display: 'none',
          position: 'absolute',
          left: '50%',
          top: '50%',
          transform: 'translate(-50%, -50%)',
          zIndex: 1,
          '@media(min-width: 1028px)': {
            display: 'block',
            width: '100%',
            maxWidth: '1084px',
          },
        }}
      />

      <img
        src='/assets/images/404-icon.webp'
        alt=''
        css={{
          position: 'relative',
          zIndex: 2,
          width: '400px',
          height: '400px',
          '@media(max-width: 1023px)': {
            display: 'block',
            width: '200px',
            height: '200px',
          },
        }}
      />

      <h1
        css={{
          position: 'relative',
          zIndex: 2,
          display: 'inline-block',
          fontSize: '16px',
          fontWeight: 400,
          lineHeight: '24px',
          width: '100%',
          '@media(min-width: 768px)': {
            width: '100%',
            maxWidth: '500px',
          },
        }}
      >
        {i18n.t('notFound')}
      </h1>

      <ButtonLink
        to='/dashboard'
        css={{
          position: 'relative',
          zIndex: 2,
        }}
        buttonSize='lg'
      >
        {i18n.t('backToDashboard')}
      </ButtonLink>
    </div>
  )
}
