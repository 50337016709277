import { CSSObject } from '@emotion/react'
import styled from '@emotion/styled'
import { configStyledOptions } from '@nbsdev/naini-react'

type FormBorderProps = {
  width?: CSSObject['width']
  padding?: CSSObject['padding']
  borderRadius?: CSSObject['borderRadius']
}

export const FormBorder = styled(
  'div',
  configStyledOptions(['width', 'padding', 'borderRadius'])
)<FormBorderProps>(
  ({ theme, width = '100%', padding = '8px', borderRadius = '8px' }): CSSObject => ({
    border: `1px solid ${theme.form.borderColor}`,
    width,
    padding,
    borderRadius,
  })
)

FormBorder.displayName = 'FormBorder'
