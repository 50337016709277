import { queryOptions, useQuery, useSuspenseQuery } from '@tanstack/react-query'

import {
  getDetailProgramSubmissionVideoEpisode as queryFn,
  getDetailProgramSubmissionVideoEpisodeKey,
  GetDetailProgramSubmissionVideoEpisodeRequest,
} from '@/services'

export const useGetDetailSubmissionVideoEpisode = (
  request: GetDetailProgramSubmissionVideoEpisodeRequest,
  enabled = true
) => {
  const {
    refetch: getDetailProgramSubmissionVideoEpisode,
    data: getDetailProgramSubmissionVideoEpisodeData,
    error: getDetailProgramSubmissionVideoEpisodeError,
    isFetching: getDetailProgramSubmissionVideoEpisodeIsFetching,
    isPending: getDetailProgramSubmissionVideoEpisodeIsPending,
    isSuccess: getDetailProgramSubmissionVideoEpisodeIsSuccess,
    isError: getDetailProgramSubmissionVideoEpisodeIsError,
  } = useQuery({
    enabled,
    queryFn: () => queryFn(request),
    queryKey: [getDetailProgramSubmissionVideoEpisodeKey, request],
    refetchOnMount: false,
    refetchOnReconnect: false,
    refetchOnWindowFocus: false,
    refetchInterval: false,
    refetchIntervalInBackground: false,
    retryOnMount: false,
    gcTime: 0,
  })

  return {
    getDetailProgramSubmissionVideoEpisode,
    getDetailProgramSubmissionVideoEpisodeData,
    getDetailProgramSubmissionVideoEpisodeError,
    getDetailProgramSubmissionVideoEpisodeIsFetching,
    getDetailProgramSubmissionVideoEpisodeIsPending,
    getDetailProgramSubmissionVideoEpisodeIsSuccess,
    getDetailProgramSubmissionVideoEpisodeIsError,
  }
}

export const useSuspenseGetDetailProgramSubmissionVideoEpisode = (
  request: GetDetailProgramSubmissionVideoEpisodeRequest
) => {
  const { data: getDetailProgramSubmissionVideoEpisodeData } = useSuspenseQuery(
    getDetailProgramSubmissionVideoEpisodeOptions(request)
  )

  return { getDetailProgramSubmissionVideoEpisodeData }
}

export const getDetailProgramSubmissionVideoEpisodeOptions = (request: GetDetailProgramSubmissionVideoEpisodeRequest) =>
  queryOptions({
    queryFn: () => queryFn(request),
    queryKey: [getDetailProgramSubmissionVideoEpisodeKey, request],
  })
