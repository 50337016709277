import { CSSObject } from '@emotion/react'
import styled from '@emotion/styled'
import { FormLabel, FormRadioInput } from '@nbsdev/naini-react'
import { forwardRef, InputHTMLAttributes } from 'react'

const Styled = styled('div')(
  (): CSSObject => ({
    display: 'flex',
    alignItems: 'flex-start',
    justifyContent: 'center',
    cursor: 'pointer',
    '>:not([hidden])~:not([hidden])': {
      marginLeft: '8px',
    },
    '>label': {
      cursor: 'pointer',
    },
    '>input:checked': {
      border: 0,
      backgroundImage:
        "url(\"data:image/svg+xml,%3Csvg width='20' height='20' viewBox='0 0 20 20' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Crect x='0.5' y='0.5' width='19' height='19' rx='9.5' fill='%23003684'/%3E%3Crect x='0.5' y='0.5' width='19' height='19' rx='9.5' stroke='%23003684'/%3E%3Cpath d='M14.6663 6.5L8.24967 12.9167L5.33301 10' stroke='white' stroke-width='2' stroke-linecap='round' stroke-linejoin='round'/%3E%3C/svg%3E%0A\")",
    },
    '>input:checked::before': {
      boxShadow: 'none',
      width: '0',
      height: '0',
      borderRadius: '0',
      backgroundColor: 'transparent',
    },
  })
)

export type FormRadioProps = {
  id?: string
  label?: string
} & InputHTMLAttributes<HTMLInputElement>

export const FormRadio = forwardRef<HTMLInputElement, FormRadioProps>(({ id, label, ...props }, ref) => {
  return (
    <Styled>
      <FormRadioInput
        ref={ref}
        id={id}
        type='radio'
        {...props}
      />

      {label && (
        <FormLabel
          as='label'
          htmlFor={id}
        >
          {label}
        </FormLabel>
      )}
    </Styled>
  )
})
