import { CSSObject } from '@emotion/react'
import { getDefaultTheme, ModalBackdrop, ModalCard, ModalCloseButton, ModalPosition } from '@nbsdev/naini-react'
import { forwardRef, PropsWithChildren, useEffect, useRef } from 'react'
import { createPortal } from 'react-dom'
import { useOnClickOutside } from 'usehooks-ts'

export type ModalProps = PropsWithChildren<{
  isOpen?: boolean
  withoutCloseButton?: boolean
  onClose?(): void
  maxWidth?: CSSObject['maxWidth']
}>

export const Modal = forwardRef<HTMLDivElement, ModalProps>(
  ({ isOpen = false, withoutCloseButton = false, onClose, maxWidth = '480px', children }, ref) => {
    const bodyRef = useRef(document.body)
    const modalRef = useRef(null)

    const handleClose = () => {
      if (onClose) {
        onClose()
      }
    }

    useEffect(() => {
      if (isOpen) {
        bodyRef.current.style.overflow = 'hidden'
      }

      return () => {
        bodyRef.current.removeAttribute('style')
      }
    }, [])

    useOnClickOutside(modalRef, handleClose)

    return createPortal(
      <ModalBackdrop
        ref={ref}
        css={{
          zIndex: getDefaultTheme.zIndex.other + 2,
        }}
      >
        <ModalPosition>
          <ModalCard
            ref={modalRef}
            css={{ maxWidth, width: '100%', maxHeight: '100%' }}
          >
            {!withoutCloseButton && (
              <ModalCloseButton
                type='button'
                onClick={handleClose}
              >
                <span className='ri-close-circle-fill' />
              </ModalCloseButton>
            )}

            {children}
          </ModalCard>
        </ModalPosition>
      </ModalBackdrop>,
      bodyRef.current
    )
  }
)
