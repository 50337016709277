import { CSSObject } from '@emotion/react'
import styled from '@emotion/styled'

export const FormSchedule = styled('div')(
  ({ theme }): CSSObject => ({
    width: '100%',
    padding: '12px',
    border: `1px solid ${theme.form.borderColor}`,
    borderRadius: '12px',
  })
)

FormSchedule.displayName = 'FormSchedule'
