// environment
export const isDev = import.meta.env.DEV
export const isProd = import.meta.env.PROD

// App
export const appName = import.meta.env.APP_NAME
export const appDescription = import.meta.env.APP_DESCRIPTION
export const appVersion = import.meta.env.APP_VERSION
export const appBuildSignature = import.meta.env.APP_BUILD_SIGNATURE

// API
export const apiBaseUrl = import.meta.env.API_BASE_URL
export const apiClientId = import.meta.env.API_CLIENT_ID
export const apiClientSecret = import.meta.env.API_CLIENT_SECRET

// Sentry
export const sentryDSN = import.meta.env.SENTRY_DSN
export const sentryEnvironment = import.meta.env.SENTRY_ENVIRONMENT
