import { queryOptions, useQuery, useSuspenseQuery } from '@tanstack/react-query'

import { getDetailCategory as queryFn, getDetailCategoryKey, GetDetailCategoryRequest } from '@/services'

export const useGetDetailCategory = (request: GetDetailCategoryRequest, enabled = true) => {
  const {
    refetch: getDetailCategory,
    data: getDetailCategoryData,
    error: getDetailCategoryError,
    isFetching: getDetailCategoryIsFetching,
    isPending: getDetailCategoryIsPending,
    isSuccess: getDetailCategoryIsSuccess,
    isError: getDetailCategoryIsError,
  } = useQuery({
    enabled,
    queryFn: () => queryFn(request),
    queryKey: [getDetailCategoryKey, request],
    refetchOnMount: false,
    refetchOnReconnect: false,
    refetchOnWindowFocus: false,
    refetchInterval: false,
    refetchIntervalInBackground: false,
    retryOnMount: false,
    gcTime: 0,
  })

  return {
    getDetailCategory,
    getDetailCategoryData,
    getDetailCategoryError,
    getDetailCategoryIsFetching,
    getDetailCategoryIsPending,
    getDetailCategoryIsSuccess,
    getDetailCategoryIsError,
  }
}

export const useSuspenseGetDetailCategory = (request: GetDetailCategoryRequest) => {
  const { data: getDetailCategoryData } = useSuspenseQuery(getDetailCategoryOptions(request))

  return { getDetailCategoryData }
}

export const getDetailCategoryOptions = (request: GetDetailCategoryRequest) =>
  queryOptions({
    queryFn: () => queryFn(request),
    queryKey: [getDetailCategoryKey, request],
  })
