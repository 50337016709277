import { queryOptions, useQuery, useSuspenseQuery } from '@tanstack/react-query'

import { getDetailSubject as queryFn, getDetailSubjectKey, GetDetailSubjectRequest } from '@/services'

export const useGetDetailSubject = (request: GetDetailSubjectRequest, enabled = true) => {
  const {
    refetch: getDetailSubject,
    data: getDetailSubjectData,
    error: getDetailSubjectError,
    isFetching: getDetailSubjectIsFetching,
    isPending: getDetailSubjectIsPending,
    isSuccess: getDetailSubjectIsSuccess,
    isError: getDetailSubjectIsError,
  } = useQuery({
    enabled,
    queryFn: () => queryFn(request),
    queryKey: [getDetailSubjectKey, request],
    refetchOnMount: false,
    refetchOnReconnect: false,
    refetchOnWindowFocus: false,
    refetchInterval: false,
    refetchIntervalInBackground: false,
    retryOnMount: false,
    gcTime: 0,
  })

  return {
    getDetailSubject,
    getDetailSubjectData,
    getDetailSubjectError,
    getDetailSubjectIsFetching,
    getDetailSubjectIsPending,
    getDetailSubjectIsSuccess,
    getDetailSubjectIsError,
  }
}

export const useSuspenseGetDetailSubject = (request: GetDetailSubjectRequest) => {
  const { data: getDetailSubjectData } = useSuspenseQuery(getDetailSubjectOptions(request))

  return { getDetailSubjectData }
}

export const getDetailSubjectOptions = (request: GetDetailSubjectRequest) =>
  queryOptions({
    queryFn: () => queryFn(request),
    queryKey: [getDetailSubjectKey, request],
  })
