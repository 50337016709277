export enum Actions {
  Access = 'access',
  Manage = 'manage',
}

export enum Abilities {
  // Dashboard Dashboard
  ReadDashboard = 'RD',

  // Banner Management
  ReadBanner = 'RBM',
  CreateBanner = 'CBM',
  UpdateBanner = 'UBM',
  DeleteBanner = 'DBM',

  // Notification Broadcast
  ReadNotificationBroadcast = 'RNB',
  CreateNotificationBroadcast = 'CNB',
  UpdateNotificationBroadcast = 'UNB',
  DeleteNotificationBroadcast = 'DNB',

  // Splash Screen
  ReadSplashScreen = 'RSS',
  CreateSplashScreen = 'CSS',
  UpdateSplashScreen = 'USS',
  DeleteSplashScreen = 'DSS',

  // Slideshow
  ReadSlideShow = 'RSLD',
  CreateSlideShow = 'CSLD',
  UpdateSlideShow = 'USLD',
  DeleteSlideShow = 'DSLD',

  // Program Highlight
  ReadProgramHighlight = 'RPHL',
  CreateProgramHighlight = 'CPHL',
  UpdateProgramHighlight = 'UPHL',
  DeleteProgramHighlight = 'DPHL',

  // Static Splashscreen
  UpdateStaticSplashScreen = 'USTS',
  ReadStaticSplashScreen = 'RSTS',

  // Dynamic Content
  ReadDynamicContent = 'RDC',
  CreateDynamicContent = 'CDC',
  UpdateDynamicContent = 'UDC',
  DeleteDynamicContent = 'DDC',

  // Program Library
  ReadProgramLibrary = 'RPL',
  UpdateProgramLibrary = 'UPL',
  DeleteProgramLibrary = 'DPL',

  // Submission Program
  ReadSubmissionProgram = 'RSPG',
  CreateSubmissionProgram = 'CSPG',
  UpdateSubmissionProgram = 'USPG',
  DeleteSubmissionProgram = 'DSPG',

  // Program Need Review
  ReadProgramNeedReview = 'RPNR',
  UpdateProgramNeedReview = 'UPNR',

  // statistic program
  ReadProgramStatistic = 'RPGS',

  // Live Channel
  ReadLiveChannel = 'RLC',
  CreateLiveChannel = 'CLC',
  UpdateLiveChannel = 'ULC',
  DeleteLiveChannel = 'DLC',

  // Program Coverage
  ReadProgramCoverage = 'RPGC',
  CreateProgramCoverage = 'CPGC',
  UpdateProgramCoverage = 'UPGC',
  DeleteProgramCoverage = 'DPGC',

  // Master Data Top Category
  ReadMasterDataTopLevelCategory = 'RMDTLC',
  CreateMasterDataTopLevelCategory = 'CMDTLC',
  UpdateMasterDataTopLevelCategory = 'UMDTLC',
  DeleteMasterDataTopLevelCategory = 'DMDTLC',

  // Master Data Category
  ReadMasterDataCategory = 'RMDCT',
  CreateMasterDataCategory = 'CMDCT',
  UpdateMasterDataCategory = 'UMDCT',
  DeleteMasterDataCategory = 'DMDCT',

  // Master Data Cast
  ReadMasterDataCast = 'RMDC',
  CreateMasterDataCast = 'CMDC',
  UpdateMasterDataCast = 'UMDC',
  DeleteMasterDataCast = 'DMDC',

  // Master Data Figure
  ReadMasterDataFigure = 'RMDF',
  CreateMasterDataFigure = 'CMDF',
  UpdateMasterDataFigure = 'UMDF',
  DeleteMasterDataFigure = 'DMDF',

  // Master Data Director
  ReadMasterDataDirector = 'RMDD',
  CreateMasterDataDirector = 'CMDD',
  UpdateMasterDataDirector = 'UMDD',
  DeleteMasterDataDirector = 'DMDD',

  // Master Data Age Rating
  ReadMasterDataAgeRating = 'RMDAR',
  CreateMasterDataAgeRating = 'CMDAR',
  UpdateMasterDataAgeRating = 'UMDAR',
  DeleteMasterDataAgeRating = 'DMDAR',

  // Master Data Tagging
  ReadMasterDataTagging = 'RMDT',
  CreateMasterDataTagging = 'CMDT',
  UpdateMasterDataTagging = 'UMDT',
  DeleteMasterDataTagging = 'DMDT',

  // Other Information About Us
  ReadOtherInformationAboutUs = 'ROIAU',
  UpdateOtherInformationAboutUs = 'UOIAU',

  // Other Information Contact and Social Media
  ReadOtherInformationContact = 'ROIC',
  UpdateOtherInformationContact = 'UOIC',
  CreateOtherInformationContact = 'COIC',
  DeleteOtherInformationContact = 'DOIC',

  // Other Information Term Condition
  ReadOtherInformationTermCondition = 'ROITC',
  UpdateOtherInformationTermCondition = 'UOITC',

  // Other Information Privacy Policy
  ReadOtherInformationPrivacyPolicy = 'ROIPP',
  UpdateOtherInformationPrivacyPolicy = 'UOIPP',

  // Other Information Feedback Url
  ReadOtherInformationFeedbackUrl = 'ROIFU',
  UpdateOtherInformationFeedbackUrl = 'UOIFU',

  // Other Information FAQ
  ReadOtherInformationFaq = 'ROIFAQ',
  CreateOtherInformationFaq = 'COIFAQ',
  UpdateOtherInformationFaq = 'UOIFAQ',
  DeleteOtherInformationFaq = 'DOIFAQ',

  // User Application
  ReadUserApplication = 'RUA',

  // Admin CMS
  ReadAdminCMS = 'RACMS',
  CreateAdminCMS = 'CACMS',
  UpdateAdminCMS = 'UACMS',
  DeleteAdminCMS = 'DACMS',

  // User Management
  ReadUserManagement = 'RUM',
  CreateUserManagement = 'CUM',
  UpdateUserManagement = 'UUM',
  DeleteUserManagement = 'DUM',

  // Profile
  ReadProfile = 'RPF',
  UpdateProfile = 'UPF',

  // Password
  UpdatePassword = 'UPS',

  //  Master Data Subject,
  ReadMasterDataSubject = 'RMDS',
  CreateMasterDataSubject = 'CMDS',
  UpdateMasterDataSubject = 'UMDS',
  DeleteMasterDataSubject = 'DMDS',

  //  Master Data Guest Speaker,
  ReadMasterDataGuestSpeaker = 'RMDGS',
  CreateMasterDataGuestSpeaker = 'CMDGS',
  UpdateMasterDataGuestSpeaker = 'UMDGS',
  DeleteMasterDataGuestSpeaker = 'DMDGS',
}

export enum ResponseErrorCode {
  ErrCanceled = 'ERR_CANCELED',
  ErrNetwork = 'ERR_NETWORK',
  Unauthorized = '401',
  InactiveUser = 'E_AUTH_1',
  ContentDuplicated = 'E_PRG_7',
  invalidSessionForgotPasswordAdmin = 'E_AUTH_11',
  MaximumRequestSend = 'E_AUTH_10',
  ResourceNotFound = 'E_COMM_1',
  ResourceCannotDuplicated = 'E_COMM_4',
  ResourceIsUsed = 'E_COMM_7',
  ErrSlideShowLimit = 'E_SLD_1',
  DateInvalid = 'E_PRG_14',
  DynamicSplashScreenDateDuplicate = 'E_SPC_1',
}

export enum Language {
  Indonesia = 'id',
  English = 'en',
}

export enum AuthProvider {
  UNKNOWN = 0,
  BASIC_AUTH = 1,
  GOOGLE_SIGN_IN = 2,
  APPLE_ID_SIGN_IN = 3,
  EMAIL = 4,
}

export enum DevicePlatform {
  UNKNOWN = 0,
  ANDROID = 1,
  IOS = 2,
  SMART_TV = 3,
  WEB = 4,
}

export enum SequenceTypeId {
  UP = 1,
  DOWN = 2,
}

export enum BannerType {
  UNKNOWN = 0,
  PROGRAM = 1,
  URL_SITE = 2,
}

export enum NotificationBroadcastType {
  UNKNOWN = 0,
  PROGRAM = 1,
  INFORMATION = 2,
}

export enum AssetType {
  UNKNOWN_ASSET_TYPE = 0,
  USER_AVATAR = 1,
  MOBILE_SPLASHSCREEN = 2,
  COVER_CATEGORY = 3,
  COVER_PROGRAM = 4,
  COVER_BANNER = 5,
  ASSETS = 6,
  COVER_NOTIFICATION_BROADCAST = 7,
  COVER_LIVESTREAM = 8,
  TRAILER_PROGRAM = 9,
  TRAILER_PROGRAM_EPISODE = 10,
  COVER_PROGRAM_EPISODE = 11,
  SUBTITLE_PROGRAM_EPISODE = 12,
  WIWARA_PROGRAM_EPISODE = 13,
  ICON_LIVESTREAMING = 15,
  COVER_SLIDE_SHOW = 18,
  COVER_SPLASH_SCREEN = 19,
  THUMBNAIL_NOTIFICATION_BROADCAST = 20,
  COVER_HOME_PROGRAM = 21,
  COVER_HOME_PROGRAM_EPISODE = 22,
  BUMPER_CONTENT_LIVESTREAM = 24,
  HIGHLIGHT_COVER = 25,
}

export enum ControlStatus {
  UNKNOWN = 0,
  ACTIVE,
  INACTIVE,
  PENDING = 3,
  LOCKED = 4,
  DRAFT = 5,
  REJECT = 6,
}

export enum SortBy {
  LATEST = 'latest',
  EARLIEST = 'earliest',
  MOST_LIKE = 'most-like',
  MOST_DISLIKE = 'most-dislike',
  MOST_SHARE = 'most-share',
}

export enum LiveStreamType {
  UNKNOWN = 0,
  SMART_TV = 1,
  MOBILE = 2,
}

export enum HomeSectionType {
  UNKNOWN_HOME_SECTION_TYPE = 0,
  HIGHLIGHT_PROGRAM = 1,
  POSTER_PROGRAM = 2,
  RATING_PROGRAM = 3,
  EPISODE_PROGRAM = 4,
  LARGE_POSTER_PROGRAM = 5,
  POSTER_PROGRAM_ML = 6,
  PROGRAM_COVERAGE = 7,
}

export enum ProgramType {
  UNKNOWN_PROGRAM_TYPE = 0,
  SEASON = 1,
  SINGLE = 2,
}

export enum ProgramStep {
  GENERAL,
  VIDEO,
  OTHER,
  COVER,
  PUBLICATION,
}

export enum CategoryInformation {
  UNKNOWN = 0,
  CAST = 1,
  FIGURE = 2,
  DIRECTOR = 3,
  OTHER = 4,
  SUBJECT = 5,
  GUEST_SPEAKER = 6,
}

export enum SubtitleType {
  UNKNOWN_SUBTITLE_TYPE,
  INDONESIA,
  ENGLISH,
  SDH,
}
