import { PRIVACY_POLICY } from '@/configs/endpoint'
import { BaseResponse } from '@/types/common'
import { api } from '@/utils/api'

export type GetDetailPrivacyPolicyResponse = BaseResponse<{
  value: {
    id: string
    en: string
  }
  version: string
}>

export const getDetailPrivacyPolicy = async () => {
  const response = await api<GetDetailPrivacyPolicyResponse>({
    url: PRIVACY_POLICY,
    method: 'GET',
  })

  return response.data
}

export const getDetailPrivacyPolicyKey = 'GET_DETAIL_PRIVACY_POLICY'
