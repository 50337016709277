import { CSSObject } from '@emotion/react'
import styled from '@emotion/styled'
import { configStyledOptions } from '@nbsdev/naini-react'
import { FC, HTMLAttributes } from 'react'

type StyledProps = {
  width?: number
  height?: number
  fontSize?: number
  color?: string
}

const Styled = styled(
  'button',
  configStyledOptions<StyledProps>(['width', 'height'])
)<StyledProps>(
  ({ width = 24, height = 24, fontSize = 16, color = '#262626' }): CSSObject => ({
    width: `${width}px`,
    height: `${height}px`,
    fontSize: `${fontSize}px`,
    color: color,
    cursor: 'pointer',
  })
)

export type IconButtonProps = HTMLAttributes<HTMLElement> & StyledProps

export const IconButton: FC<IconButtonProps> = ({ width, height, fontSize, color = 'inherit', onClick, ...props }) => {
  return (
    <Styled
      type='button'
      width={width}
      height={height}
      fontSize={fontSize}
      color={color}
      onClick={onClick}
    >
      <i {...props} />
    </Styled>
  )
}

IconButton.displayName = 'IconButton'
