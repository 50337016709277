import { CSSObject } from '@emotion/react'
import styled from '@emotion/styled'
import { configStyledOptions, getSize, getVariant, Size, Variant } from '@nbsdev/naini-react'
import { Link } from '@tanstack/react-router'

export type ButtonLinkProps = {
  buttonSize?: Size
  variant?: Variant
  outline?: boolean
}

export const ButtonLink = styled(
  Link,
  configStyledOptions<ButtonLinkProps>(['size', 'variant', 'outline'])
)<ButtonLinkProps>(({ theme, variant = 'primary', buttonSize = 'md', outline = false }): CSSObject => {
  return {
    ...getSize(buttonSize!),
    ...getVariant(theme.button!.colors, variant!, false, outline),
    borderRadius: '8px',
    borderStyle: 'solid',
    borderWidth: '1px',
    boxShadow: theme.shadow.sm,
    cursor: 'pointer',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    fontFamily: theme.font.sans,
    fontStyle: 'normal',
    fontWeight: 500,
    ':disabled': {
      ...getVariant(theme.button!.colors, 'disabled', false, outline),
      cursor: 'not-allowed',
    },
    ':hover:not([disabled])': {
      ...getVariant(theme.button!.colors, variant!, true, outline),
    },
    ':active:not([disabled])': {
      ...getVariant(theme.button!.colors, variant!, true, outline),
    },
    '>:not([hidden])~:not([hidden])': {
      marginLeft: '8px',
    },
  }
})

ButtonLink.displayName = 'ButtonLink'
