import { create } from 'zustand'
import { persist } from 'zustand/middleware'

type ForgotPasswordItem = {
  email: string
  retries: number
  retryTime: string
}

type AuthState = {
  forgotPasswordData: ForgotPasswordItem[]
  setForgotPasswordData: (value: ForgotPasswordItem[]) => void
}

export const useAuthStore = create<AuthState>()(
  persist(
    (set, get) => ({
      forgotPasswordData: get()?.forgotPasswordData || [],
      setForgotPasswordData: (forgotPasswordData: ForgotPasswordItem[]) => {
        set((state) => ({ ...state, forgotPasswordData }))
      },
    }),
    {
      name: 'auth',
    }
  )
)
