import { queryOptions, useQuery, useSuspenseQuery } from '@tanstack/react-query'

import { getDetailTermCondition as queryFn, getDetailTermConditionKey } from '@/services'

export const useGetDetailTermCondition = () => {
  const {
    refetch: getDetailTermCondition,
    data: getDetailTermConditionData,
    error: getDetailTermConditionError,
    isFetching: getDetailTermConditionIsFetching,
    isPending: getDetailTermConditionIsPending,
    isSuccess: getDetailTermConditionIsSuccess,
    isError: getDetailTermConditionIsError,
  } = useQuery({
    queryFn,
    queryKey: [getDetailTermConditionKey],
    refetchOnMount: false,
    refetchOnReconnect: false,
    refetchOnWindowFocus: false,
    refetchInterval: false,
    refetchIntervalInBackground: false,
    retryOnMount: false,
    gcTime: 0,
  })

  return {
    getDetailTermCondition,
    getDetailTermConditionData,
    getDetailTermConditionError,
    getDetailTermConditionIsFetching,
    getDetailTermConditionIsPending,
    getDetailTermConditionIsSuccess,
    getDetailTermConditionIsError,
  }
}

export const useSuspenseGetDetailTermCondition = () => {
  const { data: getDetailTermConditionData } = useSuspenseQuery(getDetailTermConditionOptions())

  return { getDetailTermConditionData }
}

export const getDetailTermConditionOptions = () =>
  queryOptions({
    queryFn,
    queryKey: [getDetailTermConditionKey],
  })
