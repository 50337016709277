import i18next from 'i18next'
import { initReactI18next } from 'react-i18next'

import auth from './id/auth.json'
import banner from './id/banner.json'
import common from './id/common.json'
import dynamicContent from './id/dynamic-content.json'
import dynamicSplashScreen from './id/dynamic-splash-screen.json'
import form from './id/form.json'
import highlights from './id/highlights.json'
import liveChannel from './id/live-channel.json'
import masterData from './id/master-data.json'
import notificationBroadcast from './id/notification-broadcast.json'
import otherInformation from './id/other-information.json'
import program from './id/program.json'
import programCoverage from './id/program-coverage.json'
import programSubmission from './id/program-submission.json'
import sidebarMenu from './id/sidebar-menu.json'
import slideShow from './id/slide-show.json'
import staticSplashScreen from './id/static-splash-screen.json'
import userManagement from './id/user-management.json'
import validation from './id/validation.json'

i18next.use(initReactI18next).init({
  lng: 'id',
  supportedLngs: ['id'],
  fallbackLng: 'id',
  interpolation: {
    escapeValue: false, // not needed for react as it escapes by default
  },
  resources: {
    id: {
      auth,
      banner,
      common,
      form,
      liveChannel,
      masterData,
      notificationBroadcast,
      otherInformation,
      program,
      sidebarMenu,
      slideShow,
      userManagement,
      validation,
      dynamicSplashScreen,
      staticSplashScreen,
      dynamicContent,
      programSubmission,
      programCoverage,
      highlights,
    },
  },
  defaultNS: 'common',
})

export default i18next
