import { queryOptions, useQuery, useSuspenseQuery } from '@tanstack/react-query'

import {
  getDetailDynamicSplashScreen as queryFn,
  getDetailDynamicSplashScreenKey,
  GetDetailDynamicSplashScreenRequest,
} from '@/services'

export const useGetDetailDynamicSplashScreen = (request: GetDetailDynamicSplashScreenRequest, enabled = true) => {
  const {
    refetch: getDetailDynamicSplashScreen,
    data: getDetailDynamicSplashScreenData,
    error: getDetailDynamicSplashScreenError,
    isFetching: getDetailDynamicSplashScreenIsFetching,
    isPending: getDetailDynamicSplashScreenIsPending,
    isSuccess: getDetailDynamicSplashScreenIsSuccess,
    isError: getDetailDynamicSplashScreenIsError,
  } = useQuery({
    enabled,
    queryFn: () => queryFn(request),
    queryKey: [getDetailDynamicSplashScreenKey, request],
    refetchOnMount: false,
    refetchOnReconnect: false,
    refetchOnWindowFocus: false,
    refetchInterval: false,
    refetchIntervalInBackground: false,
    retryOnMount: false,
    gcTime: 0,
  })

  return {
    getDetailDynamicSplashScreen,
    getDetailDynamicSplashScreenData,
    getDetailDynamicSplashScreenError,
    getDetailDynamicSplashScreenIsFetching,
    getDetailDynamicSplashScreenIsPending,
    getDetailDynamicSplashScreenIsSuccess,
    getDetailDynamicSplashScreenIsError,
  }
}

export const useSuspenseGetDetailDynamicSplashScreen = (request: GetDetailDynamicSplashScreenRequest) => {
  const { data: getDetailDynamicSplashScreenData } = useSuspenseQuery(getDetailDynamicSplashScreenOptions(request))

  return { getDetailDynamicSplashScreenData }
}

export const getDetailDynamicSplashScreenOptions = (request: GetDetailDynamicSplashScreenRequest) =>
  queryOptions({
    queryFn: () => queryFn(request),
    queryKey: [getDetailDynamicSplashScreenKey, request],
  })
