import { queryOptions, useQuery, useSuspenseQuery } from '@tanstack/react-query'

import { getDetailPrivacyPolicy as queryFn, getDetailPrivacyPolicyKey } from '@/services'

export const useGetDetailPrivacyPolicy = () => {
  const {
    refetch: getDetailPrivacyPolicy,
    data: getDetailPrivacyPolicyData,
    error: getDetailPrivacyPolicyError,
    isFetching: getDetailPrivacyPolicyIsFetching,
    isPending: getDetailPrivacyPolicyIsPending,
    isSuccess: getDetailPrivacyPolicyIsSuccess,
    isError: getDetailPrivacyPolicyIsError,
  } = useQuery({
    queryFn,
    queryKey: [getDetailPrivacyPolicyKey],
    refetchOnMount: false,
    refetchOnReconnect: false,
    refetchOnWindowFocus: false,
    refetchInterval: false,
    refetchIntervalInBackground: false,
    retryOnMount: false,
    gcTime: 0,
  })

  return {
    getDetailPrivacyPolicy,
    getDetailPrivacyPolicyData,
    getDetailPrivacyPolicyError,
    getDetailPrivacyPolicyIsFetching,
    getDetailPrivacyPolicyIsPending,
    getDetailPrivacyPolicyIsSuccess,
    getDetailPrivacyPolicyIsError,
  }
}

export const useSuspenseGetDetailPrivacyPolicy = () => {
  const { data: getDetailPrivacyPolicyData } = useSuspenseQuery(getDetailPrivacyPolicyOptions())

  return { getDetailPrivacyPolicyData }
}

export const getDetailPrivacyPolicyOptions = () =>
  queryOptions({
    queryFn,
    queryKey: [getDetailPrivacyPolicyKey],
  })
