import { FC } from 'react'

export const IconSearchNotFound: FC = () => {
  return (
    <svg
      width='90'
      height='90'
      viewBox='0 0 90 90'
      fill='none'
      xmlns='http://www.w3.org/2000/svg'
    >
      <g id='unsubscribe 1'>
        <path
          id='Unsubscribe'
          d='M74.9998 34.7925V30C74.9958 28.6893 74.6472 27.4028 73.989 26.2693C73.3309 25.1359 72.3862 24.1955 71.2498 23.5425V18.75C71.2498 16.7609 70.4596 14.8532 69.0531 13.4467C67.6465 12.0402 65.7389 11.25 63.7498 11.25H26.2498C24.2606 11.25 22.353 12.0402 20.9465 13.4467C19.5399 14.8532 18.7498 16.7609 18.7498 18.75V23.5425C17.6134 24.1955 16.6687 25.1359 16.0105 26.2693C15.3523 27.4028 15.0037 28.6893 14.9998 30V34.7925C13.8634 35.4455 12.9187 36.3859 12.2605 37.5193C11.6023 38.6528 11.2537 39.9393 11.2498 41.25V71.25C11.2498 73.2391 12.0399 75.1468 13.4465 76.5533C14.853 77.9598 16.7606 78.75 18.7498 78.75H71.2498C73.2389 78.75 75.1465 77.9598 76.5531 76.5533C77.9596 75.1468 78.7498 73.2391 78.7498 71.25V41.25C78.7458 39.9393 78.3972 38.6528 77.7391 37.5193C77.0808 36.3859 76.1362 35.4455 74.9998 34.7925ZM22.4998 30H67.4998V33.75H22.4998V30ZM63.7498 18.75V22.5H26.2498V18.75H63.7498ZM55.151 61.0987C55.5092 61.4447 55.7949 61.8585 55.9914 62.316C56.1879 62.7735 56.2914 63.2656 56.2957 63.7635C56.3 64.2614 56.2051 64.7552 56.0166 65.2161C55.828 65.6769 55.5496 66.0956 55.1975 66.4477C54.8454 66.7998 54.4267 67.0783 53.9658 67.2668C53.505 67.4554 53.0112 67.5503 52.5133 67.5459C52.0153 67.5416 51.5233 67.4382 51.0657 67.2416C50.6082 67.0451 50.1944 66.7594 49.8485 66.4013L44.9998 61.5525L40.151 66.4013C39.4437 67.0843 38.4965 67.4623 37.5133 67.4538C36.53 67.4452 35.5895 67.0508 34.8942 66.3556C34.1989 65.6603 33.8045 64.7197 33.796 63.7365C33.7874 62.7533 34.1654 61.806 34.8485 61.0987L39.6973 56.25L34.8485 51.4012C34.1654 50.694 33.7874 49.7467 33.796 48.7635C33.8045 47.7803 34.1989 46.8397 34.8942 46.1444C35.5895 45.4492 36.53 45.0548 37.5133 45.0462C38.4965 45.0377 39.4437 45.4157 40.151 46.0988L44.9998 50.9475L49.8485 46.0988C50.5558 45.4157 51.503 45.0377 52.4863 45.0462C53.4695 45.0548 54.41 45.4492 55.1053 46.1444C55.8006 46.8397 56.195 47.7803 56.2035 48.7635C56.2121 49.7467 55.8341 50.694 55.151 51.4012L50.3023 56.25L55.151 61.0987Z'
          fill='#00A3D8'
        />
      </g>
    </svg>
  )
}
