import parse from 'html-react-parser'
import { DateTime, Duration } from 'luxon'
import sanitizeHtml from 'sanitize-html'

export const epochToFormat = (second: number, format: string) => DateTime.fromSeconds(second).toFormat(format)
export const epochToJsDate = (second: number) => DateTime.fromSeconds(second).toJSDate()
export const formatToEpoch = (date: string, format: string) => DateTime.fromFormat(date, format).toSeconds()
export const formatToJsDate = (date: string, format: string) => DateTime.fromFormat(date, format).toJSDate()
export const jsDateToEpochStart = (date: string, format: string) =>
  DateTime.fromFormat(date, format)
    .set({
      hour: 0,
      minute: 0,
      second: 0,
      millisecond: 0,
    })
    .toSeconds()
    .toFixed(0)
export const jsDateToEpochEnd = (date: string, format: string) =>
  DateTime.fromFormat(date, format)
    .set({
      hour: 23,
      minute: 59,
      second: 59,
      millisecond: 0,
    })
    .toSeconds()
    .toFixed(0)
export const jsDateToEpoch = (date: Date) => DateTime.fromJSDate(date).toSeconds().toFixed(0)
export const jsDateToFormat = (date: Date, format: string) => DateTime.fromJSDate(date).toFormat(format)
export const changeFormat = (date: string, from: string, to: string) => DateTime.fromFormat(date, from).toFormat(to)
export const nowEpoch = () => DateTime.now().toSeconds().toFixed(0)
export const nowFormat = (format: string) => DateTime.now().toFormat(format)
export const nowJsDate = () => DateTime.now().toJSDate()
export const secondsToFormat = (seconds: number, format: string) =>
  Duration.fromObject({ seconds: seconds }).toFormat(format)

export const getNearestScheduleTime = (time: string) => {
  const hour = Number(time.split(':')[0])

  if (hour < 12) {
    return '09:00'
  } else if (hour < 15) {
    return '12:00'
  } else if (hour < 18) {
    return '15:00'
  } else if (hour < 21) {
    return '18:00'
  } else {
    return '21:00'
  }
}

export const formatUrl = (value: string) => {
  if (value.startsWith('https://')) {
    return value.slice(8)
  }

  if (value.startsWith('http://')) {
    return value.slice(7)
  }

  return value
}

export const formatPhoneNumber = (value: string) => {
  if (value.startsWith('0')) {
    return value.slice(1)
  }

  if (value.startsWith('+62')) {
    return value.slice(3)
  }
  return value
}

export const formatAddZeroForUnderTen = (num: number) => {
  return num < 10 ? '0' + num : num.toString()
}

export const formatBytes = (bytes: number, decimals = 2) => {
  if (bytes === 0) return '0 Bytes'

  const k = 1024
  const dm = decimals < 0 ? 0 : decimals
  const sizes = ['Bytes', 'KB', 'MB', 'GB', 'TB', 'PB', 'EB', 'ZB', 'YB']

  const i = Math.floor(Math.log(bytes) / Math.log(k))

  return parseFloat((bytes / Math.pow(k, i)).toFixed(dm)) + ' ' + sizes[i]
}

export type FileSizeUnit = 'B' | 'KB' | 'MB' | 'GB'

export const parseMaxSize = (maxSize: string): number => {
  const sizeRegex = /^(\d+)\s*(B|KB|MB|GB)$/i // Allow optional spaces between the number and unit
  const match = maxSize.match(sizeRegex)

  if (!match) {
    throw new Error(
      'Invalid maxSize format. Use formats like "500 KB", "5 MB", or "1 GB" (case-insensitive, with or without spaces).'
    )
  }

  const sizeValue = parseInt(match[1], 10)
  const sizeUnit = match[2].toUpperCase() as FileSizeUnit

  const unitMultiplier = {
    B: 1,
    KB: 1024,
    MB: 1024 * 1024,
    GB: 1024 * 1024 * 1024,
  }

  return sizeValue * unitMultiplier[sizeUnit]
}

export const formatDynamicDuration = (seconds: number) => {
  seconds = Math.floor(seconds)

  const days = Math.floor(seconds / 86400)
  const hours = Math.floor((seconds % 86400) / 3600)
  const minutes = Math.floor((seconds % 3600) / 60)
  const remainingSeconds = Math.floor(seconds % 60)

  if (seconds >= 86400) {
    // More than or equal to 1 day
    return `${days} hari ${hours} jam`
  } else if (seconds >= 3600) {
    // More than or equal to 1 hour
    return `${hours} jam ${minutes} min`
  } else if (seconds >= 60) {
    // More than or equal to 1 minute
    return `${minutes} min ${remainingSeconds} detik`
  } else {
    // Less than 1 minute
    return `${seconds} detik`
  }
}

export const decodeJWT = (token: string) => {
  const base64Url = token.split('.')[1] // Get the payload part
  const base64 = base64Url.replace(/-/g, '+').replace(/_/g, '/')
  const jsonPayload = decodeURIComponent(
    atob(base64)
      .split('')
      .map((c) => '%' + ('00' + c.charCodeAt(0).toString(16)).slice(-2))
      .join('')
  )

  return JSON.parse(jsonPayload)
}

const sanitizeConfig = {
  allowedTags: ['p', 'a', 'b', 'i', 'em', 'strong', 'li', 'ol', 'ul'],
  allowedAttributes: {
    a: ['href', 'target'],
  },
}

export const parseToHtml = (value: string) => {
  return parse(sanitizeHtml(value, sanitizeConfig))
}
