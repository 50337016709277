import { CSSObject, keyframes } from '@emotion/react'
import styled from '@emotion/styled'
import { FC, SyntheticEvent, useMemo } from 'react'

import defaultImage12 from '/assets/images/default-image-1-2.webp'
import defaultImage34 from '/assets/images/default-image-3-4.webp'
import defaultImage169 from '/assets/images/default-image-16-9.webp'

type StyledProps = {
  width?: number
  height?: number
}

const shimmerKeyframes = keyframes`
    0% {
        background-position: -200% 0;
    }
    100% {
        background-position: 200% 0;
    }
`

const StyledWrapper = styled.div<StyledProps>(
  ({ width = '80px', height = '45px' }): CSSObject => ({
    width,
    height,
    position: 'relative',
    borderRadius: '4px',
    overflow: 'hidden',
    background: `linear-gradient(
      90deg,
      #f0f0f0 25%,
      #e0e0e0 50%,
      #f0f0f0 75%
    )`,
    backgroundSize: '200% 100%',
    animation: `${shimmerKeyframes} 1.5s infinite linear`,
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
  })
)

const StyledImage = styled.img<StyledProps>(
  ({ width = '100px', height = '100px' }): CSSObject => ({
    width,
    height,
    objectFit: 'cover',
    objectPosition: 'center',
    borderRadius: '4px',
    position: 'absolute',
    top: 0,
    left: 0,
    opacity: 0,
    transition: 'opacity 0.3s ease-in-out',
  })
)

export type ImgProps = {
  src: string
  alt: string
  defaultImage?: '1:2' | '3:4' | '16:9'
  css?: CSSObject
} & StyledProps

export const Img: FC<ImgProps> = ({ src, alt, defaultImage = '16:9', ...styledProps }) => {
  const handleImageLoad = (e: SyntheticEvent<HTMLImageElement>) => {
    const image = e.currentTarget
    image.style.opacity = '1' // Show the image once it has loaded
  }

  const srcImage = useMemo(() => {
    if (src.length) {
      return src
    }
    if (defaultImage === '1:2') {
      return defaultImage12
    }
    if (defaultImage === '3:4') {
      return defaultImage34
    }
    return defaultImage169
  }, [src])

  return (
    <StyledWrapper {...styledProps}>
      <StyledImage
        src={srcImage}
        alt={alt}
        onLoad={handleImageLoad}
        {...styledProps}
      />
    </StyledWrapper>
  )
}
