import { DYNAMIC_SPLASH_SCREEN_DETAIL } from '@/configs/endpoint'
import { BaseResponse, FileData, ModifiedBy, TimestampAndVersion } from '@/types/common'
import { api } from '@/utils/api'

export type GetDetailDynamicSplashScreenRequestParams = {
  xid: string
}

export type GetDetailDynamicSplashScreenRequest = {
  params: GetDetailDynamicSplashScreenRequestParams
}

export type GetDetailDynamicSplashScreenResponse = BaseResponse<
  {
    xid: string
    title: string
    startDate: string
    endDate: string
    cover: FileData
    statusId: number
    modifiedBy: ModifiedBy
  } & TimestampAndVersion
>

export const getDetailDynamicSplashScreen = async (request: GetDetailDynamicSplashScreenRequest) => {
  const response = await api<GetDetailDynamicSplashScreenResponse, GetDetailDynamicSplashScreenRequestParams>({
    ...request,
    url: DYNAMIC_SPLASH_SCREEN_DETAIL,
    method: 'GET',
  })

  return response.data
}

export const getDetailDynamicSplashScreenKey = 'GET_DETAIL_DYNAMIC_SPLASH_SCREEN'
