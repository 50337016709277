import { SUBJECT_DETAIL } from '@/configs/endpoint'
import { BaseResponse, TimestampAndVersion } from '@/types/common'
import { api } from '@/utils/api'

export type GetDetailSubjectRequestParams = {
  xid: string
}

export type GetDetailSubjectRequest = {
  params: GetDetailSubjectRequestParams
}

export type GetDetailSubjectResponse = BaseResponse<
  {
    xid: string
    name: string
  } & TimestampAndVersion
>

export const getDetailSubject = async (request: GetDetailSubjectRequest) => {
  const response = await api<GetDetailSubjectResponse, GetDetailSubjectRequestParams>({
    ...request,
    url: SUBJECT_DETAIL,
    method: 'GET',
  })

  return response.data
}

export const getDetailSubjectKey = 'GET_DETAIL_SUBJECT'
