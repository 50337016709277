import { create } from 'zustand'

interface ProgramActiveState {
  loading: boolean
  setLoading: (loading: boolean) => void
  selected: string
  setSelected: (pathname: string) => void
  openSidePanelDetail: string
  setOpenSidePanelDetail: (openSidePanelDetail: string) => void
  openSidePanelListVideo: boolean
  setOpenSidePanelListVideo: (openSidePanelListVideo: boolean) => void
  importKumulateTitle: string
  setImportKumulateTitle: (KumulateTitle: string) => void
  detailVideo: string
  setDetailVideo: (KumulateTitle: string) => void
  modalDeleteVideoEpisode: string
  setModalDeleteVideoEpisode: (KumulateTitle: string) => void
  modalDeleteProgram: {
    xid: string
    title: string
  }
  setModalDeleteProgram: (xid: string, title: string) => void
  version: string
  setVersion: (version: string) => void
  statusId: string
  setStatusId: (statusId: string) => void
}

export const useProgramActiveStore = create<ProgramActiveState>()((set) => ({
  loading: false,
  setLoading: (loading: boolean) => {
    set((state) => ({ ...state, loading }))
  },
  selected: '',
  setSelected: (selected: string) => {
    set((state) => ({ ...state, selected }))
  },
  openSidePanelDetail: '',
  setOpenSidePanelDetail: (openSidePanelDetail: string) => {
    set((state) => ({ ...state, openSidePanelDetail }))
  },
  openSidePanelListVideo: false,
  setOpenSidePanelListVideo: (openSidePanelListVideo: boolean) => {
    set((state) => ({ ...state, openSidePanelListVideo }))
  },
  importKumulateTitle: '',
  setImportKumulateTitle: (importKumulateTitle: string) => {
    set((state) => ({ ...state, importKumulateTitle }))
  },
  detailVideo: '',
  setDetailVideo: (detailVideo: string) => {
    set((state) => ({ ...state, detailVideo }))
  },
  modalDeleteVideoEpisode: '',
  setModalDeleteVideoEpisode: (modalDeleteVideoEpisode: string) => {
    set((state) => ({ ...state, modalDeleteVideoEpisode }))
  },
  modalDeleteProgram: {
    xid: '',
    title: '',
  },
  setModalDeleteProgram: (xid: string, title: string) => {
    set((state) => ({ ...state, modalDeleteProgram: { xid, title } }))
  },
  version: '0',
  setVersion: (version: string) => {
    set((state) => ({ ...state, version }))
  },
  statusId: '',
  setStatusId: (statusId: string) => {
    set((state) => ({ ...state, statusId }))
  },
}))
