import axios from 'axios'

import { REFRESH_TOKEN } from '@/configs/endpoint'
import { apiBaseUrl } from '@/configs/env'
import { BaseResponse } from '@/types/common'
import { getUserRefreshToken, removeUserToken, setUserAccessToken, setUserRefreshToken } from '@/utils/app'

export type RefreshTokenResponse = BaseResponse<{
  accessSession: {
    token: string
    expiredAt: number
  }
  refreshSession: {
    token: string
    expiredAt: number
  }
}>

export const refreshToken = async () => {
  const client = axios.create({
    baseURL: apiBaseUrl,
  })

  try {
    const response = await client.request<RefreshTokenResponse>({
      url: REFRESH_TOKEN,
      method: 'POST',
      headers: {
        Authorization: `Bearer ${getUserRefreshToken()}`,
        'Content-Type': 'application/json',
      },
      data: {
        notification: {
          channel: 0,
          token: '',
        },
        deviceId: '1',
      },
    })

    setUserAccessToken(response.data.data.accessSession.token, response.data.data.accessSession.expiredAt)
    setUserRefreshToken(response.data.data.refreshSession.token, response.data.data.refreshSession.expiredAt)

    return true
  } catch (e) {
    removeUserToken()

    throw e
  }
}

export const refreshTokenKey = 'REFRESH_TOKEN'
