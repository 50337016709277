import { CSSObject } from '@emotion/react'
import styled from '@emotion/styled'
import { Button, configStyledOptions, getSize, getVariant, Size } from '@nbsdev/naini-react'
import { FC } from 'react'
import { useTranslation } from 'react-i18next'

import { getFileExtension } from '@/utils/string'

type StyledProps = {
  buttonSize?: Size
  padding?: CSSObject['padding']
  height?: CSSObject['height']
  fontSize?: CSSObject['fontSize']
}

const Styled = styled(
  Button,
  configStyledOptions<StyledProps>(['size', 'variant', 'outline'])
)<StyledProps>(({
  theme,
  variant = 'primary',
  buttonSize = 'md',
  outline = false,
  padding,
  height,
  fontSize,
}): CSSObject => {
  return {
    ...getSize(buttonSize!),
    ...getVariant(theme.button!.colors, variant!, false, outline),
    padding: padding,
    height: height,
    fontSize: fontSize,
    borderRadius: '8px',
    borderStyle: 'solid',
    borderWidth: '1px',
    boxShadow: theme.shadow.sm,
    cursor: 'pointer',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    fontFamily: theme.font.sans,
    fontStyle: 'normal',
    fontWeight: 500,
    ':disabled': {
      ...getVariant(theme.button!.colors, 'disabled', false, outline),
      cursor: 'not-allowed',
    },
    ':hover:not([disabled])': {
      ...getVariant(theme.button!.colors, variant!, true, outline),
    },
    ':active:not([disabled])': {
      ...getVariant(theme.button!.colors, variant!, true, outline),
    },
    '>:not([hidden])~:not([hidden])': {
      marginLeft: '8px',
    },
  }
})

type ButtonPreviewProps = {
  icon?: string
  url: string
  fileName: string
  downloadText?: string
  preview?: boolean
  closeOnBackdropClick?: boolean
}

export const ButtonDownload: FC<StyledProps & ButtonPreviewProps> = ({
  icon = 'ri-download-cloud-fill',
  url,
  fileName,
  downloadText,
  ...props
}) => {
  const { t } = useTranslation()

  const nameFile = (url: string) => {
    return `content.${getFileExtension(url)}`
  }

  const handleDownload = () => {
    fetch(url).then((res) => {
      res.blob().then((b) => {
        const a = document.createElement('a')
        a.href = URL.createObjectURL(b)
        a.target = '_blank'
        a.rel = 'noreferrer'

        a.setAttribute('download', !fileName.length ? nameFile(url) : fileName)
        a.click()
        a.remove()
      })
    })
  }

  return (
    <>
      <Styled
        onClick={handleDownload}
        variant='outline'
        outline
        {...props}
      >
        <span
          className={icon}
          css={{ fontSize: '14px' }}
        />
        <span>{downloadText ?? t('common:download.title')}</span>
      </Styled>
    </>
  )
}

ButtonDownload.displayName = 'ButtonDownload'
