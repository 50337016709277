import { CATEGORY_DETAIL } from '@/configs/endpoint'
import { BaseResponse, FileData, TimestampAndVersion } from '@/types/common'
import { api } from '@/utils/api'

export type CategoryItem = {
  xid: string
  name: string
  description: string
  cover: FileData
} & TimestampAndVersion

export type GetDetailCategoryRequestParams = {
  xid: string
}

export type GetDetailCategoryRequest = {
  params: GetDetailCategoryRequestParams
}

export type GetDetailCategoryResponse = BaseResponse<
  {
    xid: string
    name: string
    description: string
    cover: FileData
  } & TimestampAndVersion
>

export const getDetailCategory = async (request: GetDetailCategoryRequest) => {
  const response = await api<GetDetailCategoryResponse, GetDetailCategoryRequestParams>({
    ...request,
    url: CATEGORY_DETAIL,
    method: 'GET',
  })

  return response.data
}

export const getDetailCategoryKey = 'GET_DETAIL_CATEGORY'
