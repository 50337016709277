import { FEEDBACK_URL } from '@/configs/endpoint'
import { BaseResponse, ModifiedBy, TimestampAndVersion } from '@/types/common'
import { api } from '@/utils/api'

export type GetFeedbackUrlResponse = BaseResponse<
  {
    url: string
    urlSmartTv: string
    modifiedBy: ModifiedBy
  } & TimestampAndVersion
>

export const getFeedbackUrl = async () => {
  const response = await api<GetFeedbackUrlResponse>({
    url: FEEDBACK_URL,
    method: 'GET',
  })

  return response.data
}

export const getFeedbackUrlKey = 'GET_FEEDBACK_URL'
