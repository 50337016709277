import { queryOptions, useQuery, useSuspenseQuery } from '@tanstack/react-query'

import { getDetailDirector as queryFn, getDetailDirectorKey, GetDetailDirectorRequest } from '@/services'

export const useGetDetailDirector = (request: GetDetailDirectorRequest, enabled = true) => {
  const {
    refetch: getDetailDirector,
    data: getDetailDirectorData,
    error: getDetailDirectorError,
    isFetching: getDetailDirectorIsFetching,
    isPending: getDetailDirectorIsPending,
    isSuccess: getDetailDirectorIsSuccess,
    isError: getDetailDirectorIsError,
  } = useQuery({
    enabled,
    queryFn: () => queryFn(request),
    queryKey: [getDetailDirectorKey, request],
    refetchOnMount: false,
    refetchOnReconnect: false,
    refetchOnWindowFocus: false,
    refetchInterval: false,
    refetchIntervalInBackground: false,
    retryOnMount: false,
    gcTime: 0,
  })

  return {
    getDetailDirector,
    getDetailDirectorData,
    getDetailDirectorError,
    getDetailDirectorIsFetching,
    getDetailDirectorIsPending,
    getDetailDirectorIsSuccess,
    getDetailDirectorIsError,
  }
}

export const useSuspenseGetDetailDirector = (request: GetDetailDirectorRequest) => {
  const { data: getDetailDirectorData } = useSuspenseQuery(getDetailDirectorOptions(request))

  return { getDetailDirectorData }
}

export const getDetailDirectorOptions = (request: GetDetailDirectorRequest) =>
  queryOptions({
    queryFn: () => queryFn(request),
    queryKey: [getDetailDirectorKey, request],
  })
