import { CSSObject } from '@emotion/react'
import styled from '@emotion/styled'
import { flip, useClick, useDismiss, useFloating, useInteractions } from '@floating-ui/react'
import {
  Button,
  configStyledOptions,
  FormDropdownActions,
  FormDropdownGroup,
  FormDropdownIcon,
  FormDropdownLabel,
  FormDropdownMenu,
  FormDropdownPlaceholder,
  FormError,
  FormGroup,
  FormInput,
  FormInputGroup,
  FormInputGroupButton,
  FormLabel,
  FormRadioDropdownInput,
  FormRadioDropdownLabel,
  FormRadioDropdownText,
} from '@nbsdev/naini-react'
import clsx from 'clsx'
import { ChangeEvent, FC, useEffect, useMemo, useRef, useState } from 'react'

import { DropdownItem } from '@/types/common'

type StyledProps = {
  width?: CSSObject['width']
}

const Styled = styled(
  'div',
  configStyledOptions(['width'])
)<StyledProps>(
  ({ width = '100%' }): CSSObject => ({
    width,
  })
)

export type FormRadioDropdownProps = {
  items: DropdownItem[]
  selected?: DropdownItem
  label?: string
  placeholder?: string
  error?: string
  required?: boolean
  readonly?: boolean
  disabled?: boolean
  searchable?: boolean
  onSearch?: (value: string) => void
  onChange?(value: DropdownItem): void
  onApply?(value: DropdownItem): void
} & StyledProps

export const FormRadioDropdown: FC<FormRadioDropdownProps> = ({
  items,
  selected,
  label,
  placeholder = 'Choose',
  error,
  required = false,
  readonly = false,
  disabled = false,
  searchable = false,
  onSearch,
  onChange,
  onApply,
  ...styledProps
}) => {
  const [checked, setChecked] = useState<string>()
  const [isOpen, setIsOpen] = useState(false)
  const [search, setSearch] = useState('')
  const searchInputRef = useRef<HTMLInputElement>(null)

  const { refs, floatingStyles, context } = useFloating({
    placement: 'bottom-start',
    strategy: 'absolute',
    open: isOpen,
    onOpenChange: !readonly ? setIsOpen : () => {},
    middleware: [flip()],
  })

  const { getReferenceProps, getFloatingProps } = useInteractions([
    useClick(context),
    useDismiss(context, { ancestorScroll: true }),
  ])

  const selectedExist = useMemo(() => {
    const find = items.find((item) => item.id === selected?.id)

    return !!find
  }, [selected])

  const handleChange = (e: ChangeEvent<HTMLInputElement>) => {
    setChecked(e.target.value)

    if (!onApply && onChange) {
      const find = items.find((item) => item.id === e.target.value)

      if (find) {
        onChange(find)
      }

      setSearch('')
      setIsOpen(false)
    }
  }

  const handleChangeSearch = (e: ChangeEvent<HTMLInputElement>) => {
    setSearch(e.target.value)
  }

  const handleClearSearch = () => {
    setSearch('')
  }

  const handleApply = () => {
    const find = items.find((item) => item.id === checked)

    if (onApply && find) {
      onApply(find)

      setSearch('')
      setIsOpen(false)
    }
  }

  const handleReset = () => {
    setChecked(selected?.id)
    setSearch('')
    setIsOpen(false)
  }

  useEffect(() => {
    setChecked(selected?.id)
  }, [selected])

  useEffect(() => {
    if (onSearch) {
      onSearch(search)
    }
  }, [search])

  useEffect(() => {
    if (isOpen && searchable && searchInputRef.current) {
      searchInputRef.current.focus()
    }
  }, [isOpen])

  return (
    <Styled {...styledProps}>
      <FormGroup>
        {label && (
          <FormLabel
            htmlFor='radio-dropdown'
            className={clsx(required && 'required')}
          >
            {label}
          </FormLabel>
        )}

        <FormDropdownGroup
          type='button'
          id='radio-dropdown'
          ref={refs.setReference}
          {...getReferenceProps()}
          disabled={disabled}
          className={clsx(error && 'error')}
          css={{
            ...(readonly && {
              cursor: 'not-allowed !important',
            }),
          }}
        >
          <FormDropdownLabel css={{ cursor: 'inherit' }}>
            {selected ? selected.label : <FormDropdownPlaceholder>{placeholder}</FormDropdownPlaceholder>}
          </FormDropdownLabel>

          <FormDropdownIcon
            as='div'
            className='ri-arrow-down-s-line'
            css={{ cursor: 'inherit' }}
          />
        </FormDropdownGroup>

        {isOpen && (
          <FormDropdownMenu
            ref={refs.setFloating}
            style={floatingStyles}
            {...getFloatingProps()}
          >
            {(searchable || onSearch) && (
              <FormInputGroup>
                <FormInputGroupButton
                  as='div'
                  className='ri-search-line'
                />

                <FormInput
                  type='text'
                  placeholder='Search'
                  onChange={handleChangeSearch}
                  value={search}
                  ref={searchInputRef}
                />

                {search.length > 0 && (
                  <FormInputGroupButton
                    type='button'
                    className='ri-close-line'
                    onClick={handleClearSearch}
                  />
                )}
              </FormInputGroup>
            )}

            <div css={{ maxHeight: '225px', width: '100%', overflowY: 'auto' }}>
              {!selectedExist && selected && (
                <FormRadioDropdownLabel
                  key={selected.id}
                  htmlFor={selected.id}
                  className={clsx(selected.disabled && 'disabled')}
                  css={(theme) => ({
                    '&.disabled': {
                      cursor: 'not-allowed',
                      pointerEvents: 'auto',
                      color: theme.form.inputTextColor,
                    },
                  })}
                >
                  <FormRadioDropdownInput
                    type='radio'
                    id={selected.id}
                    name='radio'
                    value={selected.id}
                    checked={checked === selected.id}
                    onChange={handleChange}
                    disabled={selected.disabled}
                  />

                  <FormRadioDropdownText>{selected.label}</FormRadioDropdownText>
                </FormRadioDropdownLabel>
              )}

              {items
                .filter((f) => (onSearch ? f : f.label.includes(search)))
                .filter((f) => (!selectedExist ? f.id !== selected?.id : f))
                .map((item) => (
                  <FormRadioDropdownLabel
                    key={item.id}
                    htmlFor={item.id}
                    className={clsx(item.disabled && 'disabled')}
                    css={(theme) => ({
                      '&.disabled': {
                        cursor: 'not-allowed',
                        pointerEvents: 'auto',
                        color: theme.form.inputTextColor,
                      },
                    })}
                  >
                    <FormRadioDropdownInput
                      type='radio'
                      id={item.id}
                      name='radio'
                      value={item.id}
                      checked={checked === item.id}
                      onChange={handleChange}
                      disabled={item.disabled}
                    />

                    <FormRadioDropdownText>{item.label}</FormRadioDropdownText>
                  </FormRadioDropdownLabel>
                ))}
            </div>

            {onApply && (
              <FormDropdownActions>
                <Button
                  type='button'
                  variant='outline'
                  onClick={handleReset}
                >
                  Reset
                </Button>

                <Button
                  type='button'
                  variant='primary'
                  onClick={handleApply}
                >
                  Apply
                </Button>
              </FormDropdownActions>
            )}
          </FormDropdownMenu>
        )}

        {error && <FormError>{error}</FormError>}
      </FormGroup>
    </Styled>
  )
}
