import { LIVE_STREAMS_DETAIL } from '@/configs/endpoint'
import { ControlStatus } from '@/configs/enum'
import { BaseResponse, FileData, ModifiedBy, TimestampAndVersion } from '@/types/common'
import { api } from '@/utils/api'

export type GetDetailLiveStreamsRequestParams = {
  xid: string
}

export type GetDetailLiveStreamsRequest = {
  params: GetDetailLiveStreamsRequestParams
}

export type GetDetailLiveStreamsResponse = BaseResponse<
  {
    xid: string
    title: string
    cover: FileData | null
    icon: FileData | null
    bumperContent: FileData | null
    description: string
    descriptionTvAndWeb: string
    urlBumperLiveStream: string
    statusId: ControlStatus
    modifiedBy: ModifiedBy
  } & TimestampAndVersion
>

export const getDetailLiveStreams = async (request: GetDetailLiveStreamsRequest) => {
  const response = await api<GetDetailLiveStreamsResponse, GetDetailLiveStreamsRequestParams>({
    ...request,
    url: LIVE_STREAMS_DETAIL,
    method: 'GET',
  })

  return response.data
}

export const getDetailLiveStreamsKey = 'GET_DETAIL_LIVE_STREAMS'
