import { CSSObject } from '@emotion/react'
import styled from '@emotion/styled'
import { Table, TableBody, TableCell, TableHead, TableHeadCell, TableRow } from '@nbsdev/naini-react'
import { useCallback } from 'react'
import { DragDropContext, Draggable, Droppable, DropResult } from 'react-beautiful-dnd'
import { useTranslation } from 'react-i18next'

import { Dropdown, DropdownItem } from '../Dropdown'

const Styled = styled('div')({
  width: '100%',
  overflow: 'auto',
})

export type TableDndData = {
  xid: string
  [key: string]: any
}

export type TableDndColumn<K extends keyof TableDndData> = {
  key: K
  title: string
  width?: CSSObject['width']
}

export type TableDndComponentProps<K extends keyof TableDndData> = {
  columns: TableDndColumn<K>[]
  items: TableDndData[]
  actions?: (item: TableDndData) => DropdownItem[]
  perPage?: number
  currentPage?: number
  withoutNumber?: boolean
  onSort?: (item: TableDndData[]) => void
}

export const TableDndComponent = <K extends keyof TableDndData>({
  columns,
  items,
  actions,
  onSort,
}: TableDndComponentProps<K>) => {
  const { t } = useTranslation()

  const handleOnDragEnd = (result: DropResult) => {
    const { destination, source } = result

    if (!destination) return // Dropped outside the list

    if (destination.index === source.index) return // No change in position

    const updatedItems = Array.from(items)
    const [movedItem] = updatedItems.splice(source.index, 1)
    updatedItems.splice(destination.index, 0, movedItem)

    onSort!(updatedItems)
  }

  const dropdownItems = useCallback((item: TableDndData) => (actions ? actions(item) : []), [actions])

  return (
    <Styled>
      <Table>
        <TableHead>
          <TableRow>
            <TableHeadCell width={0} />

            {columns.map((col) => (
              <TableHeadCell
                key={col.key}
                width={col.width}
              >
                {col.title}
              </TableHeadCell>
            ))}

            {actions && actions.length > 0 && <TableHeadCell width={0}>{t('common:actions')}</TableHeadCell>}
          </TableRow>
        </TableHead>

        <DragDropContext onDragEnd={handleOnDragEnd}>
          <Droppable droppableId='table'>
            {({ droppableProps, innerRef, placeholder }) => (
              <TableBody
                ref={innerRef}
                {...droppableProps}
              >
                {items.map((item, index) => (
                  <Draggable
                    key={item.xid}
                    draggableId={item.xid}
                    index={index}
                  >
                    {({ dragHandleProps, draggableProps, innerRef }) => (
                      <TableRow
                        ref={innerRef}
                        {...dragHandleProps}
                        {...draggableProps}
                        style={{
                          ...draggableProps.style,
                        }}
                      >
                        <TableCell width={0}>
                          <i className='ri-menu-fill' />
                        </TableCell>

                        {columns.map((col) => (
                          <TableCell key={col.key}>{item[col.key] || '-'}</TableCell>
                        ))}

                        {actions && (
                          <TableCell
                            className='nowrap'
                            width={0}
                          >
                            <Dropdown items={dropdownItems(item)} />
                          </TableCell>
                        )}
                      </TableRow>
                    )}
                  </Draggable>
                ))}

                {placeholder}
              </TableBody>
            )}
          </Droppable>
        </DragDropContext>
      </Table>
    </Styled>
  )
}
