import { queryOptions, useQuery, useSuspenseQuery } from '@tanstack/react-query'

import { getDetailSubCategory as queryFn, getDetailSubCategoryKey, GetDetailSubCategoryRequest } from '@/services'

export const useGetDetailSubCategory = (request: GetDetailSubCategoryRequest, enabled = true) => {
  const {
    refetch: getDetailSubCategory,
    data: getDetailSubCategoryData,
    error: getDetailSubCategoryError,
    isFetching: getDetailSubCategoryIsFetching,
    isPending: getDetailSubCategoryIsPending,
    isSuccess: getDetailSubCategoryIsSuccess,
    isError: getDetailSubCategoryIsError,
  } = useQuery({
    enabled,
    queryFn: () => queryFn(request),
    queryKey: [getDetailSubCategoryKey, request],
    refetchOnMount: false,
    refetchOnReconnect: false,
    refetchOnWindowFocus: false,
    refetchInterval: false,
    refetchIntervalInBackground: false,
    retryOnMount: false,
    gcTime: 0,
  })

  return {
    getDetailSubCategory,
    getDetailSubCategoryData,
    getDetailSubCategoryError,
    getDetailSubCategoryIsFetching,
    getDetailSubCategoryIsPending,
    getDetailSubCategoryIsSuccess,
    getDetailSubCategoryIsError,
  }
}

export const useSuspenseGetDetailSubCategory = (request: GetDetailSubCategoryRequest) => {
  const { data: getDetailSubCategoryData } = useSuspenseQuery(getDetailSubCategoryOptions(request))

  return { getDetailSubCategoryData }
}

export const getDetailSubCategoryOptions = (request: GetDetailSubCategoryRequest) =>
  queryOptions({
    queryFn: () => queryFn(request),
    queryKey: [getDetailSubCategoryKey, request],
  })
