import { FC } from 'react'
import Lightbox, { LightboxProps } from 'yet-another-react-lightbox'

import 'yet-another-react-lightbox/styles.css'

type Props = {
  slides: LightboxProps['slides']
  isOpen: boolean
  closeOnBackdropClick?: boolean
  onClose?(): void
}

export const ImagePreview: FC<Props> = ({ slides, isOpen, closeOnBackdropClick = true, onClose }) => {
  return (
    <Lightbox
      open={isOpen}
      close={onClose}
      slides={slides}
      render={{ buttonPrev: () => null, buttonNext: () => null }}
      carousel={{ finite: true, padding: 32 }}
      controller={{ closeOnBackdropClick }}
      styles={{
        container: { backgroundColor: 'rgba(38, 38, 38, 40%)' },
      }}
    />
  )
}
