import { CSSObject } from '@emotion/react'
import styled from '@emotion/styled'

export const AuthWrapper = styled('div')(
  ({ theme }): CSSObject => ({
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'center',
    alignItems: 'center',
    fontFamily: theme.font.sans,
    width: '100vw',
    minHeight: '100vh',
  })
)
AuthWrapper.displayName = 'AuthWrapper'
