import { CSSObject } from '@emotion/react'
import styled from '@emotion/styled'
import { AlertVariant, configStyledOptions } from '@nbsdev/naini-react'

export type ModalIconProps = {
  width?: number
  height?: number
  variant?: AlertVariant
}

export const ModalIcon = styled(
  'i',
  configStyledOptions<ModalIconProps>(['width', 'height'])
)<ModalIconProps>(
  ({ theme, width = 80, height = 80, variant = 'info' }): CSSObject => ({
    fontSize: `${width}px`,
    lineHeight: `${height}px`,
    width: '100%',
    textAlign: 'center',
    color: theme.alert.colors[variant!].main,
  })
)

ModalIcon.displayName = 'ModalIcon'
