import { USERS_CMS_DETAIL } from '@/configs/endpoint'
import { BaseResponse } from '@/types/common'
import { api } from '@/utils/api'

import { UsersCmsItem } from './get-list-users-cms'

export type GetDetailUsersCmsRequestParams = {
  xid: string
}

export type GetDetailUsersCmsRequest = {
  params: GetDetailUsersCmsRequestParams
}

export type GetDetailUsersCmsResponse = BaseResponse<UsersCmsItem>

export const getDetailUsersCms = async (request: GetDetailUsersCmsRequest) => {
  const response = await api<GetDetailUsersCmsResponse, GetDetailUsersCmsRequestParams>({
    ...request,
    url: USERS_CMS_DETAIL,
    method: 'GET',
  })

  return response.data
}

export const getDetailUsersCmsKey = 'GET_DETAIL_USERS_CMS'
