import { FC } from 'react'

import { IconSpinner } from '@/components'

export const LoadingSpinner: FC = () => {
  return (
    <div
      css={{
        height: '100vh',
        textAlign: 'center',
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        justifyContent: 'center',
      }}
    >
      <IconSpinner />
    </div>
  )
}
