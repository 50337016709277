import { CSSObject } from '@emotion/react'
import styled from '@emotion/styled'
import { configStyledOptions, FlexColumn, Typography } from '@nbsdev/naini-react'
import { FC } from 'react'
import { useTranslation } from 'react-i18next'

const Styled = styled(
  'div',
  configStyledOptions([])
)(
  (): CSSObject => ({
    display: 'flex',
    textAlign: 'center',
    flexDirection: 'column',
    justifyContent: 'center',
    alignItems: 'center',
    padding: '120px 24px',
    width: '100%',
    '>:not([hidden])~:not([hidden])': {
      marginTop: '16px',
    },
  })
)

export type EmptyDataProps = {
  title: string
}

export const EmptyData: FC<EmptyDataProps> = ({ title = '' }) => {
  const { t } = useTranslation()

  return (
    <Styled>
      <FlexColumn
        justifyContent='center'
        alignItems='center'
        space={8}
      >
        <Typography
          size='xl'
          weight='bold'
        >
          {t('common:dataEmpty.title')}
        </Typography>
      </FlexColumn>

      <Typography
        size='sm'
        css={{
          width: '100%',
          '@media (min-width: 1024px)': {
            width: '432px',
          },
        }}
      >
        {t('common:dataEmpty.description', { title })}
      </Typography>
    </Styled>
  )
}
