import { queryOptions, useQuery, useSuspenseQuery } from '@tanstack/react-query'

import {
  getDetailProgramSubmissionGeneralInformation as queryFn,
  getDetailProgramSubmissionGeneralInformationKey,
  GetDetailProgramSubmissionGeneralInformationRequest,
} from '@/services'

export const useGetDetailSubmissionGeneralInformation = (
  request: GetDetailProgramSubmissionGeneralInformationRequest,
  enabled = true
) => {
  const {
    refetch: getDetailProgramSubmissionGeneralInformation,
    data: getDetailProgramSubmissionGeneralInformationData,
    error: getDetailProgramSubmissionGeneralInformationError,
    isFetching: getDetailProgramSubmissionGeneralInformationIsFetching,
    isPending: getDetailProgramSubmissionGeneralInformationIsPending,
    isSuccess: getDetailProgramSubmissionGeneralInformationIsSuccess,
    isError: getDetailProgramSubmissionGeneralInformationIsError,
  } = useQuery({
    enabled,
    queryFn: () => queryFn(request),
    queryKey: [getDetailProgramSubmissionGeneralInformationKey, request],
    refetchOnMount: false,
    refetchOnReconnect: false,
    refetchOnWindowFocus: false,
    refetchInterval: false,
    refetchIntervalInBackground: false,
    retryOnMount: false,
    gcTime: 0,
  })

  return {
    getDetailProgramSubmissionGeneralInformation,
    getDetailProgramSubmissionGeneralInformationData,
    getDetailProgramSubmissionGeneralInformationError,
    getDetailProgramSubmissionGeneralInformationIsFetching,
    getDetailProgramSubmissionGeneralInformationIsPending,
    getDetailProgramSubmissionGeneralInformationIsSuccess,
    getDetailProgramSubmissionGeneralInformationIsError,
  }
}

export const useSuspenseGetDetailProgramSubmissionGeneralInformation = (
  request: GetDetailProgramSubmissionGeneralInformationRequest
) => {
  const { data: getDetailProgramSubmissionGeneralInformationData } = useSuspenseQuery(
    getDetailProgramSubmissionGeneralInformationOptions(request)
  )

  return { getDetailProgramSubmissionGeneralInformationData }
}

export const getDetailProgramSubmissionGeneralInformationOptions = (
  request: GetDetailProgramSubmissionGeneralInformationRequest
) =>
  queryOptions({
    queryFn: () => queryFn(request),
    queryKey: [getDetailProgramSubmissionGeneralInformationKey, request],
  })
