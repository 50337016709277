import styled, { CSSObject } from '@emotion/styled'
import { configStyledOptions } from '@nbsdev/naini-react'

export const CardBodyForm = styled(
  'div',
  configStyledOptions([])
)(
  ({ theme }): CSSObject => ({
    fontFamily: theme.font.sans,
    padding: '24px',
    width: '100%',
  })
)

CardBodyForm.displayName = 'CardBodyForm'
