import { CSSObject } from '@emotion/react'
import styled from '@emotion/styled'
import { configStyledOptions } from '@nbsdev/naini-react'
import Quill from 'quill'
import { forwardRef, useEffect, useLayoutEffect, useRef } from 'react'

import 'quill/dist/quill.snow.css'

const AlignStyle = Quill.import('attributors/style/align') as string
const BackgroundStyle = Quill.import('attributors/style/background') as string
const ColorStyle = Quill.import('attributors/style/color') as string
const DirectionStyle = Quill.import('formats/direction') as string
const FontStyle = Quill.import('attributors/style/font') as string
const SizeStyle = Quill.import('attributors/style/size') as string

Quill.register(AlignStyle, true)
Quill.register(BackgroundStyle, true)
Quill.register(ColorStyle, true)
Quill.register(DirectionStyle, true)
Quill.register(FontStyle, true)
Quill.register(SizeStyle, true)

type StyledProps = {
  height?: CSSObject['height']
}

const Styled = styled(
  'div',
  configStyledOptions(['height'])
)<StyledProps>(
  ({ theme, height = '184px' }): CSSObject => ({
    width: '100%',
    height,
    border: `1px solid ${theme.form.borderColor}`,
    borderRadius: '8px',

    '.ql-toolbar': {
      border: 0,
      borderBottom: `1px solid ${theme.form.borderColor}`,
      padding: '8px 12px',
      height: '40px',
      display: 'flex',
      alignItems: 'center',

      '> :not([hidden]) ~ :not([hidden])': {
        marginLeft: '8px',
        paddingLeft: '8px',
        borderLeft: `1px solid ${theme.form.borderColor}`,
      },

      '.ql-formats': {
        display: 'inline-flex',
        margin: 0,

        '> :not([hidden]) ~ :not([hidden])': {
          marginLeft: '12px',
        },
      },

      ' button': {
        flex: 1,
        height: '20px',
        width: '20px',
        padding: 0,
      },
    },

    '.ql-container': {
      border: 0,
      height: 'calc(100% - 40px)',

      '.ql-editor': {
        padding: '12px 14px',
      },

      '.ql-editor.ql-blank::before': {
        fontStyle: 'normal',
        color: '#D9D9D9',
        fontSize: '14px',
      },
    },
  })
)

export type FormTextEditorProps = {
  value?: string
  placeholder?: string
  toolbar?: Record<string, unknown>
  onChange?(value: string): void
  readOnly?: boolean
} & StyledProps

export const FormTextEditor = forwardRef<Quill, FormTextEditorProps>(
  ({ value, placeholder, onChange, height, readOnly = false, toolbar }, ref) => {
    const containerRef = useRef<HTMLDivElement>(null)
    const valueRef = useRef(value)
    const placeholderRef = useRef(placeholder)
    const onTextChangeRef = useRef(onChange)

    useLayoutEffect(() => {
      valueRef.current = value
    }, [value])

    useLayoutEffect(() => {
      onTextChangeRef.current = onChange
    }, [onChange])

    useEffect(() => {
      const container = containerRef.current

      if (container) {
        const editorContainer = container.appendChild(container.ownerDocument.createElement('div'))
        const quill = new Quill(editorContainer, {
          theme: 'snow',
          placeholder: placeholderRef.current,
          readOnly,
          modules: {
            history: {
              delay: 1000,
              maxStack: 50,
              userOnly: true,
            },
            toolbar: {
              ...toolbar,
              handlers: {
                // Custom handlers for undo/redo
                undo: () => {
                  if (quill) {
                    quill.history.undo()
                  }
                },
                redo: () => {
                  if (quill) {
                    quill.history.redo()
                  }
                },
              },
            },
          },
          formats: ['header', 'bold', 'italic', 'underline', 'strike', 'link', 'list', 'bullet', 'align'],
        })

        if (typeof ref === 'function') {
          ref(quill)
        } else if (ref) {
          ref.current = quill
        }

        if (valueRef.current) {
          quill.root.innerHTML = valueRef.current
        }

        quill.on(Quill.events.TEXT_CHANGE, () => {
          const html = quill.root.innerHTML.trim()

          onTextChangeRef.current?.(html)
        })

        const undoButton = document.querySelector('.ql-undo') as HTMLElement
        const redoButton = document.querySelector('.ql-redo') as HTMLElement

        if (undoButton) {
          undoButton.classList.add('ri-arrow-go-back-line')
        }
        if (redoButton) {
          redoButton.classList.add('ri-arrow-go-forward-line')
        }

        return () => {
          if (typeof ref === 'function') {
            ref(null)
          } else if (ref) {
            ref.current = null
          }

          container.innerHTML = ''
        }
      }
    }, [])

    return (
      <Styled
        className='textarea raymond'
        ref={containerRef}
        height={height}
      />
    )
  }
)
