import { queryOptions, useQuery, useSuspenseQuery } from '@tanstack/react-query'

import { getDetailSlideShows as queryFn, getDetailSlideShowsKey, GetDetailSlideShowsRequest } from '@/services'

export const useGetDetailSlideShows = (request: GetDetailSlideShowsRequest, enabled = true) => {
  const {
    refetch: getDetailSlideShows,
    data: getDetailSlideShowsData,
    error: getDetailSlideShowsError,
    isFetching: getDetailSlideShowsIsFetching,
    isPending: getDetailSlideShowsIsPending,
    isSuccess: getDetailSlideShowsIsSuccess,
    isError: getDetailSlideShowsIsError,
  } = useQuery({
    enabled,
    queryFn: () => queryFn(request),
    queryKey: [getDetailSlideShowsKey, request],
    refetchOnMount: false,
    refetchOnReconnect: false,
    refetchOnWindowFocus: false,
    refetchInterval: false,
    refetchIntervalInBackground: false,
    retryOnMount: false,
    gcTime: 0,
  })

  return {
    getDetailSlideShows,
    getDetailSlideShowsData,
    getDetailSlideShowsError,
    getDetailSlideShowsIsFetching,
    getDetailSlideShowsIsPending,
    getDetailSlideShowsIsSuccess,
    getDetailSlideShowsIsError,
  }
}

export const useSuspenseGetDetailSlideShows = (request: GetDetailSlideShowsRequest) => {
  const { data: getDetailSlideShowsData } = useSuspenseQuery(getDetailSlideShowsOptions(request))

  return { getDetailSlideShowsData }
}

export const getDetailSlideShowsOptions = (request: GetDetailSlideShowsRequest) =>
  queryOptions({
    queryFn: () => queryFn(request),
    queryKey: [getDetailSlideShowsKey, request],
  })
