import { queryOptions, useQuery, useSuspenseQuery } from '@tanstack/react-query'

import {
  getDetailDynamicContent as queryFn,
  getDetailDynamicContentKey,
  GetDetailDynamicContentRequest,
} from '@/services'

export const useGetDetailDynamicContent = (request: GetDetailDynamicContentRequest, enabled = true) => {
  const {
    refetch: getDetailDynamicContent,
    data: getDetailDynamicContentData,
    error: getDetailDynamicContentError,
    isFetching: getDetailDynamicContentIsFetching,
    isPending: getDetailDynamicContentIsPending,
    isSuccess: getDetailDynamicContentIsSuccess,
    isError: getDetailDynamicContentIsError,
  } = useQuery({
    enabled,
    queryFn: () => queryFn(request),
    queryKey: [getDetailDynamicContentKey, request],
    refetchOnMount: false,
    refetchOnReconnect: false,
    refetchOnWindowFocus: false,
    refetchInterval: false,
    refetchIntervalInBackground: false,
    retryOnMount: false,
    gcTime: 0,
  })

  return {
    getDetailDynamicContent,
    getDetailDynamicContentData,
    getDetailDynamicContentError,
    getDetailDynamicContentIsFetching,
    getDetailDynamicContentIsPending,
    getDetailDynamicContentIsSuccess,
    getDetailDynamicContentIsError,
  }
}

export const useSuspenseGetDetailDynamicContent = (request: GetDetailDynamicContentRequest) => {
  const { data: getDetailDynamicContentData } = useSuspenseQuery(getDetailDynamicContentOptions(request))

  return { getDetailDynamicContentData }
}

export const getDetailDynamicContentOptions = (request: GetDetailDynamicContentRequest) =>
  queryOptions({
    queryFn: () => queryFn(request),
    queryKey: [getDetailDynamicContentKey, request],
  })
