import styled, { CSSObject } from '@emotion/styled'
import { flip, offset, useClick, useDismiss, useFloating, useInteractions } from '@floating-ui/react'
import {
  FormDatepickerButton,
  FormDatepickerButtonLabel,
  FormDatepickerFloating,
  FormDatepickerGroup,
  FormError,
  FormGroup,
  FormLabel,
} from '@nbsdev/naini-react'
import { clsx } from 'clsx'
import { FC, useState } from 'react'
import ReactDatePicker from 'react-datepicker'

import { formatToJsDate, jsDateToFormat } from '@/utils/formatter'

type StyledProps = {
  width?: CSSObject['width']
}

const Styled = styled('div')<StyledProps>(
  ({ width = '100%' }): CSSObject => ({
    position: 'relative',
    width,
    flexBasis: width,
    flexShrink: 0,
  })
)

export type FormTimePickerProps = {
  label?: string
  placeholder?: string
  error?: string
  required?: boolean
  format?: string
  timeIntervals?: number
  minTime?: Date
  maxTime?: Date
  excludeTimes?: Date[]
  value?: string
  onChange?(value: string | undefined): void
} & StyledProps

export const FormTimePicker: FC<FormTimePickerProps> = ({
  label,
  placeholder = 'Pilih waktu',
  error,
  required,
  format = 'HH:mm',
  timeIntervals = 180,
  minTime,
  maxTime,
  excludeTimes,
  value,
  onChange,
  ...rest
}) => {
  const [isOpen, setIsOpen] = useState(false)

  const { refs, floatingStyles, context } = useFloating({
    placement: 'bottom-start',
    strategy: 'absolute',
    open: isOpen,
    onOpenChange: setIsOpen,
    middleware: [offset(12), flip()],
  })

  const { getReferenceProps, getFloatingProps } = useInteractions([
    useClick(context),
    useDismiss(context, { ancestorScroll: true }),
  ])

  const handleChange = (date: Date | null) => {
    if (onChange) {
      onChange(date ? jsDateToFormat(date, format) : undefined)
    }
    setIsOpen(false)
  }

  const RenderDayContents = (dayOfMonth: number) => {
    return <div className='react-datepicker__day-custom'>{dayOfMonth}</div>
  }

  return (
    <Styled {...rest}>
      <FormGroup>
        {label && <FormLabel className={clsx(required && 'required')}>{label}</FormLabel>}

        <FormDatepickerGroup>
          <FormDatepickerButton
            type='button'
            ref={refs.setReference}
            className={clsx(error && 'error')}
            {...getReferenceProps()}
          >
            <FormDatepickerButtonLabel className={clsx(!value && 'placeholder')}>
              {value || placeholder}
            </FormDatepickerButtonLabel>
          </FormDatepickerButton>

          {isOpen && (
            <FormDatepickerFloating
              ref={refs.setFloating}
              style={floatingStyles}
              isTime
              {...getFloatingProps()}
            >
              <ReactDatePicker
                inline
                showTimeSelect
                showTimeSelectOnly
                timeIntervals={timeIntervals}
                timeCaption='Time'
                timeFormat='HH:mm'
                selected={value ? formatToJsDate(value, format) : undefined}
                onChange={handleChange}
                renderDayContents={RenderDayContents}
                minTime={minTime}
                maxTime={maxTime}
                excludeTimes={excludeTimes}
              />
            </FormDatepickerFloating>
          )}
        </FormDatepickerGroup>

        {error && <FormError>{error}</FormError>}
      </FormGroup>
    </Styled>
  )
}
