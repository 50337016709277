import { AGE_RATING_DETAIL } from '@/configs/endpoint'
import { BaseResponse, TimestampAndVersion } from '@/types/common'
import { api } from '@/utils/api'

export type GetDetailAgeRatingRequestParams = {
  xid: string
}

export type GetDetailAgeRatingRequest = {
  params: GetDetailAgeRatingRequestParams
}

export type GetDetailAgeRatingResponse = BaseResponse<
  {
    xid: string
    name: string
  } & TimestampAndVersion
>

export const getDetailAgeRating = async (request: GetDetailAgeRatingRequest) => {
  const response = await api<GetDetailAgeRatingResponse, GetDetailAgeRatingRequestParams>({
    ...request,
    url: AGE_RATING_DETAIL,
    method: 'GET',
  })

  return response.data
}

export const getDetailAgeRatingKey = 'GET_DETAIL_AGE_RATING'
