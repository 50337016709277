import { CSSObject } from '@emotion/react'
import styled from '@emotion/styled'

type Props = {
  progress: number
  animationDuration: number
}

export const TopLoadingBar = styled('div')<Props>(
  ({ theme, progress, animationDuration }): CSSObject => ({
    background: theme.colors.primary.main,
    position: 'fixed',
    left: '0',
    top: '0',
    height: '2px',
    width: '100%',
    zIndex: 99999,
    transition: `margin-left ${animationDuration}ms linear`,
    marginLeft: `${(-1 + progress) * 100}%`,
  })
)

TopLoadingBar.displayName = 'TopLoadingBar'
