import { CSSObject } from '@emotion/react'
import styled from '@emotion/styled'

export const AuthButtons = styled('div')(
  (): CSSObject => ({
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    justifyContent: 'center',
    width: '100%',

    '>:not([hidden])~:not([hidden])': {
      marginTop: '16px',
    },

    '>button': {
      width: '100%',
    },
  })
)

AuthButtons.displayName = 'AuthButtons'
