import { CSSObject } from '@emotion/react'
import styled from '@emotion/styled'

export const AuthLeft = styled('div')(
  (): CSSObject => ({
    display: 'none',
    height: '100%',
    minHeight: '100vh',
    flex: 1,
    flexDirection: 'row',
    justifyContent: 'center',
    alignItems: 'center',
    backgroundColor: '#F5F5F5',
    alignSelf: 'stretch',

    '>img': {
      width: '100%',
    },

    '@media (min-width: 1024px)': {
      display: 'flex',
    },
  })
)

AuthLeft.displayName = 'AuthLeft'
