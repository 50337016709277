import { queryOptions, useQuery, useSuspenseQuery } from '@tanstack/react-query'

import { getDetailHighlights as queryFn, getDetailHighlightsKey } from '@/services'

export const useGetDetailHighlights = () => {
  const {
    refetch: getDetailHighlights,
    data: getDetailHighlightsData,
    error: getDetailHighlightsError,
    isFetching: getDetailHighlightsIsFetching,
    isPending: getDetailHighlightsIsPending,
    isSuccess: getDetailHighlightsIsSuccess,
    isError: getDetailHighlightsIsError,
  } = useQuery({
    queryFn: () => queryFn(),
    queryKey: [getDetailHighlightsKey],
    refetchOnMount: false,
    refetchOnReconnect: false,
    refetchOnWindowFocus: false,
    refetchInterval: false,
    refetchIntervalInBackground: false,
    retryOnMount: false,
    gcTime: 0,
  })

  return {
    getDetailHighlights,
    getDetailHighlightsData,
    getDetailHighlightsError,
    getDetailHighlightsIsFetching,
    getDetailHighlightsIsPending,
    getDetailHighlightsIsSuccess,
    getDetailHighlightsIsError,
  }
}

export const useSuspenseGetDetailHighlights = () => {
  const { data: getDetailHighlightsData } = useSuspenseQuery(getDetailHighlightsOptions())

  return { getDetailHighlightsData }
}

export const getDetailHighlightsOptions = () =>
  queryOptions({
    queryFn: () => queryFn(),
    queryKey: [getDetailHighlightsKey],
  })
