import { queryOptions, useQuery, useSuspenseQuery } from '@tanstack/react-query'

import { getDetailProgram as queryFn, getDetailProgramKey, GetDetailProgramRequest } from '@/services'

export const useGetDetailProgram = (request: GetDetailProgramRequest, enabled = true) => {
  const {
    refetch: getDetailProgram,
    data: getDetailProgramData,
    error: getDetailProgramError,
    isFetching: getDetailProgramIsFetching,
    isPending: getDetailProgramIsPending,
    isSuccess: getDetailProgramIsSuccess,
    isError: getDetailProgramIsError,
  } = useQuery({
    enabled,
    queryFn: () => queryFn(request),
    queryKey: [getDetailProgramKey, request],
    refetchOnMount: false,
    refetchOnReconnect: false,
    refetchOnWindowFocus: false,
    refetchInterval: false,
    refetchIntervalInBackground: false,
    retryOnMount: false,
    gcTime: 0,
  })

  return {
    getDetailProgram,
    getDetailProgramData,
    getDetailProgramError,
    getDetailProgramIsFetching,
    getDetailProgramIsPending,
    getDetailProgramIsSuccess,
    getDetailProgramIsError,
  }
}

export const useSuspenseGetDetailProgram = (request: GetDetailProgramRequest) => {
  const { data: getDetailProgramData } = useSuspenseQuery(getDetailProgramOptions(request))

  return { getDetailProgramData }
}

export const getDetailProgramOptions = (request: GetDetailProgramRequest) =>
  queryOptions({
    queryFn: () => queryFn(request),
    queryKey: [getDetailProgramKey, request],
  })
