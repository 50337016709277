import { queryOptions, useQuery, useSuspenseQuery } from '@tanstack/react-query'

import { getDetailTag as queryFn, getDetailTagKey, GetDetailTagRequest } from '@/services'

export const useGetDetailTag = (request: GetDetailTagRequest, enabled = true) => {
  const {
    refetch: getDetailTag,
    data: getDetailTagData,
    error: getDetailTagError,
    isFetching: getDetailTagIsFetching,
    isPending: getDetailTagIsPending,
    isSuccess: getDetailTagIsSuccess,
    isError: getDetailTagIsError,
  } = useQuery({
    enabled,
    queryFn: () => queryFn(request),
    queryKey: [getDetailTagKey, request],
    refetchOnMount: false,
    refetchOnReconnect: false,
    refetchOnWindowFocus: false,
    refetchInterval: false,
    refetchIntervalInBackground: false,
    retryOnMount: false,
    gcTime: 0,
  })

  return {
    getDetailTag,
    getDetailTagData,
    getDetailTagError,
    getDetailTagIsFetching,
    getDetailTagIsPending,
    getDetailTagIsSuccess,
    getDetailTagIsError,
  }
}

export const useSuspenseGetDetailTag = (request: GetDetailTagRequest) => {
  const { data: getDetailTagData } = useSuspenseQuery(getDetailTagOptions(request))

  return { getDetailTagData }
}

export const getDetailTagOptions = (request: GetDetailTagRequest) =>
  queryOptions({
    queryFn: () => queryFn(request),
    queryKey: [getDetailTagKey, request],
  })
