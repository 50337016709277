import { queryOptions, useQuery, useSuspenseQuery } from '@tanstack/react-query'

import { profile as queryFn, profileKey } from '@/services'

export const useProfile = () => {
  const {
    data: profileData,
    error: profileError,
    isPending: profileIsPending,
    isSuccess: profileIsSuccess,
    isError: profileIsError,
  } = useQuery({
    queryFn,
    queryKey: [profileKey],
  })

  return { profileData, profileError, profileIsPending, profileIsSuccess, profileIsError }
}

export const useSuspenseProfile = () => {
  const { data } = useSuspenseQuery(profileOptions())

  return { data }
}

export const profileOptions = () =>
  queryOptions({
    queryFn,
    queryKey: [profileKey],
  })
