import { queryOptions, useQuery, useSuspenseQuery } from '@tanstack/react-query'

import { getDetailFigure as queryFn, getDetailFigureKey, GetDetailFigureRequest } from '@/services'

export const useGetDetailFigure = (request: GetDetailFigureRequest, enabled = true) => {
  const {
    refetch: getDetailFigure,
    data: getDetailFigureData,
    error: getDetailFigureError,
    isFetching: getDetailFigureIsFetching,
    isPending: getDetailFigureIsPending,
    isSuccess: getDetailFigureIsSuccess,
    isError: getDetailFigureIsError,
  } = useQuery({
    enabled,
    queryFn: () => queryFn(request),
    queryKey: [getDetailFigureKey, request],
    refetchOnMount: false,
    refetchOnReconnect: false,
    refetchOnWindowFocus: false,
    refetchInterval: false,
    refetchIntervalInBackground: false,
    retryOnMount: false,
    gcTime: 0,
  })

  return {
    getDetailFigure,
    getDetailFigureData,
    getDetailFigureError,
    getDetailFigureIsFetching,
    getDetailFigureIsPending,
    getDetailFigureIsSuccess,
    getDetailFigureIsError,
  }
}

export const useSuspenseGetDetailFigure = (request: GetDetailFigureRequest) => {
  const { data: getDetailFigureData } = useSuspenseQuery(getDetailFigureOptions(request))

  return { getDetailFigureData }
}

export const getDetailFigureOptions = (request: GetDetailFigureRequest) =>
  queryOptions({
    queryFn: () => queryFn(request),
    queryKey: [getDetailFigureKey, request],
  })
