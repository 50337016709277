import { queryOptions, useQuery, useSuspenseQuery } from '@tanstack/react-query'

import { getDetailAgeRating as queryFn, getDetailAgeRatingKey, GetDetailAgeRatingRequest } from '@/services'

export const useGetDetailAgeRating = (request: GetDetailAgeRatingRequest, enabled = true) => {
  const {
    refetch: getDetailAgeRating,
    data: getDetailAgeRatingData,
    error: getDetailAgeRatingError,
    isFetching: getDetailAgeRatingIsFetching,
    isPending: getDetailAgeRatingIsPending,
    isSuccess: getDetailAgeRatingIsSuccess,
    isError: getDetailAgeRatingIsError,
  } = useQuery({
    enabled,
    queryFn: () => queryFn(request),
    queryKey: [getDetailAgeRatingKey, request],
    refetchOnMount: false,
    refetchOnReconnect: false,
    refetchOnWindowFocus: false,
    refetchInterval: false,
    refetchIntervalInBackground: false,
    retryOnMount: false,
    gcTime: 0,
  })

  return {
    getDetailAgeRating,
    getDetailAgeRatingData,
    getDetailAgeRatingError,
    getDetailAgeRatingIsFetching,
    getDetailAgeRatingIsPending,
    getDetailAgeRatingIsSuccess,
    getDetailAgeRatingIsError,
  }
}

export const useSuspenseGetDetailAgeRating = (request: GetDetailAgeRatingRequest) => {
  const { data: getDetailAgeRatingData } = useSuspenseQuery(getDetailAgeRatingOptions(request))

  return { getDetailAgeRatingData }
}

export const getDetailAgeRatingOptions = (request: GetDetailAgeRatingRequest) =>
  queryOptions({
    queryFn: () => queryFn(request),
    queryKey: [getDetailAgeRatingKey, request],
  })
