import { SUB_CATEGORY_DETAIL } from '@/configs/endpoint'
import { BaseResponse, FileData, TimestampAndVersion } from '@/types/common'
import { api } from '@/utils/api'

export type GetDetailSubCategoryRequestParams = {
  xid: string
}

export type GetDetailSubCategoryRequest = {
  params: GetDetailSubCategoryRequestParams
}

export type GetDetailSubCategoryResponse = BaseResponse<
  {
    xid: string
    name: string
    description: string
    cover: FileData
  } & TimestampAndVersion
>

export const getDetailSubCategory = async (request: GetDetailSubCategoryRequest) => {
  const response = await api<GetDetailSubCategoryResponse, GetDetailSubCategoryRequestParams>({
    ...request,
    url: SUB_CATEGORY_DETAIL,
    method: 'GET',
  })

  return response.data
}

export const getDetailSubCategoryKey = 'GET_DETAIL_SUB_CATEGORY'
