import { TAG_DETAIL } from '@/configs/endpoint'
import { BaseResponse, TimestampAndVersion } from '@/types/common'
import { api } from '@/utils/api'

export type GetDetailTagRequestParams = {
  xid: string
}

export type GetDetailTagRequest = {
  params: GetDetailTagRequestParams
}

export type GetDetailTagResponse = BaseResponse<
  {
    xid: string
    name: string
  } & TimestampAndVersion
>

export const getDetailTag = async (request: GetDetailTagRequest) => {
  const response = await api<GetDetailTagResponse, GetDetailTagRequestParams>({
    ...request,
    url: TAG_DETAIL,
    method: 'GET',
  })

  return response.data
}

export const getDetailTagKey = 'GET_DETAIL_TAG'
