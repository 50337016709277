import { DYNAMIC_CONTENT_DETAIL } from '@/configs/endpoint'
import { ControlStatus, HomeSectionType } from '@/configs/enum'
import { BaseResponse, FileData, ModifiedBy, TimestampAndVersion } from '@/types/common'
import { api } from '@/utils/api'

import { GetListProgramResponse } from '../program'

export type GetDetailDynamicContentRequestParams = {
  xid: string
}

export type GetDetailDynamicContentRequest = {
  params: GetDetailDynamicContentRequestParams
}

export type GetDetailDynamicContentResponse = BaseResponse<
  {
    xid: string
    title: string
    subTitle: string
    description: string | null
    homeSectionTypeId: HomeSectionType
    sequence: string
    modifiedBy: ModifiedBy
    statusId: ControlStatus
    coverProgram: FileData | null
    coverEpisode: {
      items: Array<{
        xid: string
        cover: FileData
      }>
    } | null
    isDefault: boolean
    programs: Pick<GetListProgramResponse['data'], 'items'>
  } & TimestampAndVersion
>

export const getDetailDynamicContent = async (request: GetDetailDynamicContentRequest) => {
  const response = await api<GetDetailDynamicContentResponse, GetDetailDynamicContentRequestParams>({
    ...request,
    url: DYNAMIC_CONTENT_DETAIL,
    method: 'GET',
  })

  return response.data
}

export const getDetailDynamicContentKey = 'GET_DETAIL_DYNAMIC_CONTENT'
