import { CSSObject } from '@emotion/react'
import styled from '@emotion/styled'
import { configStyledOptions } from '@nbsdev/naini-react'

export type LogoProps = {
  width?: number
  height?: number
}

export const Logo = styled(
  'img',
  configStyledOptions<LogoProps>(['width', 'height'])
)<LogoProps>(
  ({ width = 153, height }): CSSObject => ({
    width: width ? `${width}px` : 'auto',
    height: height ? `${height}px` : 'auto',
  })
)

Logo.displayName = 'Logo'
