import { FC } from 'react'
import { useTranslation } from 'react-i18next'

type Props = {
  error: any
}

export const InternalServerError: FC<Props> = ({ error }) => {
  const { i18n } = useTranslation()

  return (
    <div
      css={{
        height: '100vh',
        textAlign: 'center',
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        justifyContent: 'center',
        userSelect: 'none',
      }}
    >
      <img
        src='/assets/images/500.webp'
        alt=''
        css={{
          position: 'relative',
          zIndex: 2,
          width: '512px',
          height: '512px',
          marginBottom: '16px',
          '@media(max-width: 1023px)': {
            display: 'block',
            width: '256px',
            height: '256px',
          },
        }}
      />

      <div
        css={{
          '> :not([hidden]) ~ :not([hidden])': {
            marginTop: '16px',
          },
        }}
      >
        <h1
          css={{
            display: 'inline-block',
            fontSize: '20px',
            fontWeight: 700,
            lineHeight: '30px',
            width: '100%',
            '@media(min-width: 768px)': {
              width: '100%',
              maxWidth: '430px',
            },
          }}
        >
          {error?.status || i18n.t('internal_error')}
        </h1>

        <h2
          css={{
            display: 'inline-block',
            fontSize: '16px',
            fontWeight: 400,
            lineHeight: '24px',
            width: '100%',
            '@media(min-width: 768px)': {
              width: '100%',
              maxWidth: '430px',
            },
          }}
        >
          {error?.message || i18n.t('internalServerError')}
        </h2>
      </div>
    </div>
  )
}
