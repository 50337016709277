import { NOTIFICATION_BROADCASTS_DETAIL } from '@/configs/endpoint'
import { NotificationBroadcastType } from '@/configs/enum'
import { BaseResponse, FileData, ModifiedBy, TimestampAndVersion } from '@/types/common'
import { api } from '@/utils/api'

export type GetDetailNotificationBroadcastsRequestParams = {
  xid: string
}

export type GetDetailNotificationBroadcastsRequest = {
  params: GetDetailNotificationBroadcastsRequestParams
}

export type GetDetailNotificationBroadcastsResponse = BaseResponse<
  {
    xid: string
    notificationBroadcastTypeId: NotificationBroadcastType
    programXid: string
    program: null | { name: string; xid: string }
    publishedDate: string
    statusId: number
    title: string
    description: string
    message: string
    cover?: FileData
    thumbnail?: FileData
    modifiedBy: ModifiedBy
  } & TimestampAndVersion
>

export const getDetailNotificationBroadcasts = async (request: GetDetailNotificationBroadcastsRequest) => {
  const response = await api<GetDetailNotificationBroadcastsResponse, GetDetailNotificationBroadcastsRequestParams>({
    ...request,
    url: NOTIFICATION_BROADCASTS_DETAIL,
    method: 'GET',
  })

  return response.data
}

export const getDetailNotificationBroadcastsKey = 'GET_DETAIL_NOTIFICATION_BROADCASTS'
