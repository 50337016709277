import { SUB_ROLES_DETAIL } from '@/configs/endpoint'
import { BaseResponse } from '@/types/common'
import { api } from '@/utils/api'

import { SubRolesItem } from './get-list-sub-roles'

export type GetDetailSubRolesRequestParams = {
  xid: string
}

export type GetDetailSubRolesRequest = {
  params: GetDetailSubRolesRequestParams
}

export type GetDetailSubRolesResponse = BaseResponse<SubRolesItem>

export const getDetailSubRoles = async (request: GetDetailSubRolesRequest) => {
  const response = await api<GetDetailSubRolesResponse, GetDetailSubRolesRequestParams>({
    ...request,
    url: SUB_ROLES_DETAIL,
    method: 'GET',
  })

  return response.data
}

export const getDetailSubRolesKey = 'GET_DETAIL_SUB_ROLES'
