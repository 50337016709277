import { CSSObject } from '@emotion/react'
import styled from '@emotion/styled'

export const AuthRight = styled('div')(
  (): CSSObject => ({
    display: 'flex',
    flex: 1,
    height: '100%',
    flexDirection: 'row',
    justifyContent: 'center',
    alignItems: 'center',
    backgroundColor: '#FFFFFF',
  })
)

AuthRight.displayName = 'AuthRight'
